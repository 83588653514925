import { useState } from "react";
import { makeRequest } from "../makeRequest";

const useAddress = (renderNewAddressList = () => {}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const retry = () => setTries((prev) => prev + 1);

  const markAsNotDefault = async (userID) => {
    const payload = {
      userId: parseInt(userID),
    };

    try {
      const res = await makeRequest.post(`/mark-as-not-default`, payload);
    } catch (err) {}
  };

  const setLatestAsDefault = async (userID) => {
    await markAsNotDefault(userID);
    try {
      const res = await makeRequest.get(
        `/addresses?populate=*&pagination[limit]=-1&filters[User][id][$eq]=${userID}&sort=updatedAt:desc`
      );

      if(res?.data?.data?.length === 0) return;
      const latestAddressID = res?.data?.data[0]?.id;
      const newAddress = {
        IsDefault: true,
      };
      await updateAddress(newAddress, latestAddressID);
    } catch (err) {
      return;
    }
  };

  const removeAddress = async (addressID, isDefault, userID) => {
    setLoading(true);
    setError(false);

    try {
      const res = await makeRequest.delete(`/addresses/${addressID}`);
    } catch (err) {
      setLoading(false);
      setError(true);
      return false;
    }

    if (isDefault) {
      await setLatestAsDefault(userID);
    }

    renderNewAddressList();
    setLoading(false);
    setError(false);
    return true;
  };

  const updateAddress = async (newAddress, addressID, userID) => {
    setLoading(true);
    setError(false);
    const payload = {
      data: {
        ...newAddress,
      },
    };
    await markAsNotDefault(userID);
    try {
      const res = await makeRequest.put(`/addresses/${addressID}`, payload);
    } catch (err) {
      setLoading(false);
      setError(true);
      return false;
    }

    renderNewAddressList();
    setLoading(false);
    setError(false);
    return true;
  };

  const addAddress = async (newAddress, userID) => {
    setLoading(true);
    setError(false);
    const payload = {
      data: {
        ...newAddress,
      },
    };
    await markAsNotDefault(userID);
    try {
      const res = await makeRequest.post(`/addresses`, payload);
    } catch (err) {
      setLoading(false);
      setError(true);
      return false;
    }

    renderNewAddressList();
    setLoading(false);
    setError(false);
    return true;
  };

  return {
    addAddress,
    updateAddress,
    removeAddress,
    loading,
    error,
    setError,
    retry,
  };
};

export { useAddress };
