import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { disable, enable } from "../../redux/overlayReducer";
import { closeHamburger } from "../../redux/hamburgerVisibilityReducer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Link } from "react-router-dom";

import "./Dropdown.scss";
import useDeviceType from "../../hooks/useDeviceType";

const Dropdown = ({ title, dropdownTitle, links, featured }) => {
  const [open, setOpen] = useState(false);
  const button = useRef(null);
  const deviceType = useDeviceType();

  const dispatch = useDispatch();

  const handleSelectOnClick = () => {
    setOpen((prev) => {
      if (deviceType == "desktop") {
        if (prev) {
          dispatch(disable());
        } else {
          dispatch(enable());
        }
      }
      return !prev;
    });
  };

  // disable scoll bar when overlay is present
  // if(open) {
  //     document.body.style.overflow = 'scroll';
  // } else {
  //     document.body.style.overflow = 'auto';
  // }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !button.current.contains(event.target)
      ) {
        dispatch(disable());
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {" "}
      {deviceType == "desktop" ? (
        <div className="dropdown">
          <button
            onClick={handleSelectOnClick}
            className="dropdown-label dropdown-select text-underline-animation"
            ref={button}
          >
            <span>{title}</span>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
          <div
            className={[
              "dropdown-container",
              "dropdown-options",
              open ? "active" : "hidden",
            ].join(" ")}
          >
            <div className="container">
              <div className="dropdown-links">
                <h2 className="dropdown-title">{dropdownTitle}</h2>
                <div className="dropdown-links-container">
                  {links.length > 5 ? (
                    <>
                      <div className="link-container">
                        {links
                          .slice(0, Math.ceil(links.length / 2))
                          .map((menuItem, index) => (
                            <Link
                              key={index}
                              to={menuItem.link}
                              className="list-item"
                              onClick={() => {
                                setOpen(false);
                                dispatch(disable());
                              }}
                            >
                              {menuItem.title}
                            </Link>
                          ))}
                      </div>

                      <div className="link-container">
                        {links
                          .slice(Math.ceil(links.length / 2))
                          .map((menuItem, index) => (
                            <Link
                              key={index}
                              to={menuItem.link}
                              className="list-item"
                              onClick={() => {
                                setOpen(false);
                                dispatch(disable());
                              }}
                            >
                              {menuItem.title}
                            </Link>
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="link-container">
                      {links.map((menuItem, index) => (
                        <Link
                          key={index}
                          to={menuItem.link}
                          className="list-item"
                          onClick={() => {
                            setOpen(false);
                            dispatch(disable());
                          }}
                        >
                          {menuItem.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="featured">
                {featured.map((item) => {
                  return (
                    <Link
                      to={item.link}
                      className={["featured-item", !item?.link && !item?.label && !item?.photoLink ? "visibile" : "hidden"].join(" ")}
                      onClick={() => {
                        setOpen(false);
                        dispatch(disable());
                      }}
                    >
                      <img src={item.photoLink} alt={item.label} />
                      <div className="label">{item.label}</div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="dropdown">
          <button
            onClick={handleSelectOnClick}
            className="dropdown-label dropdown-select text-underline-animation"
            ref={button}
          >
            <span>{title}</span>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
          <div
            className={[
              "dropdown-container",
              "dropdown-options",
              open ? "active" : "hidden",
            ].join(" ")}
          >
            <div className="container mobile">
              <div className="">
                <h2 className="dropdown-title">{dropdownTitle}</h2>
                <div className="dropdown-body">
                  {links.map((menuItem, index) => {
                    return (
                      <Link
                        key={index}
                        to={menuItem.link}
                        className="list-item"
                        onClick={() => {
                          dispatch(disable());
                          dispatch(closeHamburger());
                        }}
                      >
                        {menuItem.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dropdown;
