import React, { useState, useEffect } from "react";

import usePost from "../../hooks/usePost";

import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/userReducer";

import { Link, useNavigate, useLocation } from "react-router-dom";

import "./Login.scss";

import InputWithFloatingLabel from "../../components/InputWithFloatingLabel/InputWithFloatingLabel";

import { useTranslation } from "react-i18next";

const Login = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [requestData, setRequestData] = useState({});
  const [loginRetries, setLoginRetries] = useState(0);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LoginUrl = "/auth/local";
  const {
    data: LoginData,
    loading: LoginLoading,
    error: LoginError,
    status: LoginStatus,
    errorMessage: LoginErrorMessage,
  } = usePost(LoginUrl, requestData);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (LoginLoading) return;

    if (email.trim() === "") {
      setErrorMessage(t("errors.email-not-valid"));
      return;
    }
    if (password.trim() === "" || password.length < 8) {
      setErrorMessage(t("errors.password-not-valid"));
      return;
    }
    setErrorMessage("");

    setRequestData({
      identifier: email,
      password: password,
      try: loginRetries,
    });
    setLoginRetries((prev) => prev + 1);
  };

  useEffect(() => {
    if (LoginLoading) {
      setLoginLoading(true);
      return;
    }
    setLoginLoading(false);

    if (LoginStatus !== 200 && loginRetries > 0) {
      setLoginError(true);

      if (LoginStatus === 400) {
        setErrorMessage(LoginErrorMessage);
      } else {
        setErrorMessage(t("errors.unknown"));
      }
      return;
    }

    if (LoginData) {
      dispatch(
        setUser({
          jwt: LoginData.jwt,
          user: LoginData.user,
        })
      );
      setLoginError(false);
      setLoginLoading(false);
      setEmail("");
      setPassword("");
      const searchParams = new URLSearchParams(location.search);
      const refParam = searchParams.get("to");
      switch (refParam) {
        case "checkout":
          navigate("/checkout");
          break;
        default:
          navigate("/");
      }
    }
  }, [LoginLoading]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      navigate("/account");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t("login.login")}`;
  }, []);

  return (
    <section className="login">
      <div className="login-container">
        <h1 className="login-title">{t("login.login")}</h1>
        <form className="login-form" onSubmit={handleFormSubmit}>
          <InputWithFloatingLabel
            stateValue={email}
            setStateValue={setEmail}
            type={"email"}
            labelText={t("input.email")}
            idText={"email"}
          />
          <InputWithFloatingLabel
            stateValue={password}
            setStateValue={setPassword}
            type={"password"}
            labelText={t("input.password")}
            idText={"password"}
          />
          {errorMessage.trim() !== "" && (
            <h2 className="login-error">{errorMessage}</h2>
          )}
          <button
            className="login-button"
            type="submit"
            disabled={loginLoading}
          >
            {loginLoading ? t("account.loading...") : t("login.login")}
          </button>
        </form>
        <div className="login-link-container">
          <Link className="login-link" to="/register">
            {t("login.create-account")}
          </Link>
          <Link className="login-link" to="/forgot-password">
            {t("login.forget-your-password")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Login;
