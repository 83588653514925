import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rules: [],
  isLoading: false,
  isError: false,
};

const discountRulesSlice = createSlice({
  name: "discountRules",
  initialState: initialState,
  reducers: {
    setDiscountRules: (state, action) => {
      state.rules = action.payload;
    },
    removeDiscountRules: (state) => {
      state.rules = null;
    },
    enableIsLoading: (state) => {
      state.isLoading = true;
    },
    disableIsLoading: (state) => {
      state.isLoading = false;
    },
    enableIsError: (state) => {
      state.isError = true;
    },
    disableIsError: (state) => {
      state.isError = false;
    },
  },
});

export const {
  setDiscountRules,
  removeDiscountRules,
  enableIsLoading,
  disableIsLoading,
  enableIsError,
  disableIsError,
} = discountRulesSlice.actions;
export default discountRulesSlice.reducer;
