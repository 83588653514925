import React, { useState, useEffect } from "react";

import { encodeURL } from "../../helpers/common";

import { Link } from "react-router-dom";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import "./Slider.scss";

import i18n from "i18next";

const Slider = ({ data, loading, error, retryFunc }) => {
  const currentLanguage = i18n.language;
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderInfo, setSliderInfo] = useState([]);
  const [isSliderLoading, setIsSliderLoading] = useState(false);
  const [isSliderError, setIsSliderError] = useState(false);

  const prevSlide = () => {
    setCurrentSlide(
      currentSlide === 0 ? sliderInfo?.length - 1 : (prev) => prev - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide(
      currentSlide === sliderInfo?.length - 1 ? 0 : (prev) => prev + 1
    );
  };

  const CTAButtonLink = (CTA) => {
    if(CTA?.Category?.data) {
      return `/category/${encodeURL(CTA?.Category?.data?.attributes?.Title)}`
    } else if(CTA?.Page?.data) {
      return `/page/${encodeURL(CTA?.Page?.data?.attributes?.SEOURL)}`
    } else if(CTA?.Tag?.data) {
      return `/tag/${encodeURL(CTA?.Tag?.data?.attributes?.Name)}`
    }
                    
    return `/`;
  };

  useEffect(() => {
    if (!loading && data) {
      setSliderInfo(data?.attributes?.Slider);
      setIsSliderLoading(false);
    } else if (loading && !error) {
      setIsSliderLoading(true);
    } else if (!loading && error && !data) {
      setIsSliderLoading(false);
      setIsSliderError(true);
    }
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderInfo?.length > 1 && !isSliderLoading && !isSliderError) {
        nextSlide();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className="slider">
      <div
        className="container"
        style={{
          transform: `translateX(${currentLanguage == "ar" ? "+" : "-"}${
            currentSlide * 100
          }vw)`,
          width: `${sliderInfo?.length * 100}vw`,
        }}
      >
        {sliderInfo?.map((slide, index) => {
          return (
            <div
              key={index}
              className="slide"
              style={{
                background: `url(${
                  process.env.REACT_APP_IMAGE_URL +
                  slide?.Image?.data?.attributes?.url
                }) no-repeat center center / cover`,
              }}
            >
              <div className="slide-info">
                {slide?.Title ? <h2>{slide?.Title}</h2> : <></>}
                {slide?.Subtitle ? <h1>{slide?.Subtitle}</h1> : <></>}
                {slide?.CTA ? (
                  <Link to={CTAButtonLink(slide?.CTA)} className="cta-button">
                    {slide?.CTA?.Title}
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {sliderInfo?.length > 1 ? (
        <>
          <div className="icon icon-prev" onClick={prevSlide}>
            {(document.documentElement.dir || "ltr") == "ltr" ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </div>
          <div className="icon icon-next" onClick={nextSlide}>
            {(document.documentElement.dir || "ltr") == "ltr" ? (
              <KeyboardArrowRightIcon />
            ) : (
              <KeyboardArrowLeftIcon />
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Slider;
