import React, { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";

import { useParams } from "react-router-dom";

import "./Page.scss";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Page = () => {
  const slug = useParams().slug;

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "*",
    locale: "en",
    "filters[SEOURL][$eq]": `${slug}`,
    try: tries,
  });

  const pageBaseUrl = "/pages";
  const pageUrl = `${pageBaseUrl}?${Params.toString()}`;
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useFetch(pageUrl);

  useEffect(() => {
    if (!pageLoading && pageData) {
      setData(pageData[0]);
      setLoading(false);
      setMetaTitle(pageData[0]?.attributes?.SEO?.metaTitle);
      setMetaDescription(pageData[0]?.attributes?.SEO?.metaDescription);
      setMetaKeywords(pageData[0]?.attributes?.SEO?.keywords);
    } else if (pageLoading && !pageError) {
      setLoading(true);
    } else if (!pageLoading && pageError && !pageData) {
      setLoading(false);
      setError(true);
    }
  }, [pageLoading]);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${data?.attributes?.Title}`;
  }, [data]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={metaDescription || t("home.default-description")}
        />
        <meta name="keywords" content={metaKeywords || t("home.default-keywords")} />
      </Helmet>
      <section className="page-section">
        <LoaderOverlay
          loadingState={loading}
          errorState={error}
          setErrorState={setError}
          retryFunc={retry}
        />
        <h1 className="page-title">{data?.attributes?.Title}</h1>
        <div
          className="page-content"
          dangerouslySetInnerHTML={{ __html: data?.attributes?.Content?.replace("\n", "<br>") }}
        ></div>
      </section>
    </>
  );
};

export default Page;
