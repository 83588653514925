import React, { useState, useEffect, useCallback } from "react";
import { Foloosi } from "react-foloosi-widget";

const FoloosiPayment = ({
  referenceToken,
  merchantKey,
  redirect = false,
  open,
  updatePaymentResponse
}) => {
  const [foloosiOpen, setFoloosiOpen] = useState(open);

  const openFoloosi = useCallback(() => {
    setFoloosiOpen(true);
  }, []);

  const closeFoloosi = useCallback(() => {
    setFoloosiOpen(false);
  }, []);

  const foloosiHandler = useCallback((e) => {
    console.log(e.data)
    if (e.data.status === "success") {
      setTimeout(() => {
        closeFoloosi()
        updatePaymentResponse(e.data, true)
      }, 3000)
    } else if (e.data.status === "error") {
      setTimeout(() => {
        closeFoloosi()
        updatePaymentResponse(e.data, false, true)
      }, 3000)
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", foloosiHandler);
    return () => {
      window.removeEventListener("message", foloosiHandler);
    };
  }, [foloosiHandler]);

  return (
    <div>
      <Foloosi
        foloosiOpen={foloosiOpen}
        foloosiClose={closeFoloosi}
        reference_token={referenceToken}
        merchant_key={merchantKey}
        redirect={redirect}
      />
    </div>
  );
};

export default FoloosiPayment;
