import React, { useState, useEffect } from "react";

import usePost from "../../hooks/usePost";

import {
  isPhoneValid,
  updateCountryFromPhoneNumber,
} from "../../helpers/common";

import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/userReducer";

import { Link, useNavigate } from "react-router-dom";

import "./Register.scss";

import InputWithFloatingLabel from "../../components/InputWithFloatingLabel/InputWithFloatingLabel";

import { useTranslation } from "react-i18next";

const Register = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [requestData, setRequestData] = useState({});
  const [registerRetries, setRegisterRetries] = useState(0);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RegisterUrl = "/auth/local/register";
  const {
    data: RegisterData,
    loading: RegisterLoading,
    error: RegisterError,
    status: RegisterStatus,
    errorMessage: RegisterErrorMessage,
  } = usePost(RegisterUrl, requestData);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (registerLoading) return;

    if (firstName.trim() === "" || firstName.length < 3) {
      setErrorMessage(t("errors.firstname-not-valid"));
      return;
    }
    if (lastName.trim() === "" || lastName.length < 3) {
      setErrorMessage(t("errors.lastname-not-valid"));
      return;
    }
    if (email.trim() === "") {
      setErrorMessage(t("errors.email-not-valid"));
      return;
    }
    if (password.trim() === "" || password.length < 8) {
      setErrorMessage(t("errors.password-not-valid"));
      return;
    }
    if (confirmPassword.trim() === "") {
      setErrorMessage(t("errors.confirm-your-password"));
      return;
    }
    if (password.trim() !== confirmPassword.trim()) {
      setErrorMessage(t("errors.confirm-password-not-valid"));
      return;
    }
    if (phone.trim() === "" || !isPhoneValid(phone)) {
      setErrorMessage(t("errors.phone-not-valid"));
      return;
    }
    setErrorMessage("");

    setRequestData({
      email: email,
      password: password,
      username: firstName + lastName,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phone,
      try: registerRetries,
    });
    setRegisterRetries((prev) => prev + 1);
  };

  useEffect(() => {
    if (RegisterLoading) {
      setRegisterLoading(true);
      return;
    }
    setRegisterLoading(false);

    if (RegisterStatus !== 200 && registerRetries > 0) {
      setRegisterError(true);

      if (RegisterStatus === 400) {
        setErrorMessage(RegisterErrorMessage);
      } else {
        setErrorMessage(t("errors.unknown"));
      }
      return;
    }

    if (RegisterData) {
      dispatch(
        setUser({
          jwt: RegisterData.jwt,
          user: RegisterData.user,
        })
      );
      setRegisterError(false);
      setRegisterLoading(false);
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setFirstName("");
      setLastName("");
      setPhone("");
      navigate("/account");
    }
  }, [RegisterLoading]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      navigate("/account");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t("register.register")}`;
  }, []);

  return (
    <section className="register">
      <div className="register-container">
        <h1 className="register-title">{t("register.register")}</h1>
        <form className="register-form" onSubmit={handleFormSubmit}>
          <InputWithFloatingLabel
            stateValue={firstName}
            setStateValue={setFirstName}
            type={"text"}
            labelText={t("input.first-name")}
            idText={"firstname"}
          />
          <InputWithFloatingLabel
            stateValue={lastName}
            setStateValue={setLastName}
            type={"text"}
            labelText={t("input.last-name")}
            idText={"lastname"}
          />
          <InputWithFloatingLabel
            stateValue={email}
            setStateValue={setEmail}
            type={"email"}
            labelText={t("input.email")}
            idText={"email"}
          />
          <InputWithFloatingLabel
            stateValue={password}
            setStateValue={setPassword}
            type={"password"}
            labelText={t("input.password")}
            idText={"password"}
          />
          <InputWithFloatingLabel
            stateValue={confirmPassword}
            setStateValue={setConfirmPassword}
            type={"password"}
            labelText={t("input.confirm-password")}
            idText={"confirm-password"}
          />
          <InputWithFloatingLabel
            stateValue={phone}
            setStateValue={setPhone}
            type={"tel"}
            labelText={t("input.phone")}
            idText={"phone"}
            phoneInput={true}
            updateCountryFromPhoneNumber={updateCountryFromPhoneNumber}
          />
          {errorMessage.trim() !== "" && (
            <h2 className="register-error">{errorMessage}</h2>
          )}
          <button className="register-button" type="submit" disabled={registerLoading}>
            {registerLoading ? t("account.loading...") : t("register.create-account")}
          </button>
        </form>
        <div className="register-link-container">
          <Link className="register-link" to="/login">
            {t("register.back-to-login")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Register;
