import React, { useState, useEffect } from "react";

import usePost from "../../hooks/usePost";

import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

import "./ResetPassword.scss";

import InputWithFloatingLabel from "../../components/InputWithFloatingLabel/InputWithFloatingLabel";
import { setUser } from "../../redux/userReducer";

import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [requestData, setRequestData] = useState({});
  const [resetPasswordRetries, setResetPasswordRetries] = useState(0);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(false);

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const ResetPasswordUrl = "/auth/reset-password";
  const {
    data: ResetPasswordData,
    loading: ResetPasswordLoading,
    error: ResetPasswordError,
    status: ResetPasswordStatus,
    errorMessage: ResetPasswordErrorMessage,
  } = usePost(ResetPasswordUrl, requestData);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (ResetPasswordLoading) return;

    if (password.trim() === "" || password.length < 8) {
      setErrorMessage(t("errors.password-not-valid"));
      return;
    }
    if (confirmPassword.trim() === "") {
      setErrorMessage(t("errors.confirm-your-password"));
      return;
    }
    if (password.trim() !== confirmPassword.trim()) {
      setErrorMessage(t("errors.confirm-password-not-valid"));
      return;
    }
    if (code.trim() === "") {
      setErrorMessage(t("errors.code-not-valid"));
      return;
    }

    setErrorMessage("");

    setRequestData({
      password: password,
      passwordConfirmation: confirmPassword,
      code: code,
    });
    setResetPasswordRetries((prev) => prev + 1);
  };

  useEffect(() => {
    if (ResetPasswordLoading) {
      setResetPasswordLoading(true);
      return;
    }
    setResetPasswordLoading(false);

    if (ResetPasswordStatus !== 200 && resetPasswordRetries > 0) {
      setResetPasswordError(true);

      if (ResetPasswordStatus === 400) {
        setErrorMessage(ResetPasswordErrorMessage);
      } else {
        setErrorMessage(t("errors.unknown"));
      }
      return;
    }

    if (ResetPasswordData) {
      dispatch(
        setUser({
          jwt: ResetPasswordData.jwt,
          user: ResetPasswordData.user,
        })
      );
      navigate("/account");
    }
  }, [ResetPasswordLoading]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      navigate("/account");
    }

    setCode(searchParams.get("code"));
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t(
      "reset-password.reset-password"
    )}`;
  }, []);

  return (
    <section className="reset-password">
      <div className="reset-password-container">
        <h1 className="reset-password-title">
          {t("reset-password.reset-password")}
        </h1>
        <form className="reset-password-form" onSubmit={handleFormSubmit}>
          <InputWithFloatingLabel
            stateValue={password}
            setStateValue={setPassword}
            type={"password"}
            labelText={t("input.new-password")}
            idText={"password"}
          />
          <InputWithFloatingLabel
            stateValue={confirmPassword}
            setStateValue={setConfirmPassword}
            type={"password"}
            labelText={t("input.confirm-new-password")}
            idText={"confirm-password"}
          />
          <InputWithFloatingLabel
            stateValue={code}
            setStateValue={setCode}
            type={"text"}
            labelText={t("input.reset-code")}
            idText={"code"}
          />
          {errorMessage.trim() !== "" && (
            <h2 className="reset-password-error">{errorMessage}</h2>
          )}
          <button className="reset-password-button" type="submit">
            {resetPasswordLoading
              ? t("account.loading...")
              : t("reset-password.reset-password")}
          </button>
        </form>
        <div className="reset-password-link-container">
          <Link className="reset-password-link" to="/login">
            {t("register.back-to-login")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
