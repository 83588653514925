import { createSlice } from "@reduxjs/toolkit";

const hamburgerVisibilitySlice = createSlice({
  name: "hamburgerVisibility",
  initialState: { isOpen: false },
  reducers: {
    openHamburger: (state) => {
      state.isOpen = true;
    },
    closeHamburger: (state) => {
      state.isOpen = false;
    },
    toggleHamburger: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openHamburger, closeHamburger, toggleHamburger } = hamburgerVisibilitySlice.actions;
export default hamburgerVisibilitySlice.reducer;