import { useState, useEffect, useCallback } from "react";

import { getAddressFromCoords } from "../../helpers/common";

import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { Icon } from "leaflet";

import "./Map.scss";

import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";

const customIcon = new Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const MapUpdater = ({ position }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(position, 13);
  }, [position, map]);
  return null;
};

const DraggableMarker = ({ position, setPosition, setAddress, update }) => {
  const updatePosition = async (e) => {
    const newPosition = e.target.getLatLng();
    setPosition(newPosition);
    const newAddress = await getAddressFromCoords(
      newPosition?.lat,
      newPosition?.lng
    );
    setAddress(newAddress?.DisplayName);
    update(newAddress);
  };

  return (
    <Marker
      position={position}
      draggable={true}
      eventHandlers={{ dragend: updatePosition }}
      icon={customIcon}
    />
  );
};

const ClickHandler = ({ setPosition, setAddress, update }) => {
  useMapEvents({
    click: async (e) => {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      const newAddress = await getAddressFromCoords(lat, lng);
      setAddress(newAddress?.DisplayName);
      update(newAddress);
    },
  });
  return null;
};

const Map = ({
  updateAddress,
  loadMap,
  selectedPosition = [25.276987, 55.296249],
  DisplayName = "",
  userLoggedIn = false,
  setTurnMapOff
}) => {
  const [position, setPosition] = useState(selectedPosition);
  const [displayName, setDisplayName] = useState("");
  const [userLocated, setUserLocated] = useState(false);
  const [positionSupported, setPositionSupported] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const getPosition = useCallback((options = {}) => {
    const defaultOptions = {
      enableHighAccuracy: true,  // Use GPS if available
      timeout: 10000,           // Time to wait before error (10 seconds)
      maximumAge: 60000,        // Accept cached positions up to 1 minute old
    };

    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
        return;
      }

      // Try high accuracy first
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => {
          // If high accuracy fails, try with lower accuracy
          if (options.enableHighAccuracy) {
            navigator.geolocation.getCurrentPosition(
              (position) => resolve(position),
              (error) => reject(getLocationErrorMessage(error)),
              { ...defaultOptions, enableHighAccuracy: false }
            );
          } else {
            reject(getLocationErrorMessage(error));
          }
        },
        { ...defaultOptions, ...options }
      );
    });
  }, []);

  const getLocationErrorMessage = useCallback((error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return 'Please enable location services in your browser settings to use this feature';
      case error.POSITION_UNAVAILABLE:
        return 'Location information is unavailable at this time';
      case error.TIMEOUT:
        return 'Location request timed out - please try again';
      default:
        return 'An unknown error occurred while getting your location';
    }
  }, []);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 2;

    const attemptGeolocation = async () => {
      try {
        const position = await getPosition();
        const { latitude, longitude } = position.coords;
        setPosition([latitude, longitude]);
        const newAddress = await getAddressFromCoords(latitude, longitude);
        setDisplayName(newAddress?.DisplayName);
        updateAddress(newAddress);
        setUserLocated(true);
      } catch (error) {
        console.error("Geolocation error:", error);
        if (retryCount < maxRetries) {
          retryCount++;
          console.log(`Retrying geolocation (attempt ${retryCount + 1}/${maxRetries + 1})...`);
          attemptGeolocation();
        } else {
          setPositionSupported(false);
          setErrorMessage(error.message || "Unable to get your location");
          setUserLocated(true);
          setTurnMapOff(true);
        }
      }
    };

    if ("geolocation" in navigator) {
      attemptGeolocation();
    } else {
      console.error("Geolocation is not supported.");
      setPositionSupported(false);
      setErrorMessage("Geolocation is not supported by your browser");
      setUserLocated(true);
      setTurnMapOff(true);
    }
  }, [getPosition]);

  useEffect(() => {
    setPosition(selectedPosition);
    setDisplayName(DisplayName);
  }, [selectedPosition, DisplayName]);

  return (
    <div className="leaflet-map">
      {!loadMap || !positionSupported && (
      <div className="map-overlay">
        <h2 className="map-overlay-message">{errorMessage}</h2>
      </div>
      )}
      <div className="leaflet-map-container">
        <MapContainer
          center={position}
          zoom={13}
          className="leaflet-actual-map"
          attributionControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution=""
          />
          {userLocated && <MapUpdater position={position} />}
          {!userLoggedIn && (
            <>
              <ClickHandler
                setPosition={setPosition}
                update={updateAddress}
                setAddress={setDisplayName}
              />
              <DraggableMarker
                position={position}
                setPosition={setPosition}
                setAddress={setDisplayName}
                update={updateAddress}
              />
            </>
          )}
          {userLoggedIn && <Marker position={position} icon={customIcon} />}
        </MapContainer>
      </div>
      <h2 className="map-display-name">{DisplayName}</h2>
    </div>
  );
};

export default Map;
