import React from "react";

import { useSelector } from "react-redux";


import "./Overlay.scss"

const Overlay = () => {
    const overlayState = useSelector(state => state.overlay)

    return (
        <div className={['overlay', overlayState.enabled ? "show" : "none"].join(' ')}></div>
    )
}

export default Overlay;