import React, { useEffect } from "react";

import "./RadioBoxCollection.scss";

import { useTranslation } from "react-i18next";

const RadioBoxCollection = ({
  radioOptions,
  stateValue,
  setStateValue,
  emptyMessage,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const handleOptionChange = (optionId) => {
    setStateValue(optionId);
  };

  useEffect(() => {
    if (radioOptions?.length > 0) {
      handleOptionChange(radioOptions[0]?.Name);
    }
  }, [radioOptions]);

  return (
    <>
      {radioOptions?.length > 0 ? (
        <>
          <form className="radio-container">
            {radioOptions?.map((option, index) => {
              return (
                <div
                  key={index}
                  className={`radio-option-container ${
                    stateValue === option?.Name ? "active" : ""
                  }`}
                >
                  <div className="radio-option">
                    <input
                      type="radio"
                      className="radio-input"
                      name="radio"
                      id={option?.Name}
                      checked={stateValue === option?.Name}
                      onChange={(e) => handleOptionChange(e.target.id)}
                    />
                    <label className="radio-label" htmlFor={option?.Name}>
                      {option?.Name}
                    </label>
                  </div>
                  {option?.DaysEstimate && (
                    <p className="radio-days">
                      ({option?.DaysEstimate} {t("checkout.days")})
                    </p>
                  )}
                </div>
              );
            })}
          </form>
        </>
      ) : (
        <>
          <div className="no-options">{t("checkout.no-options-available")}</div>
          {emptyMessage && (
            <div className="no-options-message">{emptyMessage}</div>
          )}
        </>
      )}
    </>
  );
};

export default RadioBoxCollection;
