import React, { useState, useEffect } from "react";

import { useZones } from "../../hooks/useZones";
import { useGetCity } from "../../hooks/useGetCity";
import { useGetArea } from "../../hooks/useGetArea";
import useDHLShipping from "../../hooks/useDHLShipping";

import "./Dhl.scss";

import SelectWithInnerLabel from "../../components/SelectWithInnerLabel/SelectWithInnerLabel";
import InputWithFloatingLabel from "../../components/InputWithFloatingLabel/InputWithFloatingLabel";

const Dhl = () => {
  const [password, setPassword] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);

  const correctPassword = "Dubai@2020#";

  const [weight, setWeight] = useState("");
  const [weightError, setWeightError] = useState("");
  const [estimatedDays, setEstimatedDays] = useState("");

  // Sender details
  const [senderCountry, setSenderCountry] = useState(231);
  const [senderCity, setSenderCity] = useState("");
  const [senderArea, setSenderArea] = useState("");
  const [senderPostalCode, setSenderPostalCode] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [senderErrors, setSenderErrors] = useState({});

  // Receiver details
  const [receiverCountry, setReceiverCountry] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverArea, setReceiverArea] = useState("");
  const [receiverPostalCode, setReceiverPostalCode] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [receiverErrors, setReceiverErrors] = useState({});

  const [countriesArr, setCountriesArr] = useState([]);
  const [senderCitiesArr, setSenderCitiesArr] = useState([]);
  const [senderAreasArr, setSenderAreasArr] = useState([]);
  const [receiverCitiesArr, setReceiverCitiesArr] = useState([]);
  const [receiverAreasArr, setReceiverAreasArr] = useState([]);

  const [rateValue, setRateValue] = useState(0);

  const {
    data: zonesData,
    loading: zonesLoading,
    error: zonesError,
  } = useZones();

  const {
    data: senderCityData,
    loading: senderCityLoading,
    error: senderCityError,
  } = useGetCity(senderCountry);

  const {
    data: receiverCityData,
    loading: receiverCityLoading,
    error: receiverCityError,
  } = useGetCity(receiverCountry);

  const {
    data: senderAreaData,
    loading: senderAreaLoading,
    error: senderAreaError,
  } = useGetArea(senderCity);

  const {
    data: receiverAreaData,
    loading: receiverAreaLoading,
    error: receiverAreaError,
  } = useGetArea(receiverCity);

  const {
    getDHLSettings,
    getDHLPageRates: getDHLRates,
    loading: dhlLoading,
    error: dhlError,
    setError: setDhlError,
    retry: retryDHL,
  } = useDHLShipping();

  useEffect(() => {
    if (!zonesLoading && !zonesError && zonesData) {
      setCountriesArr(
        zonesData.countries.map((country) => ({
          Name: country?.attributes?.Name,
          id: country?.id,
        }))
      );
      setSenderCountry(231);
      setReceiverCountry(1);
    }
  }, [zonesLoading, zonesError, zonesData]);

  useEffect(() => {
    if (senderCityData && receiverCityData) {
      const senderCities = senderCityData.cities
        ?.filter((city) => city?.attributes?.Country?.data?.id == senderCountry)
        ?.map((city) => ({
          Name: city?.attributes?.Name,
          id: city?.id,
        }));

      setSenderCitiesArr(senderCities);
      setSenderCity(senderCities?.length > 0 ? senderCities[0]?.id : "");

      const receiverCities = receiverCityData.cities
        ?.filter(
          (city) => city?.attributes?.Country?.data?.id == receiverCountry
        )
        ?.map((city) => ({
          Name: city?.attributes?.Name,
          id: city?.id,
        }));

      setReceiverCitiesArr(receiverCities);
      setReceiverCity(receiverCities?.length > 0 ? receiverCities[0]?.id : "");
    }
  }, [senderCountry, receiverCountry, senderCityData, receiverCityData]);

  useEffect(() => {
    if (senderAreaData && receiverAreaData) {
      const senderAreas = senderAreaData.areas
        ?.filter((area) => area?.attributes?.City?.data?.id == senderCity)
        ?.map((area) => ({
          Name: area?.attributes?.Name,
          id: area?.id,
        }));

      setSenderAreasArr(senderAreas);
      setSenderArea(senderAreas?.length > 0 ? senderAreas[0]?.id : "");

      const receiverAreas = receiverAreaData.areas
        ?.filter((area) => area?.attributes?.City?.data?.id == receiverCity)
        ?.map((area) => ({
          Name: area?.attributes?.Name,
          id: area?.id,
        }));

      setReceiverAreasArr(receiverAreas);
      setReceiverArea(receiverAreas?.length > 0 ? receiverAreas[0]?.id : "");
    }
  }, [senderCity, receiverCity, senderAreaData, receiverAreaData]);

  const validateFields = () => {
    const senderErrors = {};
    const receiverErrors = {};
    let isValid = true;

    if (!weight || isNaN(weight) || weight <= 0) {
      setWeightError("Weight must be a positive number.");
      isValid = false;
    } else {
      setWeightError("");
    }

    if (!senderCountry) senderErrors.country = "Sender country is required.";
    if (!senderCity) senderErrors.city = "Sender city is required.";

    if (!receiverCountry)
      receiverErrors.country = "Receiver country is required.";
    if (!receiverCity) receiverErrors.city = "Receiver city is required.";

    setSenderErrors(senderErrors);
    setReceiverErrors(receiverErrors);

    if (
      Object.keys(senderErrors).length > 0 ||
      Object.keys(receiverErrors).length > 0
    ) {
      isValid = false;
    }

    return isValid;
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthorized(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const requestData = {
      weight,
      sender: {
        country: senderCountry,
        city: senderCity,
        area: senderArea,
        postalCode: senderPostalCode,
        address: senderAddress,
      },
      receiver: {
        country: receiverCountry,
        city: receiverCity,
        area: receiverArea,
        postalCode: receiverPostalCode,
        address: receiverAddress,
      },
    };

    const senderCountryName = countriesArr?.find(
      (count) => count?.id == requestData?.sender?.country
    )?.Name;
    const senderCityName = senderCitiesArr?.find(
      (cit) => cit?.id == requestData?.sender?.city
    )?.Name;

    const receiverCountryName = countriesArr?.find(
      (count) => count?.id == requestData?.receiver?.country
    )?.Name;
    const receiverCityName = receiverCitiesArr?.find(
      (cit) => cit?.id == requestData?.receiver?.city
    )?.Name;

    const [shippingRate, daysEstimate] = await getDHLRates(
      weight,
      senderCountryName,
      senderCityName,
      requestData?.sender?.postalCode,
      receiverCountryName,
      receiverCityName,
      requestData?.receiver?.postalCode
    );
    if (shippingRate > 0 && daysEstimate) {
      setEstimatedDays(daysEstimate);
    }
    setRateValue(shippingRate);
  };

  return (
    <section className="dhl-form">
      {isAuthorized ? (
        <>
          <div className="container">
            <div className="left-side">
              <h1 className="form-title">DHL Calculator</h1>
              <div className="form-section">
                <InputWithFloatingLabel
                  stateValue={weight}
                  setStateValue={setWeight}
                  type={"number"}
                  labelText={"Weight (KG)"}
                  idText={"weight"}
                />
                {weightError && <p className="error-text">{weightError}</p>}

                <h3>Sender Details</h3>
                <SelectWithInnerLabel
                  stateValue={senderCountry}
                  setStateValue={setSenderCountry}
                  labelText={"Country/Region"}
                  idText={"sender-country-selector"}
                  options={countriesArr}
                />
                {senderErrors.country && (
                  <p className="error-text">{senderErrors.country}</p>
                )}

                <SelectWithInnerLabel
                  stateValue={senderCity}
                  setStateValue={setSenderCity}
                  labelText={"City"}
                  idText={"sender-city-selector"}
                  options={senderCitiesArr}
                />
                {senderErrors.city && (
                  <p className="error-text">{senderErrors.city}</p>
                )}

                <SelectWithInnerLabel
                  stateValue={senderArea}
                  setStateValue={setSenderArea}
                  labelText={"Area"}
                  idText={"sender-area-selector"}
                  options={senderAreasArr}
                />
                {senderErrors.area && (
                  <p className="error-text">{senderErrors.area}</p>
                )}

                <InputWithFloatingLabel
                  stateValue={senderPostalCode}
                  setStateValue={setSenderPostalCode}
                  labelText={"Postal Code"}
                  idText={"sender-postal-code"}
                />
                {senderErrors.postalCode && (
                  <p className="error-text">{senderErrors.postalCode}</p>
                )}

                <InputWithFloatingLabel
                  stateValue={senderAddress}
                  setStateValue={setSenderAddress}
                  labelText={"Address"}
                  idText={"sender-address"}
                />
                {senderErrors.address && (
                  <p className="error-text">{senderErrors.address}</p>
                )}

                <h3>Receiver Details</h3>
                <SelectWithInnerLabel
                  stateValue={receiverCountry}
                  setStateValue={setReceiverCountry}
                  labelText={"Country/Region"}
                  idText={"receiver-country-selector"}
                  options={countriesArr}
                />
                {receiverErrors.country && (
                  <p className="error-text">{receiverErrors.country}</p>
                )}

                <SelectWithInnerLabel
                  stateValue={receiverCity}
                  setStateValue={setReceiverCity}
                  labelText={"City"}
                  idText={"receiver-city-selector"}
                  options={receiverCitiesArr}
                />
                {receiverErrors.city && (
                  <p className="error-text">{receiverErrors.city}</p>
                )}

                <SelectWithInnerLabel
                  stateValue={receiverArea}
                  setStateValue={setReceiverArea}
                  labelText={"Area"}
                  idText={"receiver-area-selector"}
                  options={receiverAreasArr}
                />
                {receiverErrors.area && (
                  <p className="error-text">{receiverErrors.area}</p>
                )}

                <InputWithFloatingLabel
                  stateValue={receiverPostalCode}
                  setStateValue={setReceiverPostalCode}
                  labelText={"Postal Code"}
                  idText={"receiver-postal-code"}
                />
                {receiverErrors.postalCode && (
                  <p className="error-text">{receiverErrors.postalCode}</p>
                )}

                <InputWithFloatingLabel
                  stateValue={receiverAddress}
                  setStateValue={setReceiverAddress}
                  labelText={"Address"}
                  idText={"receiver-address"}
                />
                {receiverErrors.address && (
                  <p className="error-text">{receiverErrors.address}</p>
                )}
              </div>
            </div>
            <div className="right-side">
              <div className="sticky-container">
                <h3>Rate Value: {rateValue} AED</h3>
                <h3>Estimated Delivery Days: {estimatedDays} Days</h3>
                <button onClick={handleSubmit} disabled={dhlLoading}>
                  {!dhlLoading ? "Submit" : "Loading..."}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-authorizaion">
            <h1 className="password-label">Password Required</h1>
            <form className="form-section" onSubmit={handlePasswordSubmit}>
              <InputWithFloatingLabel
                stateValue={password}
                setStateValue={setPassword}
                type={"password"}
                labelText={"Password"}
                idText={"password"}
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </>
      )}
    </section>
  );
};

export default Dhl;
