import React, { useState, useEffect, useRef } from "react";

import useFetch from "../../hooks/useFetch";
import useDeviceType from "../../hooks/useDeviceType";

import { encodeURL } from "../../helpers/common";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./Navbar.scss";

import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Dropdown from "../Dropdown/Dropdown";
import NavbarSearch from "../NavbarSearch/NavbarSearch";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import Cart from "../Cart/Cart";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const deviceType = useDeviceType();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const logoRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [logoHeight, setLogoHeight] = useState(46);
  const [paddingTop, setPaddingTop] = useState(40);
  const products = useSelector((state) => state.cart.products);

  const [navbarInfo, setNavbarInfo] = useState({});
  const [isNavbarLoading, setIsNavbarLoading] = useState(false);
  const [isNavbarError, setIsNavbarError] = useState(false);
  const [navbarRetries, setNavbarRetries] = useState(0);

  const navbarBaseUrl = "/main-menu";
  const navbarParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    try: navbarRetries,
  });
  const navbarUrl = `${navbarBaseUrl}?${navbarParams.toString()}`;
  const {
    data: navbarData,
    loading: navbarLoading,
    error: navbarError,
  } = useFetch(navbarUrl);

  const retrynavbar = () => setNavbarRetries((prev) => prev + 1);

  useEffect(() => {
    if (!navbarLoading && navbarData) {
      setNavbarInfo(navbarData);
      setIsNavbarLoading(false);
    } else if (navbarLoading && !navbarError) {
      setIsNavbarLoading(true);
    } else if (!navbarLoading && navbarError && !navbarData) {
      retrynavbar();
    }
  }, [navbarLoading]);

  useEffect(() => {
    const handleScroll = () => {
      if(deviceType != "mobile") {
        const scrollPosition = window.scrollY;
        const newHeight = Math.max(0, 46 - scrollPosition / 5);
        const newPaddingTop = Math.max(0, 40 - scrollPosition / 5);
  
        setLogoHeight(newHeight);
        setPaddingTop(newPaddingTop);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className="navbar">
      {deviceType == "mobile" && (
        <>
          <div className="hamburger-menu-container">
            <HamburgerMenu data={navbarInfo} lang={navbarData} />
          </div>
        </>
      )}
      <div
        className="logo-wrapper"
        ref={logoRef}
        style={{
          height: `${deviceType != "mobile" ? `${logoHeight}px` : "fit-content"}`,
          paddingTop: `${deviceType != "mobile" ? paddingTop : "0"}px`,
          transition: "height 0.3s ease, padding-top 0.3s ease",
        }}

      >
        <span className="logo">
          <Link to="/" className="logo-text">
            Classy . YM
          </Link>
        </span>
      </div>
      <div className="links-wrapper">
        {deviceType == "desktop" && (
          <>
            <LanguageSelector
              languagesArray={navbarData?.attributes?.Language?.languages?.data}
            />
            <div className="links">
              {!isNavbarLoading ? (
                <>
                  {navbarInfo?.attributes?.SubMenus?.map((el, index) => {
                    if (
                      el?.categories?.data?.length <= 1 &&
                      el?.Tags?.data?.length <= 1
                    ) {
                      let encodedURI = "";
                      if (el?.categories?.data?.length == 1) {
                        encodedURI = `/category/${encodeURL(
                          el?.categories?.data[0]?.attributes?.Title
                        )}`;
                      } else if (el?.Tags?.data?.length == 1) {
                        encodedURI = `/tag/${encodeURL(
                          el?.Tags?.data[0]?.attributes?.Name
                        )}`;
                      }
                      return (
                        <div className="">
                          <Link
                            key={index}
                            to={encodedURI}
                            className="nav-link link-container text-underline-animation"
                          >
                            {el?.Title}
                          </Link>
                        </div>
                      );
                    } else {
                      const links = [];
                      if (el?.categories?.data?.length > 1) {
                        el?.categories?.data?.forEach((link) => {
                          links.push({
                            title: link?.attributes?.Title,
                            link: `/category/${encodeURL(
                              link?.attributes?.Title
                            )}`,
                          });
                        });
                      } else if (el?.Tags?.data?.length > 1) {
                        el?.Tags?.data?.forEach((link) => {
                          links.push({
                            title: link?.attributes?.Name,
                            link: `/tag/${encodeURL(link?.attributes?.Name)}`,
                          });
                        });
                      }

                      return (
                        <Dropdown
                          key={index}
                          title={el?.Title}
                          dropdownTitle={el?.InnerTitle}
                          links={links}
                          featured={[
                            {
                              photoLink:
                                process.env.REACT_APP_IMAGE_URL +
                                el?.ProductL?.data?.attributes?.DefaultImage
                                  ?.data?.attributes?.url,
                              label: el?.ProductL?.data?.attributes?.Title,
                              link: `/product/${el?.ProductL?.data?.attributes?.SEOURL}`,
                            },
                            {
                              photoLink:
                                process.env.REACT_APP_IMAGE_URL +
                                el?.ProductR?.data?.attributes?.DefaultImage
                                  ?.data?.attributes?.url,
                              label: el?.ProductR?.data?.attributes?.Title,
                              link: `/product/${el?.ProductR?.data?.attributes?.SEOURL}`,
                            },
                          ]}
                        />
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
        <div className="icons">
          <NavbarSearch />
          <ProfileDropdown />
          {/* <div className="icon-padd text-underline-animation">
            <Heart />
          </div> */}
          <Cart />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
