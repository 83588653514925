import React, { useState } from "react";

import "./AddressCard.scss";

import AddressCardEntry from "../AddressCardEntry/AddressCardEntry";

import { useTranslation } from "react-i18next";

const AddressCard = ({ address, remove, edit, setDefault }) => {
  const { t, i18n } = useTranslation();

  return (
    <div key={address?.id} className="address-card">
      <div className="address-card-details">
        <div className="address-card-two-cols">
          <AddressCardEntry
            label={`${t("input.first-name")}:`}
            value={address?.attributes?.FirstName}
          />
          <AddressCardEntry
            label={`${t("input.last-name")}:`}
            value={address?.attributes?.LastName}
          />
        </div>
        <div className="address-card-two-cols">
          <AddressCardEntry
            label={`${t("input.country")}:`}
            value={address?.attributes?.Country?.data?.attributes?.Name}
          />
          <AddressCardEntry
            label={`${t("input.city")}:`}
            value={address?.attributes?.City?.data?.attributes?.Name}
          />
        </div>
        <div className="address-card-two-cols">
          <AddressCardEntry
            label={`${t("input.area")}:`}
            value={
              address?.attributes?.Area?.data?.attributes?.Name ?? "All Areas"
            }
          />
          <AddressCardEntry
            label={`${t("input.postal-code")}:`}
            value={address?.attributes?.Postcode}
          />
        </div>
        <AddressCardEntry
          label={`${t("order.address-line-1")}:`}
          value={address?.attributes?.Address1}
        />
        <AddressCardEntry
          label={`${t("order.address-line-2")}:`}
          value={address?.attributes?.Address2}
        />
      </div>
      <div className="address-card-actions">
        <span className="address-card-btn" onClick={() => edit(address?.id)}>
          {t("address-book.edit")}
        </span>
        <span
          className="address-card-btn"
          onClick={() => remove(address?.id, address?.attributes?.IsDefault, address?.attributes?.User?.data?.id)}
        >
          {t("address-book.remove")}
        </span>
        {!address?.attributes?.IsDefault && (
          <span
            className="address-card-btn"
            onClick={() => setDefault(address?.id)}
          >
            {t("address-book.set-default")}
          </span>
        )}
      </div>
    </div>
  );
};

export default AddressCard;
