import React, { useState, useEffect, useRef } from "react";

import useFetch from "../../hooks/useFetch";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import "./Orders.scss";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";

import { useTranslation } from "react-i18next";

const Orders = () => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const tableRef = useRef(null);

  const [data, setData] = useState({});
  const [metaData, setMetaData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "deep",
    locale: "en",
    "pagination[page]": page,
    "pagination[pageSize]": pageSize,
    sort: "createdAt:desc",
    "filters[User][id][$eq]": user?.id,
    try: tries,
  });

  const pageBaseUrl = "/orders";
  const pageUrl = `${pageBaseUrl}?${Params.toString()}`;
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    meta: pageMeta,
  } = useFetch(pageUrl, true);

  useEffect(() => {
    if (!pageLoading && pageData) {
      setData(pageData);
      setMetaData(pageMeta);
      setLoading(false);
    } else if (pageLoading && !pageError) {
      setLoading(true);
    } else if (!pageLoading && pageError && !pageData) {
      setLoading(false);
      setError(true);
    }
  }, [pageLoading]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/login");
    }
  }, []);

  // useEffect(() => {
  //   if (tableRef.current) {
  //     tableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // }, [page]);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t("orders.my-orders")}`;
  }, []);

  return (
    <section className="orders-page">
      <h1 className="orders-title">{t("orders.my-orders")}</h1>
      <div className="orders-container">
        {data && (
          <>
            <table className="orders-table" ref={tableRef}>
              <thead>
                <th>{t("orders.id")}</th>
                <th>{t("orders.status")}</th>
                <th>{t("orders.address")}</th>
                <th>{t("orders.date")}</th>
                <th>{t("orders.total")}</th>
                <th>{t("orders.actions")}</th>
              </thead>
              <tbody>
                <LoaderOverlay
                  loadingState={loading}
                  errorState={error}
                  setErrorState={setError}
                  retryFunc={retry}
                />
                {!loading &&
                  data?.map((order, index) => {
                    const date = new Date(order?.attributes?.createdAt);

                    const formattedDate = new Intl.DateTimeFormat("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }).format(date);

                    return (
                      <tr key={index}>
                        <td>#{order?.id}</td>
                        <td>{order?.attributes?.Status}</td>
                        <td>
                          {order?.attributes?.Address1 +
                            " " +
                            order?.attributes?.Address2}
                        </td>
                        <td>{formattedDate}</td>
                        <td>
                          {order?.attributes?.TotalAfterVAT} {t("home.aed")}
                        </td>
                        <td>
                          <div className="order-links">
                            <Link
                              to={`/order/${order?.id}`}
                              className="orders-link"
                            >
                              {t("orders.details")}
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {metaData?.pagination?.total > 12 ? (
              <div className="order-pagination-arrows">
                <span
                  className={[
                    "paginate",
                    metaData?.pagination?.page !== 1 ? "active" : "",
                  ].join(" ")}
                  onClick={() => {
                    if (metaData?.pagination?.page !== 1) {
                      setPage((prev) => prev - 1);
                    }
                  }}
                >
                  {t("orders.previous")}
                </span>
                <div className="paginate-page">
                  {t("orders.page")}: {metaData?.pagination?.page}{" "}
                  {t("orders.of")} {metaData?.pagination?.pageCount}
                </div>
                <span
                  className={[
                    "paginate",
                    metaData?.pagination?.page !==
                    metaData?.pagination?.pageCount
                      ? "active"
                      : "",
                  ].join(" ")}
                  onClick={() => {
                    if (
                      metaData?.pagination?.page !==
                      metaData?.pagination?.pageCount
                    ) {
                      setPage((prev) => prev + 1);
                    }
                  }}
                >
                  {t("orders.next")}
                </span>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {!loading && !data && (
          <div className="orders-page-error">{t("orders.no-orders")}!</div>
        )}
      </div>
    </section>
  );
};

export default Orders;
