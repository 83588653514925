import { useState, useEffect, Suspense } from "react";

import useFetch from "./hooks/useFetch";

import { useDispatch } from "react-redux";

import {
  setDiscountRules,
  enableIsLoading,
  enableIsError,
  disableIsLoading,
  disableIsError,
} from "./redux/discountRulesReducer";

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  ScrollRestoration,
} from "react-router-dom";

import "./app.scss";

import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Overlay from "./components/Overlay/Overlay";

import Home from "./pages/Home/Home";
import Product from "./pages/Product/Product";
import Category from "./pages/Category/Category";
import Account from "./pages/Account/Account";
import AddressBook from "./pages/AddressBook/AddressBook";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Checkout from "./pages/Checkout/Checkout";
import Success from "./pages/Success/Success";
import NetworkPaymentRedirect from "./pages/NetworkPaymentRedirect/NetworkPaymentRedirect";
import Orders from "./pages/Orders/Orders";
import Order from "./pages/Order/Order";
import Page from "./pages/Page/Page";
import Dhl from "./pages/Dhl/Dhl";
import NotFound from "./pages/404/NotFound";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import ExchangeRequestForm from "./pages/ExchangeRequestForm/ExchangeRequestForm";
import ExchangeRequests from "./pages/ExchangeRequests/ExchangeRequests";
import ExchangeDetails from "./pages/ExchangeDetails/ExchangeDetails";

import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

const Layout = () => {
  return (
    <main className="app">
      <Overlay />
      <Navbar />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </main>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/category/:slug", element: <Category catType="category" /> },
      { path: "/tag/:slug", element: <Category catType="tag" /> },
      { path: "/search/:slug", element: <Category catType="search" /> },
      { path: "/product/:slug", element: <Product /> },
      { path: "/account", element: <Account /> },
      { path: "/address-book", element: <AddressBook /> },
      { path: "/login", element: <Login /> },
      { path: "/register", element: <Register /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/reset-password", element: <ResetPassword /> },
      { path: "/checkout", element: <Checkout /> },
      { path: "/order-submitted", element: <Success /> },
      { path: "/network-redirect", element: <NetworkPaymentRedirect /> },
      { path: "/orders", element: <Orders /> },
      { path: "/order/:id", element: <Order /> },
      { path: "/submit-exchange-request", element: <ExchangeRequestForm /> },
      { path: "/exchange-requests", element: <ExchangeRequests /> },
      { path: "/exchange-details/:id", element: <ExchangeDetails /> },
      { path: "/page/:slug", element: <Page /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  { path: "/dhl", element: <Dhl /> },
  { path: "/coming-soon", element: <ComingSoon /> },
]);

function App() {
  const dispatch = useDispatch();

  const [discountRulesRetries, setDiscountRulesRetries] = useState(0);

  const discountRulesBaseUrl = "/discount-rules";
  const discountRulesParams = new URLSearchParams({
    populate: "deep",
    "filters[Status][$eq]": true,
    try: discountRulesRetries,
  });
  const discountRulesUrl = `${discountRulesBaseUrl}?${discountRulesParams.toString()}`;
  const {
    data: discountRulesData,
    loading: discountRulesLoading,
    error: discountRulesError,
  } = useFetch(discountRulesUrl);

  const retryDiscountRules = () => setDiscountRulesRetries((prev) => prev + 1);

  useEffect(() => {
    if (!discountRulesLoading && discountRulesData) {
      dispatch(setDiscountRules(discountRulesData));
      dispatch(disableIsLoading());
      dispatch(disableIsError());
    } else if (discountRulesLoading && !discountRulesError) {
      dispatch(enableIsLoading());
    } else if (
      !discountRulesLoading &&
      discountRulesError &&
      !discountRulesData
    ) {
      dispatch(disableIsLoading());
      dispatch(enableIsError());
      retryDiscountRules();
    }
  }, [discountRulesLoading]);

  return (
    <div>
      {discountRulesLoading && (
        <div className="loader-suspense ">
          <span>Loading</span>
        </div>
      )}

      {!discountRulesLoading && discountRulesData && (
        <Suspense
          fallback={
            <div className="loader-suspense show">
              <span>Loading</span>
            </div>
          }
        >
          <RouterProvider router={router} />
        </Suspense>
      )}
    </div>
  );
}

export default App;
