import React, { useState, useEffect } from "react";

import "./SelectWithInnerLabel.scss";

const SelectWithInnerLabel = ({
  stateValue,
  setStateValue,
  labelText,
  idText,
  options,
  redBorder = false,
  disabled = false,
}) => {
  return (
    <div className="select-inner-container">
      <select
        className="select-inner"
        id={`select-inner-${idText}`}
        value={stateValue}
        onChange={(e) => setStateValue(e.target.value)}
        style={{
          border: `1px solid ${
            (redBorder && (options?.length === 0 || stateValue.length === 0)) 
              ? "red" 
              : "#00000040"
          }`
        }}        disabled={disabled}
      >
        {options?.length > 0 &&
          options?.map((option, index) => {
            return (
              <option key={index} value={option?.iso3 ?? option?.id}>{option?.Name}</option>
            );
          })}
      </select>
      <label className="select-inner-label" htmlFor={`select-inner-${idText}`}>
        {labelText}
      </label>
    </div>
  );
};

export default SelectWithInnerLabel;
