import React, { useState, useEffect, useRef } from "react";

import useFetch from "../../hooks/useFetch";
import useDeviceType from "../../hooks/useDeviceType";
import {
  calculatePromoDeduct,
  applyDiscountRules,
  isPromoValid,
} from "../../helpers/common";

import { useDispatch, useSelector } from "react-redux";
import { disable, enable } from "../../redux/overlayReducer";
import {
  enableIsPromoApplied,
  disableIsPromoApplied,
  enableIsVoucher,
  disableIsVoucher,
  addPromo,
  removePromo,
} from "../../redux/checkoutReducer";
import { toggleCart, closeCart } from "../../redux/cartVisibilityReducer";
import { removeItem, resetCart } from "../../redux/cartReducer";

import { Link } from "react-router-dom";

import "./Cart.scss";

import { ReactComponent as CartSVG } from "../../SVGs/Cart.svg";
import { ReactComponent as Close } from "../../SVGs/Close.svg";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import CartCard from "../../components/CartCard/CartCard";
import { useTranslation } from "react-i18next";

const Cart = () => {
  const deviceType = useDeviceType();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [products, setProducts] = useState([]);
  const [areProductsLoading, setAreProductsLoading] = useState(false);
  const [areProductsError, setAreProductsError] = useState(false);
  const [productsRetries, setProductsRetries] = useState(0);

  const [promoValue, setPromoValue] = useState("");
  const [isPromoLoading, setIsPromoLoading] = useState(false);
  const [isPromoError, setIsPromoError] = useState(false);
  const [promoRetries, setPromoRetries] = useState(0);
  const [promoParams, setPromoParams] = useState(
    new URLSearchParams({
      populate: "*",
      "filters[Name][$eq]": "",
      try: promoRetries,
    })
  );
  const [showPromoMessage, setShowPromoMessage] = useState(false);
  const [promoMessage, setPromoMessage] = useState("");
  const [promoAmountToDeduct, setPromoAmountToDeduct] = useState(0);

  const [isVoucherLoading, setIsVoucherLoading] = useState(false);
  const [isVoucherError, setIsVoucherError] = useState(false);
  const [voucherRetries, setVoucherRetries] = useState(0);
  const [voucherParams, setVoucherParams] = useState(
    new URLSearchParams({
      populate: "*",
      "filters[Code][$eq]": "",
      try: voucherRetries,
    })
  );

  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [vat, setVat] = useState(0);

  const button = useRef(null);

  const user = useSelector((state) => state.user.user);
  const productsIds = useSelector((state) => state.cart.products);
  const checkout = useSelector((state) => state.checkout);
  const discountRules = useSelector((state) => state.discountRules);
  const isOpen = useSelector((state) => state.cartVisibility.isOpen);
  const dispatch = useDispatch();

  const productsBaseUrl = "/products";
  const productsParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    "filters[Status][$eq]": true,
    try: productsRetries,
  });
  let productsUrl = "";
  productsIds.forEach((product, index) => {
    productsParams.append(`filters[id][$in][${index}]`, product.id);
    if(index === productsIds?.length - 1 && productsIds?.length != 0) {
      productsUrl = `${productsBaseUrl}?${productsParams.toString()}`
    }
  });
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useFetch(productsUrl);

  const promoBaseUrl = "/promo-codes";
  const promoUrl = `${promoBaseUrl}?${promoParams?.toString()}`;
  const {
    data: promoData,
    loading: promoLoading,
    error: promoError,
  } = useFetch(promoUrl);

  const voucherBaseUrl = "/vouchers";
  const voucherUrl = `${voucherBaseUrl}?${voucherParams?.toString()}`;
  const {
    data: voucherData,
    loading: voucherLoading,
    error: voucherError,
  } = useFetch(voucherUrl);

  const retryProduct = () => setProductsRetries((prev) => prev + 1);
  const retryPromo = () => setPromoRetries((prev) => prev + 1);
  const retryVouchers = () => setVoucherRetries((prev) => prev + 1);

  const handleSelectOnClick = () => {
    if (isOpen) {
      dispatch(disable());
    } else {
      dispatch(enable());
    }
    dispatch(toggleCart());
  };

  const clearCart = () => {
    dispatch(resetCart());
  }

  const submitPromo = (event) => {
    event.preventDefault();
    if (promoValue.trim() === "") return;
    const newPromoParams = new URLSearchParams(promoParams);
    newPromoParams.set("filters[Name][$eq]", promoValue);
    setPromoParams(newPromoParams);
  };

  const applyPromo = async (promo, isVoucher) => {
    if (
      promo?.length === 0 &&
      promoParams.get("filters[Name][$eq]") === "" &&
      voucherParams.get("filters[Code][$eq]") === ""
    ) {
      return;
    }

    if (
      promo?.length === 0 &&
      promoParams.get("filters[Name][$eq]") !== "" &&
      !isVoucher
    ) {
      const newVoucherParams = new URLSearchParams(voucherParams);
      newVoucherParams.set("filters[Code][$eq]", promoValue);
      setVoucherParams(newVoucherParams);
      return;
    }

    if (
      promo?.length === 0 &&
      voucherParams.get("filters[Code][$eq]") !== "" &&
      isVoucher
    ) {
      setShowPromoMessage(true);
      setPromoMessage(t("errors.promo-not-exist"));
      return;
    }

    // if (isVoucher && user?.id !== promo[0]?.attributes?.User?.data?.id) {
    //   setShowPromoMessage(true);
    //   setPromoMessage(t("errors.promo-not-exist"));
    //   return;
    // }

    const isValid = await isPromoValid(user, promo[0], isVoucher);
    if (!isValid) {
      setShowPromoMessage(true);
      setPromoMessage(t("errors.promo-not-exist"));
      return;
    }
    setShowPromoMessage(false);

    dispatch(enableIsPromoApplied());
    dispatch(addPromo(promo[0]));
    if (isVoucher) {
      dispatch(enableIsVoucher());
    } else {
      dispatch(disableIsVoucher());
    }
    const amountToDeduct = calculatePromoDeduct(promo[0], subtotal);
    if (parseFloat(amountToDeduct) >= total) {
      amountToDeduct = total;
    }
    setPromoAmountToDeduct(parseFloat(amountToDeduct));
    setTotal((subtotal - amountToDeduct).toFixed(2));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !button.current.contains(event.target)
      ) {
        dispatch(disable());
        dispatch(closeCart());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!promoLoading && promoData) {
      applyPromo(promoData, false);
      setIsPromoLoading(false);
    } else if (promoLoading && !promoError) {
      setIsPromoLoading(true);
    } else if (!promoLoading && promoError && !promoData) {
      setIsPromoLoading(false);
      setIsPromoError(true);
    }
  }, [promoLoading]);

  useEffect(() => {
    if (!voucherLoading && voucherData) {
      applyPromo(voucherData, true);
      setIsVoucherLoading(false);
    } else if (voucherLoading && !voucherError) {
      setIsVoucherLoading(true);
    } else if (!voucherLoading && voucherError && !voucherData) {
      setIsVoucherLoading(false);
      setIsVoucherError(true);
    }
  }, [voucherLoading]);

  useEffect(() => {
    if (!productsLoading && productsData) {
      if (productsIds.length === 0) {
        setProducts([]);
      } else {
        const newProducts = productsIds.map((localProduct) => {
          const product = productsData.find(
            (product) => product.id === localProduct.id
          );
          if (product) {
            const newProduct = structuredClone(product);
            const discountedProduct = applyDiscountRules(
              [newProduct],
              discountRules,
              localProduct.selectedVariantId,
              localProduct.barcode
            );
            return {
              product: discountedProduct[0],
              barcode: localProduct.barcode,
              selectedVariantId: localProduct.selectedVariantId,
            };
          } else {
            dispatch(removeItem(localProduct.barcode));
          }
        });

        setProducts(newProducts);
      }
      setAreProductsLoading(false);
    } else if (productsLoading && !productsError) {
      setAreProductsLoading(true);
    } else if (!productsLoading && productsError && !productsData) {
      setAreProductsLoading(false);
      setAreProductsError(true);
    }
  }, [productsLoading]);

  useEffect(() => {
    if (productsIds?.length === 0) {
      dispatch(disableIsPromoApplied());
      dispatch(removePromo());
      setPromoValue("");
      setShowPromoMessage(false);
      setPromoRetries(0);
      setPromoMessage("");
      return;
    }

    let subtotal = 0;
    let vat = 0;
    let total = 0;

    productsIds.forEach((localProduct) => {
      const product = products.find(
        (product) => product?.barcode === localProduct?.barcode
      );
      if (product) {
        const quantity = localProduct.quantity;
        let productPrice = product?.product?.attributes?.discountedPrice
          ? product?.product?.attributes?.discountedPrice
          : product?.product?.attributes?.Price;
        let productPriceWithoutVAT = productPrice / 1.05;
        subtotal += productPrice * quantity;
        vat += (productPrice - productPriceWithoutVAT) * quantity;
      }
    });

    total = subtotal;
    if (checkout?.isPromoApplied) {
      let amountToDeduct = calculatePromoDeduct(checkout?.promo, subtotal);
      if (parseFloat(amountToDeduct) >= total) {
        amountToDeduct = total;
      }
      total = subtotal - amountToDeduct;
      setPromoAmountToDeduct(parseFloat(amountToDeduct));
      setPromoValue(checkout?.promo?.attributes?.Name);
    }

    setSubtotal((subtotal.toFixed(2) - vat.toFixed(2)).toFixed(2));
    setTotal(total.toFixed(2));
    setVat(vat.toFixed(2));
  }, [products, productsIds]);

  useEffect(() => {
    if(productsIds?.length === 0) {
      setProducts([]);
    }
  }, [productsIds])

  useEffect(() => {
    let totalValue = parseFloat(total);
    if (checkout?.isPromoApplied) {
      let amountToDeduct = calculatePromoDeduct(checkout?.promo, totalValue);
      if (parseFloat(amountToDeduct) >= totalValue) {
        amountToDeduct = totalValue;
      }
      totalValue = parseFloat(total) - parseFloat(amountToDeduct);
      setPromoAmountToDeduct(parseFloat(amountToDeduct));
      setPromoValue(checkout?.promo?.attributes?.Name);
      setTotal(totalValue.toFixed(2));
    }
  }, [checkout?.isPromoApplied, checkout?.promo]);

  return (
    <div className="cart">
      <button
        onClick={handleSelectOnClick}
        className={[
          "dropdown-label",
          "dropdown-select",
          deviceType == "mobile" ? "" : "text-underline-animation",
        ].join(" ")}
        ref={button}
      >
        <span>
          <CartSVG />
        </span>
      </button>
      <div
        className={[
          "dropdown-container",
          "cart-options",
          isOpen ? "active" : "hidden",
        ].join(" ")}
      >
        <div className="container">
          <LoaderOverlay
            loadingState={areProductsLoading}
            errorState={areProductsError}
            setErrorState={setAreProductsError}
            retryFunc={retryProduct}
          />
          <Close className="close-btn" onClick={handleSelectOnClick} />
          <h1 className="cart-title">{t("home.my-cart")}</h1>
          <h2 className="alert-for-stocks">
            {t("home.complete-your-order")}
            <span className="stock-keywords">{t("home.stock-runs-out")}</span>
          </h2>
          <div className="products">
            {products?.length === 0 ? (
              <h3 className="empty-list">{t("home.no-products-available")}</h3>
            ) : (
              <></>
            )}
            {products?.map((product, index) => {
              return <CartCard key={index} product={product} />;
            })}
          </div>
          {products?.length > 0 ? (
            <>
              <form className="promo" onSubmit={submitPromo}>
                <div className="promo-form-container">
                  <input
                    className="promo-input"
                    type="text"
                    placeholder={t("home.voucher-or-promo-code")}
                    value={promoValue}
                    onChange={(e) => setPromoValue(e.target.value)}
                  />
                  <button className="promo-btn" type="submit">
                    {t("home.apply")}
                  </button>
                </div>
                {showPromoMessage && (
                  <h3 className="promo-error">{promoMessage}</h3>
                )}
              </form>
              <div className="totals">
                {!areProductsLoading && (
                  <LoaderOverlay
                    loadingState={isPromoLoading}
                    errorState={isPromoError}
                    setErrorState={setIsPromoError}
                    retryFunc={retryPromo}
                  />
                )}
                {!areProductsLoading && !isPromoLoading && (
                  <LoaderOverlay
                    loadingState={isVoucherLoading}
                    errorState={isVoucherError}
                    setErrorState={setIsVoucherError}
                    retryFunc={retryVouchers}
                  />
                )}
                <div className="subtotal">
                  <span>
                    {t("home.subtotal")} . {products?.length} {t("home.items")}
                  </span>
                  <span>
                    {subtotal} {t("home.aed")}
                  </span>
                </div>
                <div className="shipping">
                  <span>{t("home.shipping")}</span>
                  <span className="calc-at-checkout">
                    {t("home.calculated-at-checkout")}
                  </span>
                </div>
                <div className="vat">
                  <span>{t("home.vat")}</span>
                  <span>
                    {vat} {t("home.aed")}
                  </span>
                </div>
                {checkout?.isPromoApplied && (
                  <div className="promo-total">
                    <span>
                      {checkout?.promo?.attributes?.Name ??
                        checkout?.promo?.attributes?.Code}
                    </span>
                    <span>
                      - {promoAmountToDeduct.toFixed(2)} {t("home.aed")}
                    </span>
                  </div>
                )}
                <div className="total">
                  <span>{t("home.total")}</span>
                  <span>
                    {total} {t("home.aed")}
                  </span>
                </div>
              </div>
              <div className="checkout-cart">
                <h3 className="continue-shopping" onClick={handleSelectOnClick}>
                  {t("home.continue-shopping")}
                </h3>
                <h3 className="reset-cart" onClick={clearCart}>{t("home.clear-cart")}</h3>
                <Link
                  to="/checkout"
                  className="cta-checkout"
                  onClick={handleSelectOnClick}
                >
                  {t("home.checkout")}
                </Link>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
