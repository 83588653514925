import React, { useState, useEffect } from "react";

import { encodeURL } from "../../helpers/common";

import { Link } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./ProductDetails.scss";

import { useTranslation } from "react-i18next";

const ProductDetails = ({ details }) => {
  const [open, setOpen] = useState(true);
  const [info, setInfo] = useState({});

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getCountryName = (country) => {
    let countryName = country?.data?.attributes?.Name;
    if(currentLanguage != "en") {
      const localization = country?.data?.attributes?.localizations?.data?.find(
        (local) => local?.attributes?.locale === currentLanguage
      );
      if (localization) {
        countryName = localization?.attributes?.Name
      }
    }

    return countryName;
  }

  const getCategoryName = (category) => {
    let categoryName = category?.attributes?.Title;
    if(currentLanguage != "en") {
      const localization = category?.attributes?.localizations?.data?.find(
        (local) => local?.attributes?.locale === currentLanguage
      );
      if (localization) {
        categoryName = localization?.attributes?.Title
      }
    }

    return categoryName;
  }

  const getTagName = (tag) => {
    let tagName = tag?.attributes?.Name;
    if(currentLanguage != "en") {
      const localization = tag?.attributes?.localizations?.data?.find(
        (local) => local?.attributes?.locale === currentLanguage
      );
      if (localization) {
        tagName = localization?.attributes?.Name
      }
    }

    return tagName;
  }

  useEffect(() => {
    setInfo(details);
  }, [details]);

  return (
    <div className="product-details">
      <button className="label-btn" onClick={() => setOpen((prev) => !prev)}>
        <span className="text">{t("product.product-details")}</span>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {open && (
        <div className="details-table">
          <div className="entry">
            <h3 className="label">{t("product.instructions")}</h3>
            <p className="values">{info?.attributes?.Instruction}</p>
          </div>
          <div className="entry">
            <h3 className="label">{t("product.country-of-origin")}</h3>
            <p className="values">
              {getCountryName(info?.attributes?.Country)}
            </p>
          </div>
          <div className="entry">
            <h3 className="label">{t("product.exchange-period")}</h3>
            <p className="values">{t("product.15-days")}</p>
          </div>
          {info?.attributes?.Tags?.data?.length > 0 && (
            <div className="entry">
              <h3 className="label">{t("product.tags")}</h3>
              <p className="values">
                {info?.attributes?.Tags?.data?.map((tag) => {
                  return (
                    <Link
                      key={tag?.id}
                      className="tag-link"
                      to={`/tag/${encodeURL(tag?.attributes?.Name)}`}
                    >
                      {getTagName(tag)}
                    </Link>
                  );
                })}
              </p>
            </div>
          )}
          {info?.attributes?.Categories?.data?.length > 0 && (
            <div className="entry">
              <h3 className="label">{t("product.categories")}</h3>
              <p className="values">
                {info?.attributes?.Categories?.data?.map((category) => {
                  return (
                    <Link
                      key={category?.id}
                      className="category-link"
                      to={`/category/${encodeURL(category?.attributes?.Title)}`}
                    >
                      {getCategoryName(category)}
                    </Link>
                  );
                })}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
