import React, { useState, useEffect } from "react";

import { CountrySelector } from "react-international-phone";

import "./InputWithFloatingLabel.scss";
import "react-international-phone/style.css";

const InputWithFloatingLabel = ({
  stateValue,
  setStateValue,
  type = "text",
  labelText,
  idText,
  phoneInput = false,
  updateCountryFromPhoneNumber
}) => {
  const [country, setCountry] = useState("ae");
  const [Init, setInit] = useState(true);

  const handleCountryChange = ({ iso2, dialCode }) => {
    setStateValue(`+${dialCode}`);
    setCountry(iso2);
  };

  useEffect(() => {
    if (phoneInput) {
      if (stateValue) {
        updateCountryFromPhoneNumber(stateValue, setCountry);
      }
    }
  }, [stateValue]);

  useEffect(() => {
    if (phoneInput) {
      if (!stateValue) {
        setStateValue(`+971`);
      }
    }
  }, []);

  return (
    <div className="input-container">
      {phoneInput ? (
        <>
          <div className="phone-container">
            <div className="country-selector">
              <CountrySelector
                selectedCountry={country}
                onSelect={handleCountryChange}
                buttonStyle={{ backgroundColor: "transparent", border: "none" }}
              />
            </div>
            <input
              className="account-input not-full-width"
              id={`account-${idText}`}
              type={type}
              value={stateValue}
              onChange={(e) => {
                setStateValue(e.target.value);
                setInit(false);
              }}
            />
            <label className="account-label" htmlFor={`account-${idText}`}>
              {labelText}
            </label>
          </div>
        </>
      ) : (
        <>
          <input
            className="account-input"
            id={`account-${idText}`}
            type={type}
            value={stateValue}
            onChange={(e) => setStateValue(e.target.value)}
          />
          <label className="account-label" htmlFor={`account-${idText}`}>
            {labelText}
          </label>
        </>
      )}
    </div>
  );
};

export default InputWithFloatingLabel;
