import React, { useEffect, useState } from "react";

import { getColorAndSize, isSelectedVariantOutOfStock } from "../../helpers/common";

import { useDispatch, useSelector } from "react-redux";
import { removeItem, editQty } from "../../redux/cartReducer";

import { Link } from "react-router-dom";

import "./CartCard.scss";
import { ReactComponent as Minus } from "../../SVGs/Minus.svg";
import { ReactComponent as Addition } from "../../SVGs/Addition.svg";
import { ReactComponent as Delete } from "../../SVGs/Delete.svg";

import { useTranslation } from "react-i18next";

const CartCard = ({ product, inCheckout = false }) => {
  const [productBarcode, setProductBarcode] = useState("");
  const [productQty, setProductQty] = useState(0);
  const [colorValue, setColorValue] = useState("");
  const [sizeValue, setSizeValue] = useState("");

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const dispatch = useDispatch();
  const productsIds = useSelector((state) => state.cart.products);

  const deleteProduct = () => {
    dispatch(removeItem(productBarcode));
  };

  const subtractQty = () => {
    dispatch(
      editQty({
        barcode: productBarcode,
        operation: "-",
      })
    );
  };

  const addQty = () => {
    const selectedVariant = product?.product?.attributes?.Variants?.find(variant => variant?.id == product?.selectedVariantId)
    if(selectedVariant) {
      const selectedSize = selectedVariant?.Sizes?.find(size => size?.Barcode == product?.barcode);
      if(selectedSize) {
        if(selectedSize?.Quantity >= productQty + 1) {
          dispatch(
            editQty({
              barcode: productBarcode,
              operation: "+",
            })
          );
        }
      } else {
        dispatch(removeItem(productBarcode));
      }
    } else {
      dispatch(removeItem(productBarcode));
    }
  };

  const stateProduct = productsIds.find(
    (item) => item?.barcode === product?.barcode
  );
  useEffect(() => {
    if (stateProduct) {
      setProductBarcode(stateProduct.barcode);
      setProductQty(stateProduct.quantity);
    } else {
      dispatch(removeItem(productBarcode));
    }
  }, [stateProduct]);

  useEffect(() => {
    const isOutOfStock = isSelectedVariantOutOfStock(
      product?.product,
      product?.barcode,
      product?.selectedVariantId
    )
    if(isOutOfStock) {
      dispatch(removeItem(product?.barcode));
    }
    const [color, size] = getColorAndSize(
      product?.product,
      product?.barcode,
      product?.selectedVariantId
    );
    if(!color || !size) {
      dispatch(removeItem(product?.barcode));
    }
    setColorValue(color);
    setSizeValue(size);
  }, [product]);

  return (
    <div className="cart-card">
      <div className="image">
        <img
          className="img"
          src={`${
            process.env.REACT_APP_IMAGE_URL +
            product?.product?.attributes?.DefaultImage?.data?.attributes?.url
          }`}
          alt={
            product?.product?.attributes?.DefaultImage?.data?.attributes
              ?.alternativeText
          }
        />
        <div className="product-quantity">
          <span>{inCheckout ? product?.qty : productQty}</span>
        </div>
      </div>
      <div className="info">
        <Link to={`/product/${product?.product?.attributes?.SEOURL}`} className="cart-card-title">
          {product?.product?.attributes?.Title}
        </Link>
        <h3 className="cart-card-barcode">
          {t("home.barcode")}: <span>{productBarcode}</span>
        </h3>
        <h3 className="cart-card-options">
          {t("home.color")}: <span>{colorValue}</span>, {t("home.size")}: <span>{sizeValue}</span>
        </h3>
        <div className="price">
          {product?.product?.attributes?.discountedPrice &&
          product?.product?.attributes?.discountedPrice !==
            product?.product?.attributes?.Price ? (
            <div className="real-price">
              <div className="price-container">
                <span className="numerical-price">
                  {product?.product?.attributes?.Price}
                </span>
                <span className="currency">{t("home.aed")}</span>
              </div>
              <span className="vat">{t("home.price-with-vat")}</span>
            </div>
          ) : (
            <></>
          )}
          <div className="discounted-price">
            <div className="price-container">
              <span className="numerical-price">
                {product?.product?.attributes?.discountedPrice ??
                  product?.product?.attributes?.Price}
              </span>
              <span className="currency">{t("home.aed")}</span>
            </div>
            <span className="vat">{t("home.price-with-vat")}</span>
          </div>
        </div>
        {!inCheckout ? (
          <>
            <div className="quantity">
              <span className="minus" onClick={subtractQty}>
                <Minus />
              </span>
              <span className="qty">{productQty}</span>
              <span className="addition" onClick={addQty}>
                <Addition />
              </span>
            </div>
            <Delete className="delete-btn" onClick={deleteProduct} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CartCard;
