import { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import "./ExchangeRequests.scss";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";

import { useTranslation } from "react-i18next";

const ExchangeRequests = () => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [data, setData] = useState({});
  const [metaData, setMetaData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "*",
    locale: "en",
    "pagination[page]": page,
    "pagination[pageSize]": pageSize,
    sort: "createdAt:desc",
    "filters[User][id][$eq]": user?.id,
    try: tries,
  });

  const pageBaseUrl = "/ecxhange-requests";
  const pageUrl = `${pageBaseUrl}?${Params.toString()}`;
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    meta: pageMeta,
  } = useFetch(pageUrl, true);

  useEffect(() => {
    if (!pageLoading && pageData) {
      setData(pageData);
      setMetaData(pageMeta);
      setLoading(false);
    } else if (pageLoading && !pageError) {
      setLoading(true);
    } else if (!pageLoading && pageError && !pageData) {
      setLoading(false);
      setError(true);
    }
  }, [pageLoading]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t(
      "home.exchange-requests"
    )}`;
  }, []);

  return (
    <section className="exchange-requests-listing">
      <h1 className="exchange-requests-title">{t("home.exchange-requests")}</h1>
      <div className="exchange-requests-container">
        {data && (
          <>
            <table className="exchange-requests-table">
              <thead>
                <th>{t("orders.id")}</th>
                <th>{t("orders.status")}</th>
                <th>{t("orders.date")}</th>
                <th>{t("exchange.order")}</th>
                <th>{t("exchange.product-barcode")}</th>
                <th>{t("orders.actions")}</th>
              </thead>
              <tbody>
                <LoaderOverlay
                  loadingState={loading}
                  errorState={error}
                  setErrorState={setError}
                  retryFunc={retry}
                />
                {!loading &&
                  data?.map((exchange, index) => {
                    const date = new Date(exchange?.attributes?.createdAt);

                    const formattedDate = new Intl.DateTimeFormat("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }).format(date);

                    return (
                      <tr key={index}>
                        <td>#{exchange?.id}</td>
                        <td>{exchange?.attributes?.Status}</td>
                        <td>{formattedDate}</td>
                        <td>
                          <Link
                            to={`/order/${exchange?.attributes?.Order?.data?.id}`}
                            className="exchange-requests-link"
                          >
                            #{exchange?.attributes?.Order?.data?.id}
                          </Link>
                        </td>
                        <td>{exchange?.attributes?.Barcode}</td>
                        <td>
                          <Link
                            to={`/exchange-details/${exchange?.id}`}
                            className="exchange-requests-link"
                          >
                            {t("orders.details")}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {metaData?.pagination?.total > 12 ? (
              <div className="exchange-requests-pagination-arrows">
                <span
                  className={[
                    "paginate",
                    metaData?.pagination?.page !== 1 ? "active" : "",
                  ].join(" ")}
                  onClick={() => {
                    if (metaData?.pagination?.page !== 1) {
                      setPage((prev) => prev - 1);
                    }
                  }}
                >
                  {t("orders.previous")}
                </span>
                <div className="paginate-page">
                  {t("orders.page")}: {metaData?.pagination?.page}{" "}
                  {t("orders.of")} {metaData?.pagination?.pageCount}
                </div>
                <span
                  className={[
                    "paginate",
                    metaData?.pagination?.page !==
                    metaData?.pagination?.pageCount
                      ? "active"
                      : "",
                  ].join(" ")}
                  onClick={() => {
                    if (
                      metaData?.pagination?.page !==
                      metaData?.pagination?.pageCount
                    ) {
                      setPage((prev) => prev + 1);
                    }
                  }}
                >
                  {t("orders.next")}
                </span>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        {!loading && !data && (
          <div className="exchange-requests-page-error">
            {t("exchange.no-exchanges")}!
          </div>
        )}
      </div>
    </section>
  );
};

export default ExchangeRequests;
