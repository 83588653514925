import { useState } from "react";
import axios from "axios";
import countries from "i18n-iso-countries";
import { useTranslation } from "react-i18next";

const useSMSAShipping = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tries, setTries] = useState(0);

  const retry = () => {
    setTries((prev) => prev + 1);
  };

  const createSMSAShipment = async (
    countryName,
    cityName,
    postalCode,
    addressLine1,
    addressLine2,
    customerName,
    customerPhone,
    shippingProductCode,
    total,
    totalCurrency,
    description,
    pkgs,
    orderID
  ) => {
    setLoading(true);
    setError("");

    const today = new Date();
    let plannedShippingDate = new Date(today);
    plannedShippingDate.setDate(today.getDate() + 1);

    if (plannedShippingDate.getDay() === 0) {
      plannedShippingDate.setDate(plannedShippingDate.getDate() + 1);
    }

    // Format the date as YYYY-MM-DD
    const dateWithoutTime = `${plannedShippingDate.getFullYear()}-${String(
      plannedShippingDate.getMonth() + 1
    ).padStart(2, "0")}-${String(plannedShippingDate.getDate()).padStart(
      2,
      "0"
    )}`;
    const formattedPlannedShippingDate = `${dateWithoutTime}T12:00:00`;

    // Get country
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${currentLanguage}.json`)
    );
    const countryIso2Code = countries.getAlpha2Code(
      countryName,
      currentLanguage
    );

    let totalWeight = 0;
    for(const pkg of pkgs) {
        totalWeight += pkg?.weight
    }

    // Define the API endpoint and query parameters
    const url = `${process.env.REACT_APP_API_URL}smsa-create-shipment`;
    const params = {
      ConsigneeAddress: {
        ContactName: customerName,
        ContactPhoneNumber: customerPhone,
        Country: countryIso2Code,
        PostalCode: postalCode,
        City: cityName,
        AddressLine1: addressLine1,
      },
      ShipperAddress: {
        ContactName: "CLASSY Y Y M GARMENTS TRADING L.L.C",
        ContactPhoneNumber: "+971565255245",
        Country: "AE",
        PostalCode: "00000",
        City: "Ajman",
        AddressLine1: "Al JURF 3 Jeddah street",
      },
      OrderNumber: `NWO${orderID}`,
      DeclaredValue: total,
      CODAmount: 0,
      Parcels: pkgs?.length,
      ShipDate: formattedPlannedShippingDate,
      ShipmentCurrency: totalCurrency,
      WaybillType: "PDF",
      Weight: parseFloat(totalWeight.toFixed(2)),
      WeightUnit: "KG",
      ContentDescription: description,
      ServiceCode: shippingProductCode,
    };

    if(addressLine2?.trim() != "") {
        params.ConsigneeAddress.AddressLine2 = addressLine2
    }

    let trackingNumber = "";
    let pdfDocument = "";
    try {
      const response = await axios.post(url, params);
      trackingNumber = response?.data?.sawb;
      pdfDocument = response?.data?.waybills[0]?.awbFile;
    } catch (err) {
      setError("Failed to create shipment.");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setLoading(false);
    return [trackingNumber, pdfDocument];
  };

  return {
    createSMSAShipment,
    loading,
    error,
    setError,
    retry,
  };
};

export default useSMSAShipping;
