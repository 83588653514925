import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const useNetworkPayment = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [paymentLink, setPaymentLink] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null)

  const initiatePayment = async (currency, amount, email) => {
    setLoading(true);
    setError("");
    setPaymentLink(null);

    try {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}ngenuis`,
        {
          currency: currency,
          amount: parseFloat(amount),
          emailAddress: email,
          language: currentLanguage,
        },
      );
  
      // Return Payment Link
      setPaymentLink(orderResponse?.data?.data);
      return paymentLink;
    } catch (err) {
      console.error("Payment error:", err);
      setError("Failed to initiate payment.");
      setTimeout(() => {
        setError("");
      }, 5000);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getPayment = async (ref) => {
    setLoading(true);
    setError("");
    setPaymentResponse(null)

    try {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}ngenuis-check-status`,
        {
          ref: ref
        },
      );
  
      // Return Payment Link
      setPaymentResponse(orderResponse.data)
      return orderResponse.data
    } catch (err) {
      console.error("Payment error:", err);
      setError("Failed to initiate payment.");
      setTimeout(() => {
        setError("");
      }, 5000);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { initiatePayment, getPayment, loading, error, paymentLink, paymentResponse };
};

export default useNetworkPayment;
