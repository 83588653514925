import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { removeOrderID, disableIsPromoApplied, disableIsVoucher, removePromo, removeShippingData, setVoucherAmount, removePoints } from "../../redux/checkoutReducer";
import { resetCart } from "../../redux/cartReducer";

import { Link, useNavigate } from "react-router-dom";

import {
  updateSizeQuantity,
  updateVoucher,
  updatePoints
} from "../../helpers/common";

import "./Success.scss";
import { ReactComponent as OrderSubmitted } from "../../SVGs/OrderSubmitted.svg";

import { useTranslation } from "react-i18next";

const Success = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const checkout = useSelector((state) => state.checkout);
  const productsIds = useSelector((state) => state.cart.products);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    setOrderId(checkout?.orderID);
    setTimeout(async () => {
      if(checkout?.isVoucher) {
        await updateVoucher(checkout?.promo, checkout?.voucherAmount)
        dispatch(setVoucherAmount(0))
      }
      await updateSizeQuantity(productsIds, checkout?.orderID);
      await updatePoints(checkout?.points, user?.id);
      dispatch(removePoints());
      dispatch(removeOrderID());
      dispatch(resetCart());
      dispatch(disableIsPromoApplied());
      dispatch(disableIsVoucher());
      dispatch(removePromo());
      dispatch(removeShippingData());
    }, 3000);
  }, []);

  useEffect(() => {
    if (!checkout?.orderID) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t("success.order-successful")}`;
  }, []);

  return (
    <section className="success-page">
      <div className="success-page-container">
        <OrderSubmitted />
        <div className="success-page-message-container">
          <div className="success-page-label">{t("success.thank-you")}!</div>
          <div className="success-page-text-container">
            <h3 className="success-page-text">
              {t("success.order-placed")}
            </h3>
            <h3 className="success-page-text">
              {t("success.order-ref-num")}{" "}
              <span className="success-page-order-id">#{orderId}</span>
            </h3>
          </div>
          <h3 className="success-page-action-message">
            {t("success.you-can-view-your")}{" "}
            <Link className="success-page-link" to="/orders">
              {t("success.orders")}
            </Link>
            , {t("success.or-return-to")}{" "}
            <Link className="success-page-link" to="/">
              {t("success.homepage")}
            </Link>
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Success;
