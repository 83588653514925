import { useState, useEffect } from "react";

import useFetch from "./useFetch";

import { useTranslation } from "react-i18next";

const useFilters = () => {
  const [filters, setFilters] = useState([]);
  const [areFiltersLoading, setAreFiltersLoading] = useState(true);
  const [areFiltersError, setAreFiltersError] = useState(false);
  const [FiltersRetries, setFiltersRetries] = useState(0);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const retryFilters = () => setFiltersRetries((prev) => prev + 1);

  const {
    data: activeFiltersData,
    loading: activeFiltersLoading,
    error: activeFiltersError,
  } = useFetch("/filter");

  const Params = new URLSearchParams({
    populate: "*",
    locale: 'en',
    try: FiltersRetries,
    "pagination[limit]": -1,
  });

  // get colors
  const colorsBaseUrl = "/colors";
  const colorsUrl = `${colorsBaseUrl}?${Params.toString()}`;
  const {
    data: colorsData,
    loading: colorsLoading,
    error: colorsError,
  } = useFetch(colorsUrl);

  // get vendors
  const vendorsBaseUrl = "/vendors";
  const vendorsUrl = `${vendorsBaseUrl}?${Params.toString()}`;
  const {
    data: vendorsData,
    loading: vendorsLoading,
    error: vendorsError,
  } = useFetch(vendorsUrl);

  // get sizes
  const sizesBaseUrl = "/sizes";
  const sizesUrl = `${sizesBaseUrl}?${Params.toString()}`;
  const {
    data: sizesData,
    loading: sizesLoading,
    error: sizesError,
  } = useFetch(sizesUrl);

  useEffect(() => {
    const filtersArray = [];

    if (activeFiltersError || colorsError || vendorsError || sizesError) {
      setAreFiltersError(true);
      setAreFiltersLoading(false);
      return;
    }

    if (
      !activeFiltersLoading &&
      !colorsLoading &&
      !vendorsLoading &&
      !sizesLoading &&
      activeFiltersData?.attributes
    ) {
      const {
        ColorFilter,
        SizeFilter,
        PriceFilter,
        VendorFilter,
        Price1,
        Price2,
        Price3,
      } = activeFiltersData?.attributes;

      const sortedSizes = [];
      const sortOrder = ["xs", "s", "m", "l", "xl"];

      sizesData?.forEach((element) => sortedSizes.push(element));

      sortedSizes.sort(
        (a, b) =>
          sortOrder.indexOf(a?.attributes?.Name?.toLowerCase()) -
          sortOrder.indexOf(b?.attributes?.Name?.toLowerCase())
      );

      if (ColorFilter && !colorsLoading && colorsData)
        filtersArray.push({
          title: "Color",
          values: colorsData,
        });
      if (VendorFilter && !vendorsLoading && vendorsData)
        filtersArray.push({
          title: "Vendor",
          values: vendorsData,
        });
      if (SizeFilter && !sizesLoading && sizesData)
        filtersArray.push({
          title: "Size",
          values: sortedSizes,
        });
      if (PriceFilter)
        filtersArray.push({
          title: "Price",
          values: [
            { id: Price1, attributes: { Name: `Below ${Price1}` } },
            { id: Price2, attributes: { Name: `Below ${Price2}` } },
            { id: Price3, attributes: { Name: `Below ${Price3}` } },
          ],
        });

      setFilters(filtersArray);
      setAreFiltersLoading(false);
    }
  }, [activeFiltersLoading, colorsLoading, vendorsLoading, sizesLoading]);

  return {
    filters,
    areFiltersLoading,
    areFiltersError,
    setAreFiltersError,
    retryFilters,
  };
};

export { useFilters };
