import { useState, useEffect } from "react";

import useFetch from "./useFetch";

import { useTranslation } from "react-i18next";

const usePaymentMethods = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    "populate": '*',
    locale: 'en',
    "filters[IsActive][$eq]": true,
    try: tries,
  });

  // get payment methods
  const paymentMethodsBaseUrl = "/payment-methods";
  const paymentMethodsUrl = `${paymentMethodsBaseUrl}?${Params.toString()}`;
  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    error: paymentMethodsError,
  } = useFetch(paymentMethodsUrl);

  useEffect(() => {
    if (paymentMethodsError) {
      setError(true);
      setLoading(false);
      return;
    }

    if (
      !paymentMethodsLoading &&
      paymentMethodsData?.length > 0
    ) {
      setData(paymentMethodsData);
      setLoading(false);
    }
  }, [paymentMethodsLoading]);

  return {
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export { usePaymentMethods };
