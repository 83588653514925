import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enabled: false
};

export const overlayReducer = createSlice({
  name: "overlay",
  initialState,
  reducers: {
    enable: (state, action) => {
        state.enabled = true
    },
    disable: (state, action) => {
        state.enabled = false
    },
  },
});

// Action creators are generated for each case reducer function
export const { enable, disable } = overlayReducer.actions;

export default overlayReducer.reducer;
