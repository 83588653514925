import React, { useState } from "react";

import "./LoaderOverlay.scss";

import { useTranslation } from "react-i18next";

const LoaderOverlay = ({
  loadingState,
  errorState,
  setErrorState,
  retryFunc,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div
      className={[
        "loader-overlay",
        loadingState || errorState ? "show" : "none",
      ].join(" ")}
    >
      {loadingState && <span className="loading" style={{"transform": currentLanguage == 'en' ? "translate(-50%, -50%)" : "translate(50%, -50%)"}}>{t("home.loading")}</span>}
      {errorState && (
        <div className="error-container">
          <span className="error">{t("home.an-error-occured")}</span>
          <button
            className="error-btn"
            onClick={(event) => {
              event.target.disabled = true;
              event.target.style.cursor = "auto";
              setErrorState(false);
              retryFunc();
            }}
          >
            {t("home.retry")}
          </button>
        </div>
      )}
    </div>
  );
};

export default LoaderOverlay;
