import { useState } from "react";
import { makeRequest } from "../makeRequest";
import { useTranslation } from "react-i18next";

const useSettings = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tries, setTries] = useState(0);

  const retry = () => {
    setTries((prev) => prev + 1);
  };

  const getSiteSettings = async () => {
    setLoading(true);
    setError("");
    let result = [];

    const Params = new URLSearchParams({
      populate: "*",
      "pagination[limit]": -1,
      tries: tries,
    });

    // get settings
    const settingsBaseUrl = "/settings";
    const settingsUrl = `${settingsBaseUrl}?${Params.toString()}`;

    try {
      setLoading(true);
      result = await makeRequest.get(settingsUrl);
    } catch (err) {
      setError(t("errors.unknown"));
    }
    setLoading(false);
    setData(result?.data?.data)
  };

  return {
    getSiteSettings,
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export default useSettings;
