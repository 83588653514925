import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { disable, enable } from "../../redux/overlayReducer";
import { resetCart } from "../../redux/cartReducer";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./LanguageSelector.scss";
import useDeviceType from "../../hooks/useDeviceType";

import { useTranslation } from "react-i18next";

const LanguageSelector = ({ languagesArray }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [open, setOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(currentLanguage);
  const button = useRef(null);
  const deviceType = useDeviceType();
  const dispatch = useDispatch();

  const languages = languagesArray?.map((el) => {
    return {
      id: el?.id,
      code: el?.attributes?.Code,
      language: el?.attributes?.Name,
    };
  });

  const handleSelectOnClick = () => {
    setOpen((prev) => {
      if (deviceType == "desktop") {
        if (prev) {
          dispatch(disable());
        } else {
          dispatch(enable());
        }
      }
      return !prev;
    });
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const handleOptionOnClick = (event) => {
    const lang = event.target.textContent.toLowerCase();
    changeLanguage(lang);
    setSelectedLang(lang)
    dispatch(disable());
    dispatch(resetCart());
    setOpen(false);
  };

  // disable scoll bar when overlay is present
  // if(open) {
  //     document.body.style.overflow = 'scroll';
  // } else {
  //     document.body.style.overflow = 'auto';
  // }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !button.current.contains(event.target)
      ) {
        dispatch(disable());
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="lang-selector">
      <button
        onClick={handleSelectOnClick}
        className="dropdown-label language-select text-underline-animation"
        ref={button}
      >
        {languages && (
          <>
            <span>{languages ? selectedLang.toUpperCase() : t("home.loading")}</span>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </>
        )}
      </button>
      <div
        className={[
          "dropdown-container",
          "dropdown-options",
          open ? "active" : "hidden",
        ].join(" ")}
      >
        {languages?.map((menuItem) => {
          return (
            <li
              key={menuItem.id}
              id={menuItem.id}
              className="list-item"
              value={menuItem.code}
              onClick={handleOptionOnClick}
            >
              <span>{menuItem.code}</span>
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSelector;
