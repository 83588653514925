import React, { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./SizeTable.scss";

import { useTranslation } from "react-i18next";

const SizeTable = ({ details }) => {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    setInfo(details);
  }, [details]);

  return (
    <div className="size-table">
      <button className="label-btn" onClick={() => setOpen((prev) => !prev)}>
        <span className="text">{t("product.size-&-fit")}</span>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {open && (
        <div
          className="size-guide"
          dangerouslySetInnerHTML={{ __html: details?.attributes?.SizeGuide }}
        ></div>
      )}
    </div>
  );
};

export default SizeTable;
