import { useState } from "react";
import { makeRequest } from "../makeRequest";
import { useTranslation } from "react-i18next";

const useGetOffers = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tries, setTries] = useState(0);

  const retry = () => {
    setTries((prev) => prev + 1);
  };

  const getOffers = async () => {
    setLoading(true);
    setError("");
    let result = [];

    const Params = new URLSearchParams({
      populate: "*",
      "pagination[limit]": -1,
      "filters[Status][$eq]": true,
      tries: tries,
    });

    // get offers
    const offersBaseUrl = "/offers";
    const offersUrl = `${offersBaseUrl}?${Params.toString()}`;

    try {
      setLoading(true);
      result = await makeRequest.get(offersUrl);
    } catch (err) {
      setError(t("errors.unknown"));
    }
    setLoading(false);
    setData(result?.data?.data);
  };

  return {
    getOffers,
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export default useGetOffers;
