import React, { useState, useEffect, useRef } from "react";

import useDeviceType from "../../hooks/useDeviceType";

import { useDispatch, useSelector } from "react-redux";
import { disable, enable } from "../../redux/overlayReducer";
import { removeUser } from "../../redux/userReducer";

import { Link } from "react-router-dom";

import "./ProfileDropdown.scss";
import { ReactComponent as Profile } from "../../SVGs/Profile.svg";

import { useTranslation } from "react-i18next";

const ProfileDropdown = () => {
  const deviceType = useDeviceType();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [open, setOpen] = useState(false);

  const button = useRef(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const handleSelectOnClick = () => {
    setOpen((prev) => {
      if (prev) {
        dispatch(disable());
      } else {
        dispatch(enable());
      }
      return !prev;
    });
  };

  // disable scoll bar when overlay is present
  // if(open) {
  //     document.body.style.overflow = 'scroll';
  // } else {
  //     document.body.style.overflow = 'auto';
  // }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !button.current.contains(event.target)
      ) {
        dispatch(disable());
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="profile-dropdown">
      <button
        onClick={handleSelectOnClick}
        className={[
          "dropdown-label",
          "profile-select",
          deviceType == "mobile" ? "" : "text-underline-animation",
        ].join(" ")}
        ref={button}
      >
        <span>
          <Profile />
        </span>
      </button>
      <div
        className={[
          "dropdown-container",
          "dropdown-options",
          open ? "active" : "hidden",
        ].join(" ")}
      >
        {Object.keys(user).length === 0 ? (
          <>
            <div className="profile-dropdown-link-container">
              <Link
                className="list-item"
                to="/login"
                onClick={() => {
                  setOpen(false);
                  dispatch(disable());
                }}
              >
                <span>{t("home.login")}</span>
              </Link>
              <Link
                className="list-item"
                to="/register"
                onClick={() => {
                  setOpen(false);
                  dispatch(disable());
                }}
              >
                <span>{t("home.register")}</span>
              </Link>
            </div>
          </>
        ) : (
          <>
            <span className="profile-dropdown-title">
              {t("home.welcome")} {user?.FirstName} {user?.LastName}
            </span>
            <div className="profile-dropdown-link-container">
              <Link
                className="list-item"
                to="/account"
                onClick={() => {
                  setOpen(false);
                  dispatch(disable());
                }}
              >
                <span>{t("home.my-details")}</span>
              </Link>
              <Link
                className="list-item"
                to="/address-book"
                onClick={() => {
                  setOpen(false);
                  dispatch(disable());
                }}
              >
                <span>{t("home.address-book")}</span>
              </Link>
              <Link
                className="list-item"
                to="/orders"
                onClick={() => {
                  setOpen(false);
                  dispatch(disable());
                }}
              >
                <span>{t("home.my-orders")}</span>
              </Link>
              <Link
                className="list-item"
                to="/exchange-requests"
                onClick={() => {
                  setOpen(false);
                  dispatch(disable());
                }}
              >
                <span>{t("home.exchange-requests")}</span>
              </Link>
              <Link
                className="list-item"
                to="/"
                onClick={() => {
                  setOpen(false);
                  dispatch(disable());
                  dispatch(removeUser());
                }}
              >
                <span>{t("home.logout")}</span>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileDropdown;
