import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const normalizeLanguage = (lng) => {
  if (!lng) return "en";
  if (lng.includes("ar")) return "ar";
  return "en";
};

const updateHtmlDirection = (lng) => {
  const html = document.documentElement;
  if (lng === "ar") {
    html.setAttribute("dir", "rtl");
    html.setAttribute("lang", "ar");
  } else {
    html.setAttribute("dir", "ltr");
    html.setAttribute("lang", lng);
  }
};

i18n
  .use(HttpBackend) // Load translations using HTTP
  .use(LanguageDetector) // Detect the user's language
  .use(initReactI18next) // Bind i18next to React
  .init({
    fallbackLng: "en", // Default language
    debug: true, // Enable debug mode for development
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
    },
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      // order: ["localStorage", "cookie", "navigator"],
      order: ["localStorage", "cookie"],
      caches: ["localStorage", "cookie"],
    },
    lng: localStorage.getItem("i18nextLng") || "en"
  });

// Listen to language change events
i18n.on("languageChanged", (lng) => {
  const normalizedLang = normalizeLanguage(lng);
  updateHtmlDirection(normalizedLang);
});

// Set initial direction
updateHtmlDirection(normalizeLanguage(i18n.language));

export default i18n;
