import { makeRequest } from "../makeRequest";
import { PhoneNumberUtil } from "google-libphonenumber";
import axios from "axios";

export const encodeURL = (url) => {
  /*
    const snakeCase = url.toLowerCase().replace(/\s+/g, '-') // Replace spaces with underscores
    */
  return encodeURIComponent(url?.toLowerCase());
};

export const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const calculatePromoDeduct = (promo, subtotal) => {
  if (promo?.attributes?.Type === "Percentage") {
    return (subtotal * (promo?.attributes?.Amount / 100)).toFixed(2);
  } else {
    return (promo?.attributes?.Amount).toFixed(2);
  }
};

export const isPromoValid = async (user, promoParameter, isVoucher) => {
  // re-fetch the promo
  let promo = {};

  if (isVoucher) {
    // re-fetch voucher
    const promoBaseUrl = "/vouchers";
    const promoParams = new URLSearchParams({
      populate: "*",
      "filters[Code][$eq]": promoParameter?.attributes?.Code,
    });
    const promoUrl = `${promoBaseUrl}?${promoParams?.toString()}`;

    try {
      const res = await makeRequest.get(promoUrl);

      if (res?.data?.meta?.pagination?.total > 0) {
        promo = res?.data?.data[0];
      }
    } catch (err) {
      return false;
    }
  } else {
    // re-fetch promo
    const promoBaseUrl = "/promo-codes";
    const promoParams = new URLSearchParams({
      populate: "*",
      "filters[Name][$eq]": promoParameter?.attributes?.Name,
    });
    const promoUrl = `${promoBaseUrl}?${promoParams?.toString()}`;

    try {
      const res = await makeRequest.get(promoUrl);

      if (res?.data?.meta?.pagination?.total > 0) {
        promo = res?.data?.data[0];
      }
    } catch (err) {
      return false;
    }
  }

  // check promo expiry date
  const expiryDate = promo?.attributes?.ExpiryDate;

  if (expiryDate) {
    const today = new Date();
    const expiry = new Date(expiryDate);

    if (expiry < today) return false;
  }

  // check promo status
  if (!isVoucher && promo?.attributes?.Status !== true) return false;

  // check promo uses per customer
  if (!isVoucher && Object.keys(user).length > 0) {
    const promoBaseUrl = "/orders";
    const promoParams = new URLSearchParams({
      populate: "*",
      "filters[User][id][$eq]": user?.id,
      "filters[CouponCode][$eq]": promo?.attributes?.Name,
    });
    const promoUrl = `${promoBaseUrl}?${promoParams?.toString()}`;

    try {
      const res = await makeRequest.get(promoUrl);

      if (
        res?.data?.meta?.pagination?.total >= promo?.attributes?.UsesPerCustomer
      ) {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  // check promo total uses
  if (!isVoucher) {
    const promoBaseUrl = "/orders";
    const promoParams = new URLSearchParams({
      populate: "*",
      "filters[CouponCode][$eq]": promo?.attributes?.Name,
    });
    const promoUrl = `${promoBaseUrl}?${promoParams?.toString()}`;

    try {
      const res = await makeRequest.get(promoUrl);

      if (
        res?.data?.meta?.pagination?.total >= promo?.attributes?.TotalUsesNumber
      ) {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  // check if voucher active
  if (
    isVoucher &&
    promo?.attributes?.Status !== "Active" &&
    promo?.attributes?.Status !== "Used"
  )
    return false;

  // check if voucher is for current user
  // if (isVoucher && promo?.attributes?.User?.data?.id !== user?.id) return false;

  return true;
};

export const normalizeDate = (date) => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

export const getCheapestProduct = (products) => {
  if (products?.length === 1) {
    return products[0];
  }

  let cheapestProduct = null;
  for (const product of products) {
    if (!cheapestProduct) {
      cheapestProduct = product;
      continue;
    }

    const cheapestProductPrice =
      cheapestProduct?.product?.attributes?.discountedPrice ??
      cheapestProduct?.product?.attributes?.Price;

    const productPrice =
      product?.product?.attributes?.discountedPrice ??
      product?.product?.attributes?.Price;

    if (cheapestProductPrice > productPrice) {
      cheapestProduct = product;
    }
  }

  return cheapestProduct;
};

export const getNCheapestProducts = (n, products) => {
  const sortedProducts = products.toSorted((a, b) => {
    const aPrice =
      a?.product?.attributes?.discountedPrice ?? a?.product?.attributes?.Price;
    const bPrice =
      b?.product?.attributes?.discountedPrice ?? b?.product?.attributes?.Price;

    return aPrice - bPrice;
  });
  const offeredProducts = [];
  let num = n;
  while (num > 0) {
    for (const product of sortedProducts) {
      if (product?.qty <= num) {
        offeredProducts.push({
          productID: product?.product?.id,
          quantity: product?.qty,
        });
        num -= product?.qty;
      } else {
        if (num <= 0) break;

        offeredProducts.push({
          productID: product?.product?.id,
          quantity: num,
        });
        num = 0;
        break;
      }
    }
  }
  return offeredProducts;
};

export const applyDiscountRules = (
  products,
  discountRules,
  variantId = null,
  barcode = null,
  selectedCountryID = 0
) => {
  if (
    !discountRules?.isLoading &&
    !discountRules?.isError &&
    products &&
    products?.length > 0 &&
    Object.keys(products[0])?.length > 0
  ) {
    const rules = [...discountRules.rules].sort(
      (a, b) => a?.attributes?.Priority - b.attributes?.Priority
    );

    for (const product of products) {
      let newPrice = product?.attributes?.Price;

      if (product?.attributes?.SpecialPrice) {
        product.attributes.discountedPrice = product?.attributes?.SpecialPrice;
        continue;
      }

      const categories = product?.attributes?.Categories?.data?.map(
        (cat) => cat?.id
      );
      const tags = product?.attributes?.Tags?.data?.map((tag) => tag?.id);

      for (const rule of rules) {
        if (rule?.attributes?.FreeShipping) {
          continue;
        }

        const today = normalizeDate(new Date());
        const fromDate = normalizeDate(rule?.attributes?.FromDate);
        const toDate = normalizeDate(rule?.attributes?.ToDate);

        if (toDate < today || fromDate > today) {
          continue;
        }

        if (rule?.attributes?.ExcludeProducts?.data?.length > 0) {
          const isProductExcluded =
            rule?.attributes?.ExcludeProducts?.data?.find(
              (prod) => prod?.id === product?.id
            );
          if (isProductExcluded) {
            continue;
          }
        }

        if (rule?.attributes?.IncludeProducts?.data?.length > 0) {
          const isProductIncluded =
            rule?.attributes?.IncludeProducts?.data?.find(
              (prod) => prod?.id === product?.id
            );
          if (!isProductIncluded) {
            continue;
          }
        }

        let amountToDeduct = 0;
        if (rule?.attributes?.Type === "Percentage") {
          amountToDeduct = (newPrice * (rule?.attributes?.Value / 100)).toFixed(
            2
          );
        } else {
          amountToDeduct = (rule?.attributes?.Value).toFixed(2);
        }

        const ruleFilters = {
          category: false,
          colors: false,
          country: false,
          sizes: false,
          tags: false,
        };
        const matchingObject = {
          category: false,
          colors: false,
          country: false,
          sizes: false,
          tags: false,
        };

        if (rule?.attributes?.categories?.data?.length > 0)
          ruleFilters.category = true;
        if (rule?.attributes?.colors?.data?.length > 0)
          ruleFilters.colors = true;
        if (rule?.attributes?.country?.data?.id) ruleFilters.country = true;
        if (rule?.attributes?.sizes?.data?.length > 0) ruleFilters.sizes = true;
        if (rule?.attributes?.tags?.data > 0) ruleFilters.tags = true;

        if (ruleFilters.category) {
          for (const category of rule?.attributes?.categories?.data) {
            if (categories?.find((cat) => cat === category?.id)) {
              matchingObject.category = true;
              break;
            }
          }
        }

        if (ruleFilters.colors && variantId) {
          const selectedVariant = product?.attributes?.Variants?.find(
            (variant) => variant.id === variantId
          );
          if (selectedVariant) {
            for (const color of rule?.attributes?.colors?.data) {
              if (color?.id === selectedVariant?.Color?.data?.id) {
                matchingObject.colors = true;
                break;
              }
            }
          }
        }

        if (ruleFilters.country && selectedCountryID) {
          const countryDiscountId = rule?.attributes?.country?.data?.id ?? null;
          if (countryDiscountId && countryDiscountId == selectedCountryID) {
            matchingObject.country = true;
          }
        }

        if (ruleFilters.sizes && barcode) {
          const selectedVariant = product?.attributes?.Variants?.find(
            (variant) => variant.id === variantId
          );
          if (selectedVariant) {
            const selectedSize = selectedVariant?.Sizes?.find(
              (size) => size?.Barcode === barcode
            );
            if (selectedSize) {
              for (const size of rule?.attributes?.sizes?.data) {
                if (size?.id === selectedSize?.Size?.data?.id) {
                  matchingObject.sizes = true;
                  break;
                }
              }
            }
          }
        }

        if (ruleFilters.tags) {
          for (const tag of rule?.attributes?.tags?.data) {
            if (tags.find((t) => t === tag?.id)) {
              matchingObject.tags = true;
              break;
            }
          }
        }

        // check if all filters are correct
        if (
          ruleFilters.category === matchingObject.category &&
          ruleFilters.colors === matchingObject.colors &&
          ruleFilters.country === matchingObject.country &&
          ruleFilters.sizes === matchingObject.sizes &&
          ruleFilters.tags === matchingObject.tags
        ) {
          newPrice -= amountToDeduct;
          break;
        }
      }

      if (newPrice === product?.attributes?.Price) {
        product.attributes.discountedPrice = null;
      } else {
        product.attributes.discountedPrice = newPrice;
      }
    }
  }
  return products;
};

export const calculateFreeShipping = (
  products,
  discountRules,
  subtotal,
  shippingProvider,
  selectedCountryID = 0
) => {
  if (
    !discountRules?.isLoading &&
    !discountRules?.isError &&
    products &&
    products?.length > 0
  ) {
    const rules = [...discountRules.rules].sort(
      (a, b) => a?.attributes?.Priority - b.attributes?.Priority
    );

    for (const rule of rules) {
      if (!rule?.attributes?.FreeShipping) {
        continue;
      }

      let hasFreeShipping = true;

      const today = normalizeDate(new Date());
      const fromDate = normalizeDate(rule?.attributes?.FromDate);
      const toDate = normalizeDate(rule?.attributes?.ToDate);

      if (toDate < today || fromDate > today) {
        continue;
      }

      const ruleFilters = {
        category: false,
        colors: false,
        country: false,
        sizes: false,
        tags: false,
      };

      if (rule?.attributes?.categories?.data?.length > 0)
        ruleFilters.category = true;
      if (rule?.attributes?.colors?.data?.length > 0) ruleFilters.colors = true;
      if (rule?.attributes?.country?.data?.id) ruleFilters.country = true;
      if (rule?.attributes?.sizes?.data?.length > 0) ruleFilters.sizes = true;
      if (rule?.attributes?.tags?.data > 0) ruleFilters.tags = true;

      for (const product of products) {
        if (rule?.attributes?.ExcludeProducts?.data?.length > 0) {
          const isProductExcluded =
            rule?.attributes?.ExcludeProducts?.data?.find(
              (prod) => prod?.id === product?.product?.id
            );
          if (isProductExcluded) {
            hasFreeShipping = false;
            break;
          }
        }

        if (rule?.attributes?.IncludeProducts?.data?.length > 0) {
          const isProductIncluded =
            rule?.attributes?.IncludeProducts?.data?.find(
              (prod) => prod?.id === product?.product?.id
            );
          if (!isProductIncluded) {
            hasFreeShipping = false;
            break;
          }
        }

        const matchingObject = {
          category: false,
          colors: false,
          country: false,
          sizes: false,
          tags: false,
        };

        const categories = product?.product?.attributes?.Categories?.data?.map(
          (cat) => cat?.id
        );
        const tags = product?.product?.attributes?.Tags?.data?.map(
          (tag) => tag?.id
        );

        if (ruleFilters.category) {
          for (const category of rule?.attributes?.categories?.data) {
            if (categories?.find((cat) => cat === category?.id)) {
              matchingObject.category = true;
              break;
            }
          }
        }

        if (ruleFilters.colors) {
          const selectedVariant = product?.attributes?.Variants?.find(
            (variant) => variant.id === product?.selectedVariantId
          );
          if (selectedVariant) {
            for (const color of rule?.attributes?.colors?.data) {
              if (color?.id === selectedVariant?.Color?.data?.id) {
                matchingObject.colors = true;
                break;
              }
            }
          }
        }

        if (ruleFilters.country && selectedCountryID) {
          const countryDiscountId = rule?.attributes?.country?.data?.id ?? null;
          if (countryDiscountId && countryDiscountId == selectedCountryID) {
            matchingObject.country = true;
          }
        }

        if (ruleFilters.sizes) {
          const selectedVariant = product?.attributes?.Variants?.find(
            (variant) => variant.id === product?.selectedVariantId
          );
          if (selectedVariant) {
            const selectedSize = selectedVariant?.Sizes?.find(
              (size) => size?.Barcode === product?.barcode
            );
            if (selectedSize) {
              for (const size of rule?.attributes?.sizes?.data) {
                if (size?.id === selectedSize?.Size?.data?.id) {
                  matchingObject.sizes = true;
                  break;
                }
              }
            }
          }
        }

        if (ruleFilters.tags) {
          for (const tag of rule?.attributes?.tags?.data) {
            if (tags.find((t) => t === tag?.id)) {
              matchingObject.tags = true;
              break;
            }
          }
        }

        // check if any filters aren't correct
        if (
          ruleFilters.category !== matchingObject.category ||
          ruleFilters.colors !== matchingObject.colors ||
          ruleFilters.country !== matchingObject.country ||
          ruleFilters.sizes !== matchingObject.sizes ||
          ruleFilters.tags !== matchingObject.tags
        ) {
          hasFreeShipping = false;
          break;
        }
      }
      if (rule?.attributes?.MinimumSubtotal) {
        if (
          parseFloat(rule?.attributes?.MinimumSubtotal) > parseFloat(subtotal)
        )
          continue;
      }

      if (rule?.attributes?.ShippingProviders?.data?.length > 0) {
        let isShippingProviderIncluded = false;
        for (const ruleShippingProvider of rule?.attributes?.ShippingProviders
          ?.data) {
          if (shippingProvider == ruleShippingProvider?.attributes?.Name) {
            isShippingProviderIncluded = true;
            break;
          }
        }

        if (!isShippingProviderIncluded) continue;
      }

      if (hasFreeShipping) {
        return true;
      }
    }

    return false;
  }

  return false;
};

export const applyOffers = (products, offers) => {
  if (products?.length <= 0 || offers?.length <= 0) {
    return { offer: null, tip: "", offerAppliedProducts: [] };
  }

  let appliedOffer = null;
  let CheapestProducts = [];
  let offerTip = "";

  for (const offer of offers) {
    let isApplicable = true;

    const today = normalizeDate(new Date());
    const fromDate = normalizeDate(offer?.attributes?.StartDate);
    const toDate = normalizeDate(offer?.attributes?.ExpiryDate);

    if (toDate < today || fromDate > today) {
      continue;
    }

    if (
      offer?.attributes?.Categories?.data?.length === 0 ||
      !offer?.attributes?.Quantity ||
      offer?.attributes?.OfferCategories?.data?.length === 0 ||
      !offer?.attributes?.OfferQuantity ||
      !offer?.attributes?.DiscountType ||
      !offer?.attributes?.DiscountValue
    ) {
      continue;
    }

    const offerFilters = {
      categories: offer?.attributes?.Categories?.data?.map((cat) => cat?.id),
      categoriesQuantity: offer?.attributes?.Quantity,
      offerCategories: offer?.attributes?.OfferCategories?.data?.map(
        (cat) => cat?.id
      ),
      offerCategoriesQuantity: offer?.attributes?.OfferQuantity,
      excludedCategories: offer?.attributes?.ExcludeOfferCategories?.data?.map(
        (cat) => cat?.id
      ),
      excludedProducts: offer?.attributes?.ExcludeOfferProducts?.data?.map(
        (prod) => prod?.id
      ),
    };

    const matchingObject = {
      categoriesQuantity: offerFilters?.categories?.map((cat) => {
        return { id: cat, qty: 0 };
      }),
      offerCategoriesQuantity: offerFilters?.offerCategories?.map((cat) => {
        return { id: cat, qty: 0 };
      }),
    };

    const categoryProducts = [];
    const offerProducts = [];

    for (const product of products) {
      const productID = product?.product?.id;
      const productCategories =
        product?.product?.attributes?.Categories?.data?.map((cat) => cat?.id);
      const productQuantity = product?.qty;
      const excluded = false;

      for (const excludedProduct of offerFilters?.excludedProducts) {
        if (excludedProduct === productID) {
          excluded = true;
        }
      }

      for (const productCategory of productCategories) {
        const isCategoryExcluded = offerFilters?.excludedCategories?.find(
          (cat) => cat === productCategory
        );
        if (isCategoryExcluded) {
          excluded = true;
        }

        const isCategoryIncluded = offerFilters?.categories?.find(
          (cat) => cat === productCategory
        );
        if (isCategoryIncluded) {
          const matchingCategory = matchingObject.categoriesQuantity.find(
            (cat) => cat.id === productCategory
          );
          if (matchingCategory) matchingCategory.qty += productQuantity;
          categoryProducts.push(product);
          break;
        }

        const isOfferCategory = offerFilters?.offerCategories?.find(
          (cat) => cat === productCategory
        );
        if (isOfferCategory && !excluded) {
          const matchingCategory = matchingObject.offerCategoriesQuantity.find(
            (cat) => cat.id === productCategory
          );
          if (matchingCategory) matchingCategory.qty += productQuantity;
          offerProducts.push(product);
          break;
        }
      }
    }

    let categoriesMissing = false;
    let offerCategoriesMissing = false;

    for (const category of matchingObject.categoriesQuantity) {
      if (category.qty < offerFilters.categoriesQuantity) {
        isApplicable = false;
        categoriesMissing = true;
        break;
      }
    }

    for (const category of matchingObject.offerCategoriesQuantity) {
      if (category.qty < offerFilters.offerCategoriesQuantity) {
        isApplicable = false;
        offerCategoriesMissing = true;
        break;
      }
    }

    if (isApplicable) {
      appliedOffer = offer;
      CheapestProducts = getNCheapestProducts(
        offer?.attributes?.OfferQuantity,
        offerProducts
      );
      break;
    }

    if (!isApplicable) {
      // add one category product
      if (categoriesMissing && !offerCategoriesMissing) {
        let isOneProductMissing = false;
        let missingCategory = 0;
        for (const category of matchingObject.categoriesQuantity) {
          if (category.qty + 1 === offerFilters.categoriesQuantity) {
            if (!isOneProductMissing) {
              isOneProductMissing = true;
              missingCategory = category;
            } else {
              isOneProductMissing = false;
              break;
            }
          }
        }
        if (isOneProductMissing) {
          const missingCategoryName = offer?.attributes?.Categories?.data?.find(
            (cat) => cat?.id === missingCategory?.id
          );
          if (missingCategoryName) {
            const cheapestProduct = getCheapestProduct(offerProducts);
            offerTip = `You can add one more product that has the category ${missingCategoryName?.attributes?.Title} to get `;
            if (offer?.attributes?.DiscountType === "Percentage") {
              offerTip += `a ${offer?.attributes?.DiscountValue}% offer on ${cheapestProduct?.product?.attributes?.Title}`;
            } else {
              offerTip += `${offer?.attributes?.DiscountValue} AED deduction on ${cheapestProduct?.product?.attributes?.Title}`;
            }
          }
        }
      }

      // add one offer product
      if (offerCategoriesMissing && !categoriesMissing) {
        let isOneProductMissing = false;
        let missingCategory = 0;
        for (const category of matchingObject.offerCategoriesQuantity) {
          if (category.qty + 1 === offerFilters.offerCategoriesQuantity) {
            if (!isOneProductMissing) {
              isOneProductMissing = true;
              missingCategory = category;
            } else {
              isOneProductMissing = false;
              break;
            }
          }
        }
        if (isOneProductMissing) {
          const missingCategoryName =
            offer?.attributes?.OfferCategories?.data?.find(
              (cat) => cat?.id === missingCategory?.id
            );
          if (missingCategoryName) {
            offerTip = `You can get `;
            if (offer?.attributes?.DiscountType === "Percentage") {
              offerTip += `a ${offer?.attributes?.DiscountValue}% offer `;
            } else {
              offerTip += `${offer?.attributes?.DiscountValue} AED deduction `;
            }
            offerTip += `if you add one more product that has the category ${missingCategoryName?.attributes?.Title}`;
          }
        }
      }
    }
  }

  return {
    offer: appliedOffer,
    tip: offerTip,
    offerAppliedProducts: CheapestProducts,
  };
};

export const getColorAndSize = (product, barcode, selectedVariantId) => {
  const selectedVariant = product?.attributes?.Variants?.find(
    (variant) => variant?.id === selectedVariantId
  );

  if (selectedVariant) {
    const color = selectedVariant?.Color?.data?.attributes?.Name;
    const selectedSize = selectedVariant?.Sizes?.find(
      (size) => size?.Barcode === barcode
    );
    if (selectedSize) {
      const size = selectedSize?.Size?.data?.attributes?.Name;
      return [color, size];
    }
  }

  return ["", ""];
};

export const generateUser = async (email, firstName, lastName, phone) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }

  try {
    const res = await makeRequest.post("/auth/local/register", {
      email: email,
      password: password,
      username: `${firstName + lastName}_${Date.now()}`,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phone,
    });

    return {
      error: false,
      data: res.data,
    };
  } catch (err) {
    return {
      error: true,
      data: err.response.data,
    };
  }
};

export const isPhoneValid = (phone) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const updateCountryFromPhoneNumber = (phoneNumber, setCountry) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    const countryCode = parsedNumber.getCountryCode();
    const regionCode = phoneUtil.getRegionCodeForCountryCode(countryCode);
    if (regionCode) {
      setCountry(regionCode.toLowerCase());
    }
  } catch (error) {
    console.warn("Error parsing phone number:", error);
  }
};

export const calculateWeight = (products, multiplyWithQty = true) => {
  let weight = 0;

  for (const prod of products) {
    const selectedVariantId = prod?.selectedVariantId;
    const barcode = prod?.barcode;

    const variant = prod?.product?.attributes?.Variants?.find(
      (variant) => variant?.id === selectedVariantId
    );
    if (variant) {
      const size = variant?.Sizes?.find((size) => size?.Barcode === barcode);
      if (size) {
        if (multiplyWithQty) weight += size?.Weight * prod?.qty;
        else weight += size?.Weight;
      }
    }
  }
  return parseFloat(weight?.toFixed(2));
};

export const getShippingRate = (
  shippingMethod,
  shippingRatesArr,
  country,
  city,
  area,
  products
) => {
  if (
    !shippingMethod ||
    products.length === 0 ||
    !country ||
    (!area && area != 0) ||
    !city ||
    shippingRatesArr.length === 0
  ) {
    return 0;
  }

  const weight = calculateWeight(products);
  for (const rate of shippingRatesArr) {
    const shippingProvider = rate?.attributes?.ShippingProvider;

    if (shippingProvider?.data?.attributes?.Name !== shippingMethod) continue;

    for (const rateArea of rate?.attributes?.Areas) {
      if (
        (rateArea?.Country?.data && rateArea?.Country?.data?.id != country) ||
        (rateArea?.City?.data && rateArea?.City?.data?.id != city)
      ) {
        continue;
      }

      if (rateArea?.Areas?.data?.length > 0) {
        const isValidArea = rateArea?.Areas?.data?.find(
          (Area) => Area?.id == area
        );

        if (!isValidArea) continue;
      }

      if (rateArea?.FromWeight > weight || rateArea?.ToWeight < weight)
        continue;

      if (rateArea?.FirstHalfKGPrice && rateArea?.RestHalfKGPrice) {
        const units = Math.ceil(weight / 0.5);
        const firstKG = parseFloat(rateArea?.FirstHalfKGPrice);
        const restKG = parseFloat(rateArea?.RestHalfKGPrice);
        const cost = firstKG + restKG * (units - 1);
        return cost;
      }

      return rateArea?.Fee;
    }
  }

  return 0;
};

export const updateIsDeducted = async (orderID) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}orders/${orderID}`,
      {
        data: { QuantityDeducted: true },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    );
  } catch (error) {
    console.error("Quantity update failed:", error.response || error.message);
  }
};

export const updateSizeQuantity = async (productsIds, orderID) => {
  let products = [];
  const productsParams = new URLSearchParams({
    populate: "deep",
  });
  productsIds.forEach((product, index) => {
    productsParams.append(`filters[id][$in][${index}]`, product?.id);
  });

  try {
    const res = await makeRequest.get(`/products?${productsParams.toString()}`);
    products = res?.data?.data;
  } catch (err) {
    return {
      error: true,
      data: err?.response?.data || "An unknown error occurred",
    };
  }

  const organizedProductsByID = [];

  for (const productID of productsIds) {
    const product = products?.find((prod) => prod?.id == productID?.id);

    if (product) {
      const isProductAlreadyAdded = organizedProductsByID.find(
        (orgProd) => orgProd?.id == product?.id
      );
      if (isProductAlreadyAdded) {
        const isVariantAlreadyAdded = isProductAlreadyAdded?.variants?.find(
          (variant) => variant?.id == productID?.selectedVariantId
        );
        if (isVariantAlreadyAdded) {
          const isSizeAlreadyAdded = isVariantAlreadyAdded?.Sizes?.find(
            (size) => size?.Barcode == productID?.barcode
          );
          if (isSizeAlreadyAdded) {
            isSizeAlreadyAdded.Quantity -= parseInt(productID?.quantity);
          }
        }
      } else {
        const newVariants = [];
        for (const newVariant of product?.attributes?.Variants) {
          if (newVariant?.id != productID?.selectedVariantId) {
            newVariants.push({
              id: newVariant?.id,
              Sizes: newVariant?.Sizes?.map((size) => {
                return {
                  id: size?.id,
                  Quantity: size?.Quantity,
                  Barcode: size?.Barcode,
                };
              }),
            });
          } else {
            const newSizes = [];
            for (const newVariantSize of newVariant?.Sizes) {
              if (newVariantSize?.Barcode != productID?.barcode) {
                newSizes.push({
                  id: newVariantSize?.id,
                  Quantity: newVariantSize?.Quantity,
                  Barcode: newVariantSize?.Barcode,
                });
              } else {
                newSizes.push({
                  id: newVariantSize?.id,
                  Quantity:
                    parseInt(newVariantSize?.Quantity) -
                    parseInt(productID?.quantity),
                  Barcode: newVariantSize?.Barcode,
                });
              }
            }
            newVariants.push({
              id: newVariant?.id,
              Sizes: newSizes,
            });
          }
        }
        organizedProductsByID.push({
          id: product?.id,
          variants: newVariants,
        });
      }
    }
  }

  for (const organizedProduct of organizedProductsByID) {
    const payload = {
      data: {
        Variants: organizedProduct?.variants,
      },
    };

    try {
      const res = await makeRequest.put(
        `/products/${organizedProduct?.id}`,
        payload
      );
    } catch (err) {
      return {
        error: true,
        data: err.response?.data || "An unknown error occurred",
      };
    }
  }

  await updateIsDeducted(orderID);

  return {
    error: false,
    data: "An unknown error occurred",
  };
};

export const updateVoucher = async (voucher, amountUsed) => {
  const newAmount =
    parseFloat(voucher?.attributes?.Amount) - parseFloat(amountUsed);
  const newStatus = newAmount <= 0 ? "Disabled" : "Used";
  const payload = {
    data: {
      Status: newStatus,
      Amount: parseFloat(newAmount?.toFixed(2)),
    },
  };

  try {
    const res = await makeRequest.put(`/vouchers/${voucher?.id}`, payload);
  } catch (err) {
    return false;
  }

  return true;
};

export const subscribeToNewsletter = async (emailValue) => {
  const payload = {
    data: {
      Email: emailValue,
    },
  };

  try {
    const res = await makeRequest.post(`/newsletters`, payload);
  } catch (err) {
    return false;
  }

  return true;
};

export const generateInvoiceNumber = (number) => {
  const prefix = "NW";

  // return `${prefix}${number.toString().padStart(5, "0")}`;
  return `${prefix}${number.toString()}`;
};

export const generatePackageID = (offset) => {
  const prefix = "PKG";
  const timestamp = Date.now();

  return `${prefix}-${timestamp}${offset}`;
};

export const uploadBase64PdfToStrapi = async (base64Pdf, fileName) => {
  const base64WithPrefix = `data:application/pdf;base64,${base64Pdf}`;

  // Convert Base64 string to Blob
  const byteCharacters = atob(base64WithPrefix.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(null)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Create FormData
  const formData = new FormData();
  formData.append("files", blob, fileName);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return [response?.data[0]?.id, false];
  } catch (error) {
    console.error("Upload error:", error.response || error.message);
    return [0, true];
  }
};

export const isOutOfStock = (variants) => {
  if (variants?.length === 0) return;

  let isAllOutOfStock = true;
  for (const variant of variants) {
    for (const size of variant?.Sizes) {
      if (size?.Quantity > 0) {
        if (variant?.Images?.data) {
          isAllOutOfStock = false;
          break;
        }
      }
    }
    if (!isAllOutOfStock) break;
  }

  if (isAllOutOfStock) {
    return true;
  }

  return false;
};

export const isSelectedVariantOutOfStock = (
  product,
  barcode,
  selectedVariantId
) => {
  const selectedVariant = product?.attributes?.Variants?.find(
    (variant) => variant?.id === selectedVariantId
  );

  if (selectedVariant) {
    const selectedSize = selectedVariant?.Sizes?.find(
      (size) => size?.Barcode === barcode
    );
    if (selectedSize) {
      return selectedSize?.Quantity <= 0;
    }
  }

  return true;
};

export const updateOrderWithInvoice = async (orderID) => {
  let invoice = generateInvoiceNumber(orderID);

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}orders/${orderID}`,
      {
        data: { InvoiceNumber: invoice },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    );
  } catch (error) {
    console.error("Upload error:", error.response || error.message);
  }
};

export const getAddressFromCoords = async (lat, lon) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
    );
    const data = await response.json();

    if (data && data.address) {
      const address = data?.display_name?.split(", ")?.toReversed();
      const newAddress = {
        Country: data.address.country || "",
        City:
          data.address.city ||
          data.address.state ||
          data.address.town ||
          data.address.village ||
          "",
        City2: data.address.state,
        Area: data.address.neighbourhood || "",
        Area2: data.address.suburb || "",
        Address1: data.address.neighbourhood || "",
        Address2: data.address.road || "",
        PostCode: data.address.postcode || "",
        DisplayName: data?.display_name,
        MapLink: `http://www.openstreetmap.org/?mlat=${data?.lat}&mlon=${data?.lon}&zoom=13`,
      };
      return newAddress;
    }
  } catch (error) {
    console.error("Error fetching address:", error);
  }
  return null;
};

export const getDHLShipmentEvents = async (trackingNumber) => {
  const payload = {
    tracking_id: `${trackingNumber}`,
  };

  try {
    const res = await makeRequest.post(`/dhl-track-shipment`, payload);
    return res?.data?.shipments[0]?.events;
  } catch (err) {
    return [];
  }

  return [];
};

export const uploadBlobsToStrapi = async (blobUrls) => {
  try {
    const formData = new FormData();
    const timestamp = Date.now();

    await Promise.all(
      blobUrls.map(async (blobUrl, index) => {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        formData.append(
          "files",
          blob,
          `exchange_request_file_${index + 1}_${timestamp}`
        );
      })
    );

    const uploadResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return [uploadResponse?.data.map((file) => file.id), false];
  } catch (error) {
    console.error("Upload error:", error.response || error.message);
    return [[], true];
  }
};

export const updatePoints = async (points, userID) => {
  let userPoints = 0;

  if (!userID) return;
  if (!points) return;

  try {
    const res = await makeRequest.get(
      `/users?filters[id][$eq]=${userID}&populate=*`
    );
    if (res?.data) userPoints = res?.data[0]?.Points;
    else return;
  } catch (err) {
    return;
  }

  if (!userPoints) userPoints = 0;

  userPoints = parseFloat(userPoints) + parseFloat(points);

  const payload = {
    Points: userPoints?.toFixed(2),
  };

  try {
    const res = await makeRequest.put(`/users/${userID}`, payload);
  } catch (err) {
    return;
  }
};

export const getUserPoints = async (userID) => {
  try {
    const res = await makeRequest.get(
      `/users?filters[id][$eq]=${userID}&populate=*`
    );
    if (res?.data) return parseFloat(res?.data[0]?.Points);
    else return 0;
  } catch (err) {
    return 0;
  }
};

export const getProductsQuantities = async (productsIDs) => {
  const params = new URLSearchParams({
    "fields[0]": "id",
    "populate[Variants][fields][0]": "id",
    "populate[Variants][populate][Sizes][fields][0]": "id",
    "populate[Variants][populate][Sizes][fields][1]": "Quantity",
    "populate[Variants][populate][Sizes][fields][2]": "barcode"
  });

  let index = 0;
  for(const id of productsIDs) {
    params.append(`filters[id][$in][${index}]`, id)
    index++;
  }

  try {
    const res = await makeRequest.get(
      `/products?${params.toString()}`
    );
    return res.data.data;
  } catch (err) {
    return [];
  }
};
