import React, { useState, useEffect } from "react";

import usePut from "../../hooks/usePut";

import {
  isPhoneValid,
  updateCountryFromPhoneNumber,
  getUserPoints,
} from "../../helpers/common";

import { useDispatch, useSelector } from "react-redux";
import { setUserWithoutJWT } from "../../redux/userReducer";
import { useNavigate } from "react-router-dom";

import "./Account.scss";

import InputWithFloatingLabel from "../../components/InputWithFloatingLabel/InputWithFloatingLabel";

import { useTranslation } from "react-i18next";

const Account = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState(user?.FirstName);
  const [lastName, setLastName] = useState(user?.LastName);
  const [phone, setPhone] = useState(user?.PhoneNumber);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userPoints, setUserPoints] = useState(0);

  const [requestData, setRequestData] = useState({});
  const [editAccountRetries, setEditAccountRetries] = useState(0);
  const [editAccountLoading, setEditAccountLoading] = useState(false);
  const [editAccountError, setEditAccountError] = useState(false);

  const editAccountUrl = `/users/${user?.id}`;
  const {
    data: EditAccountData,
    loading: EditAccountLoading,
    error: EditAccountError,
    status: EditAccountStatus,
    errorMessage: EditAccountErrorMessage,
  } = usePut(editAccountUrl, requestData);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (editAccountLoading) return;

    if (firstName.trim() === "" || firstName.length < 3) {
      setErrorMessage(t("errors.firstname-not-valid"));
      return;
    }
    if (lastName.trim() === "" || lastName.length < 3) {
      setErrorMessage(t("errors.lastname-not-valid"));
      return;
    }
    if (email.trim() === "") {
      setErrorMessage(t("errors.email-not-valid"));
      return;
    }
    if (password.trim() !== "" && password.length < 8) {
      setErrorMessage(t("errors.password-not-valid"));
      return;
    }
    if (password.trim() !== confirmPassword.trim()) {
      setErrorMessage(t("errors.confirm-password-not-valid"));
      return;
    }
    if (phone.trim() === "" || !isPhoneValid(phone)) {
      setErrorMessage(t("errors.phone-not-valid"));
      return;
    }
    setErrorMessage("");

    setRequestData(() => {
      const req = {
        email: email,
        username: firstName + lastName,
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phone,
        try: editAccountRetries,
      };

      if (password) {
        req.password = password;
      }

      return req;
    });
    setEditAccountRetries((prev) => prev + 1);
  };

  useEffect(() => {
    if (EditAccountLoading) {
      setEditAccountLoading(true);
      return;
    }
    setEditAccountLoading(false);

    if (EditAccountStatus !== 200 && editAccountRetries > 0) {
      setEditAccountError(true);

      if (EditAccountStatus === 400) {
        setErrorMessage(EditAccountErrorMessage);
      } else {
        setErrorMessage(t("errors.unknown"));
      }
      return;
    }

    if (EditAccountData) {
      dispatch(
        setUserWithoutJWT({
          user: EditAccountData,
        })
      );
      setEditAccountError(false);
      setEditAccountLoading(false);
      setEmail(EditAccountData?.email);
      setPassword("");
      setConfirmPassword("");
      setSuccessMessage("Account edited successfully!");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      setFirstName(EditAccountData?.FirstName);
      setLastName(EditAccountData?.LastName);
      setPhone(EditAccountData?.PhoneNumber);
    }
  }, [EditAccountLoading]);

  useEffect(() => {
    const getPoints = async () => {
      const points = await getUserPoints(user?.id);
      setUserPoints(points);
    };

    if (Object.keys(user)?.length > 0) {
      getPoints();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t(
      "account.profile-details"
    )}`;
  }, []);

  return (
    <section className="account-page">
      <div className="account-container">
        <div className="account-title-and-points">
          <h1 className="account-title">{t("account.profile-details")}</h1>
          {userPoints > 0 && (
            <h3 className="account-points">
              {t("account.points")}: {userPoints}
            </h3>
          )}
        </div>
        <form className="account-form" onSubmit={handleFormSubmit}>
          <InputWithFloatingLabel
            stateValue={email}
            setStateValue={setEmail}
            type={"email"}
            labelText={t("input.email")}
            idText={"email"}
          />
          <InputWithFloatingLabel
            stateValue={password}
            setStateValue={setPassword}
            type={"password"}
            labelText={t("input.password")}
            idText={"password"}
          />
          <InputWithFloatingLabel
            stateValue={confirmPassword}
            setStateValue={setConfirmPassword}
            type={"password"}
            labelText={t("input.confirm-password")}
            idText={"confirm-password"}
          />
          <div className="account-contact-information">
            <h1 className="account-contact-information-title">
              {t("account.contact-information")}
            </h1>
            <div className="account-contact-information-name-container">
              <InputWithFloatingLabel
                stateValue={firstName}
                setStateValue={setFirstName}
                labelText={t("input.first-name")}
                idText={"first-name"}
              />
              <InputWithFloatingLabel
                stateValue={lastName}
                setStateValue={setLastName}
                labelText={t("input.last-name")}
                idText={"last-name"}
              />
            </div>
            <InputWithFloatingLabel
              stateValue={phone}
              setStateValue={setPhone}
              type={"tel"}
              labelText={t("input.phone")}
              idText={"phone"}
              phoneInput={true}
              updateCountryFromPhoneNumber={updateCountryFromPhoneNumber}
            />
          </div>
          {errorMessage.trim() !== "" && (
            <h2 className="account-error">{errorMessage}</h2>
          )}
          {successMessage.trim() !== "" && (
            <h2 className="account-success">{successMessage}</h2>
          )}
          <button
            className="account-button"
            type="submit"
            disabled={editAccountLoading}
          >
            {editAccountLoading ? t("account.loading...") : t("account.save")}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Account;
