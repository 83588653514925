import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPromoApplied: false,
  isVoucher: false,
  voucherAmount: 0,
  promo: null,
  orderID: "",
  shippingData: null,
  points: ""
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: initialState,
  reducers: {
    enableIsPromoApplied: (state) => {
      state.isPromoApplied = true;
    },
    disableIsPromoApplied: (state) => {
      state.isPromoApplied = false;
    },
    enableIsVoucher: (state) => {
      state.isVoucher = true;
    },
    disableIsVoucher: (state) => {
      state.isVoucher = false;
    },
    setVoucherAmount: (state, action) => {
      state.voucherAmount = action.payload;
    },
    addPromo: (state, action) => {
      state.promo = action.payload;
    },
    removePromo: (state) => {
      state.promo = null;
    },
    addPoints: (state, action) => {
      state.points = action.payload;
    },
    removePoints: (state) => {
      state.points = "";
    },
    addShippingData: (state, action) => {
      state.shippingData = action.payload;
    },
    removeShippingData: (state) => {
      state.shippingData = null;
    },
    setOrderID: (state, action) => {
      state.orderID = action.payload;
    },
    removeOrderID: (state) => {
      state.orderID = "";
    },
  },
});

export const {
  enableIsPromoApplied,
  disableIsPromoApplied,
  enableIsVoucher,
  disableIsVoucher,
  setVoucherAmount,
  addPromo,
  removePromo,
  addPoints,
  removePoints,
  addShippingData,
  removeShippingData,
  setOrderID,
  removeOrderID
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
