import { createSlice } from "@reduxjs/toolkit";

const cartVisibilitySlice = createSlice({
  name: "cartVisibility",
  initialState: { isOpen: false },
  reducers: {
    openCart: (state) => {
      state.isOpen = true;
    },
    closeCart: (state) => {
      state.isOpen = false;
    },
    toggleCart: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openCart, closeCart, toggleCart } = cartVisibilitySlice.actions;
export default cartVisibilitySlice.reducer;