import React, { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";
import { useZones } from "../../hooks/useZones";
import { useGetCity } from "../../hooks/useGetCity";
import { useGetArea } from "../../hooks/useGetArea";
import { useAddress } from "../../hooks/useAddress";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "./AddressBook.scss";

import InputWithInnerLabel from "../../components/InputWithInnerLabel/InputWithInnerLabel";
import SelectWithInnerLabel from "../../components/SelectWithInnerLabel/SelectWithInnerLabel";
import AddressCard from "../../components/AddressCard/AddressCard";
import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import Map from "../../components/Map/Map";

import { ReactComponent as MapPin } from "../../SVGs/MapPin.svg";

import { useTranslation } from "react-i18next";

const AddressBook = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countriesArr, setCountriesArr] = useState([]);
  const [citiesArr, setCitiesArr] = useState([]);
  const [areasArr, setAreasArr] = useState([]);

  const [addressesArr, setAddressesArr] = useState([]);
  const [areAddressesLoading, setAreAddressesLoading] = useState(false);
  const [areAddressesError, setAreAddressesError] = useState(false);
  const [addressesRetries, setAddressesRetries] = useState(0);

  const retryAddresses = () => setAddressesRetries((prev) => prev + 1);

  const [turnMapOff, setTurnMapOff] = useState(false);
  const [AddressViaMap, setAddressViaMap] = useState({
    Country: 0,
    CountryName: "",
    City: 0,
    CityName: "",
    CityName2: "",
    Area: 0,
    AreaName: "",
    AreaName2: "",
    AddressLine1: "",
    AddressLine2: "",
    PostalCode: "",
    FirstName: "",
    LastName: "",
    MapLink: "",
    DisplayName: "",
    SelectedPosition: [],
  });
  const [cityViaMap, setCityViaMap] = useState("");
  const [city2ViaMap, setCity2ViaMap] = useState("");
  const [isCitySorted, setIsCitySorted] = useState(false);
  const [areaViaMap, setAreaViaMap] = useState("");
  const [area2ViaMap, setArea2ViaMap] = useState("");
  const [isAreaSorted, setIsAreaSorted] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState([
    25.276987, 55.296249,
  ]);
  const [mapLink, setMapLink] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [isEditForm, setIsEditForm] = useState(false);
  const [editAddressID, setEditAddressID] = useState(0);
  const [editCity, setEditCity] = useState("");
  const [editArea, setEditArea] = useState("");

  const [showCreateAddress, setShowCreateAddress] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [country, setCountry] = useState(0);
  const [city, setCity] = useState(0);
  const [area, setArea] = useState(0);

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const addressesBaseUrl = "/addresses";
  const addressesParams = new URLSearchParams({
    populate: "deep",
    "filters[User][id][$eq]": user?.id,
    sort: "updatedAt:desc",
    try: addressesRetries,
  });

  const addressesUrl = `${addressesBaseUrl}?${addressesParams.toString()}`;
  const {
    data: addressesData,
    loading: addressesLoading,
    error: addressesError,
  } = useFetch(addressesUrl);

  const {
    addAddress,
    updateAddress,
    removeAddress,
    loading: AddressHookLoading,
    error: AddressHookError,
    setError: setAddressHookError,
    retry: AddressHookRetryFunc,
  } = useAddress(retryAddresses);

  const {
    data: zonesData,
    loading: zonesLoading,
    error: zonesError,
    setError: setZonesError,
    retry: zonesRetryFunc,
  } = useZones();

  const {
    data: CityData,
    loading: CityLoading,
    error: CityError,
  } = useGetCity(country);

  const {
    data: AreaData,
    loading: AreaLoading,
    error: AreaError,
  } = useGetArea(city);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (AddressHookLoading) return;

    const newAddress = {
      User: user?.id,
      FirstName: firstName,
      LastName: lastName,
      Address1: addressLine1,
      Address2: addressLine2,
      Postcode: postalCode,
      IsDefault: true,
      Country: country,
      City: city,
      Area: null,
    };

    if (area) newAddress.Area = area;
    if (mapLink?.trim() !== "") newAddress.MapLink = mapLink;
    if (displayName?.trim() !== "") newAddress.DisplayName = displayName;

    if (!isEditForm) await addAddress(newAddress, user?.id);
    else await updateAddress(newAddress, editAddressID, user?.id);

    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get("to");
    switch (refParam) {
      case "checkout":
        navigate("/checkout");
        break;
      default:
      // navigate("/");
    }

    setShowCreateAddress(false);
    setIsEditForm(false);
    setEditAddressID(0);
    setFirstName(user?.FirstName);
    setLastName(user?.LastName);
    setAddressLine1("");
    setAddressLine2("");
    setCountry(231);
    setCity(citiesArr[0]?.id);
    setArea(areasArr[0]?.id);
    setPostalCode("");
    setEditCity("");
    setEditArea("");
    setMapLink("");
    setDisplayName("");
  };

  const isFormValid = () => {
    const areaName = areasArr?.find((areaObj) => areaObj?.id == area)?.Name;
    const fields = [
      firstName,
      lastName,
      addressLine1,
      postalCode,
      country,
      city,
      areaName?.trim() !== "",
    ];
    return fields.every(Boolean);
  };

  const editAddress = (addressID) => {
    const editedAddress = addressesArr?.find(
      (address) => address?.id === addressID
    );
    if (editedAddress) {
      setFirstName(editedAddress?.attributes?.FirstName);
      setLastName(editedAddress?.attributes?.LastName);
      setAddressLine1(editedAddress?.attributes?.Address1);
      setAddressLine2(editedAddress?.attributes?.Address2);
      setCountry(editedAddress?.attributes?.Country?.data?.id);
      setCity(editedAddress?.attributes?.City?.data?.id);
      setArea(editedAddress?.attributes?.Area?.data?.id);
      setEditCity(editedAddress?.attributes?.City?.data?.attributes?.Name);
      setEditArea(editedAddress?.attributes?.Area?.data?.attributes?.Name);
      setPostalCode(editedAddress?.attributes?.Postcode);
      setShowCreateAddress(true);
      setIsEditForm(true);
      setEditAddressID(addressID);
      if (editedAddress?.attributes?.MapLink) {
        const params = new URL(editedAddress?.attributes?.MapLink).searchParams;

        const lat = params.get("mlat");
        const lon = params.get("mlon");
        setSelectedPosition([lat, lon]);
        setDisplayName(editedAddress?.attributes?.DisplayName);
      }
    }
  };

  const setAsDefault = async (addressID) => {
    const newAddress = {
      IsDefault: true,
    };
    await updateAddress(newAddress, addressID, user?.id);
  };

  const cancelEdit = () => {
    setShowCreateAddress(false);
    setIsEditForm(false);
    setEditAddressID(0);
    setFirstName(user?.FirstName);
    setLastName(user?.LastName);
    setAddressLine1("");
    setAddressLine2("");
    setCountry(231);
    setCity(citiesArr[0]?.id);
    setArea(areasArr[0]?.id);
    setPostalCode("");
    setEditCity("");
    setEditArea("");
    setMapLink("");
    setDisplayName("");
  };

  const updateAddressObject = ({
    DisplayName,
    Address1,
    Address2,
    PostCode,
    Country,
    City,
    City2,
    Area,
    Area2,
    MapLink,
  }) => {
    const NewAddressViaMap = {
      Country: 0,
      CountryName: "",
      City: 0,
      CityName: "",
      CityName2: "",
      Area: 0,
      AreaName: "",
      AreaName2: "",
      AddressLine1: "",
      AddressLine2: "",
      PostalCode: "",
      FirstName: "",
      LastName: "",
      MapLink: "",
      DisplayName: "",
      SelectedPosition: [],
    };
    if (Address1?.trim() === "" && Address2?.trim() !== "") {
      NewAddressViaMap.AddressLine1 = Address2;
    } else if (Address1?.trim() !== "") {
      NewAddressViaMap.AddressLine1 = Address1;
      NewAddressViaMap.AddressLine2 = Address2;
    } else {
      NewAddressViaMap.AddressLine1 = Area;
      NewAddressViaMap.AddressLine2 = Address2;
    }
    NewAddressViaMap.PostalCode = PostCode?.trim() === "" ? "00000" : PostCode;
    const newCountry = countriesArr?.find(
      (country) => country?.Name === Country
    );
    if (newCountry) {
      NewAddressViaMap.Country = newCountry?.id;
      NewAddressViaMap.CountryName = newCountry?.Name;
    }
    NewAddressViaMap.CityName = City;
    NewAddressViaMap.CityName2 = City2;
    NewAddressViaMap.AreaName = Area;
    NewAddressViaMap.AreaName2 = Area2;
    NewAddressViaMap.MapLink = MapLink;
    NewAddressViaMap.DisplayName = DisplayName;
    const params = new URL(MapLink).searchParams;

    const lat = params.get("mlat");
    const lon = params.get("mlon");
    NewAddressViaMap.SelectedPosition = [lat, lon];
    setAddressViaMap(NewAddressViaMap);
  };

  const updateAddressFromMap = () => {
    try {
      // Only proceed if we have valid coordinates and data
      if (
        AddressViaMap.SelectedPosition?.length === 2 && 
        AddressViaMap.MapLink
      ) {
        setCountry(AddressViaMap.Country);
        setCityViaMap(AddressViaMap.CityName);
        setCity2ViaMap(AddressViaMap.CityName2);
        setIsCitySorted((prev) => !prev);
        setAreaViaMap(AddressViaMap.AreaName);
        setArea2ViaMap(AddressViaMap.AreaName2);
        setPostalCode(AddressViaMap.PostalCode);
        setMapLink(AddressViaMap.MapLink);
        setDisplayName(AddressViaMap.DisplayName);
        setSelectedPosition(AddressViaMap.SelectedPosition);
      }
    } catch (error) {
      console.error('Error updating address from map:', error);
      // Silently fail without showing errors to end user
      // Could also add error handling logic here if needed
    }
  };


  useEffect(() => {
    if (!addressesLoading && addressesData) {
      setAddressesArr(addressesData);
      if (addressesData?.length === 0) {
        setShowCreateAddress(true);
      }
      setAreAddressesLoading(false);
    } else if (addressesLoading && !addressesError) {
      setAreAddressesLoading(true);
    } else if (!addressesLoading && addressesError && !addressesData) {
      setAreAddressesLoading(false);
      setAreAddressesError(true);
    }
  }, [addressesLoading]);

  useEffect(() => {
    if (!zonesLoading && !zonesError && zonesData) {
      setCountriesArr(
        zonesData.countries.map((country) => {
          return {
            Name: country?.attributes?.Name,
            id: country?.id,
          };
        })
      );
      setCountry(231);
    }
  }, [zonesLoading, zonesError]);

  useEffect(() => {
    if (!CityLoading && !CityError && CityData) {
      const newCitiesArr = [{
        Name: "Please Select",
        id: ""
      }];
      if (CityData?.cities) {
        for (const newCity of CityData?.cities) {
          if (newCity?.attributes?.Country?.data?.id == country) {
            newCitiesArr.push({
              Name: newCity?.attributes?.Name,
              id: newCity?.id,
            });
          }
        }
      }

      if (cityViaMap?.trim() !== "" && newCitiesArr?.length > 0) {
        newCitiesArr?.sort((a, b) => {
          if (cityViaMap?.includes(a?.Name)) return -1;
          else if (cityViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (
        city2ViaMap?.trim() !== "" &&
        !cityViaMap?.includes(newCitiesArr[0]?.Name) &&
        newCitiesArr?.length > 0
      ) {
        newCitiesArr?.sort((a, b) => {
          if (city2ViaMap?.includes(a?.Name)) return -1;
          else if (city2ViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (editCity?.trim() !== "") {
        newCitiesArr?.sort((a, b) => {
          if (editCity?.includes(a?.Name)) return -1;
          else if (editCity?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (newCitiesArr.length === 0) {
        setCitiesArr([]);
        setCity(0);
      } else {
        setCitiesArr(newCitiesArr);
        setCity(newCitiesArr[0]?.id);
      }

      if (
        cityViaMap?.trim() !== "" &&
        city2ViaMap?.trim() !== "" &&
        newCitiesArr?.length > 0
      ) {
        setCityViaMap("");
        setCity2ViaMap("");
        setIsAreaSorted((prev) => !prev);
      }
    }
  }, [country, CityData, CityLoading, CityError, isCitySorted, editCity]);

  useEffect(() => {
    if (!AreaLoading && AreaData) {
      const newAreasArr = [];
      if (AreaData?.areas) {
        for (const newArea of AreaData?.areas) {
          if (newArea?.attributes?.City?.data?.id == city) {
            newAreasArr.push({
              Name: newArea?.attributes?.Name,
              id: newArea?.id,
            });
          }
        }
      }

      if (areaViaMap?.trim() !== "") {
        newAreasArr?.sort((a, b) => {
          if (areaViaMap?.includes(a?.Name)) return -1;
          else if (areaViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (
        area2ViaMap?.trim() !== "" &&
        !areaViaMap?.includes(newAreasArr[0]?.Name)
      ) {
        newAreasArr?.sort((a, b) => {
          if (area2ViaMap?.includes(a?.Name)) return -1;
          else if (area2ViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (editArea?.trim() !== "") {
        newAreasArr?.sort((a, b) => {
          if (editArea?.includes(a?.Name)) return -1;
          else if (editArea?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (
        !areaViaMap?.includes(newAreasArr[0]?.Name) &&
        !area2ViaMap?.includes(newAreasArr[0]?.Name) &&
        !editArea?.includes(newAreasArr[0]?.Name)
      ) {
        newAreasArr?.sort((a, b) => {
          if (a?.Name == "All Areas") return -1;
          else if (b?.Name == "All Areas") return 1;
          else return 0;
        });
      }

      if (newAreasArr.length === 0) {
        setAreasArr([]);
        setArea(0);
      } else {
        setAreasArr(newAreasArr);
        setArea(newAreasArr[0]?.id);
      }

      if (
        areaViaMap?.trim() !== "" &&
        area2ViaMap?.trim() !== "" &&
        newAreasArr?.length > 0
      ) {
        setAreaViaMap("");
        setArea2ViaMap("");
      }
    }
  }, [city, AreaData, AreaLoading, AreaError, isAreaSorted, editArea]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setFirstName(user?.FirstName);
      setLastName(user?.LastName);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const refParam = searchParams.get("to");
    switch (refParam) {
      case "checkout":
        setShowCreateAddress(true);
        break;
      default:
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t("home.address-book")}`;
  }, []);

  useEffect(() => {
      // Only proceed if we have valid coordinates and data
      if (
        AddressViaMap.SelectedPosition?.length === 2 && 
        AddressViaMap.MapLink && 
        !Object.keys(user).length > 0 // Don't auto-update for logged in users
      ) {
        updateAddressFromMap();
      }
    }, [AddressViaMap]);

  return (
    <section className="address-book">
      <h1 className="address-book-title">{t("home.address-book")}</h1>
      <div className="add-new-address-container">
        {!isEditForm && (
          <div
            className="add-new-address-dropdown"
            onClick={() => setShowCreateAddress((prev) => !prev)}
          >
            <h2 className="address-book-subtitle">
              {t("address-book.add-new-address")}
            </h2>
            <span className="add-new-address-dropdown-symbol">
              {showCreateAddress ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
        )}
        <form
          className="add-address-form"
          onSubmit={handleFormSubmit}
          style={{
            "max-height": `${showCreateAddress ? "1000px" : "0px"}`,
          }}
        >
          {countriesArr.length > 0 && (
            <>
              {!turnMapOff && (
                <h1
                  className="address-book-map-text"
                  onClick={updateAddressFromMap}
                >
                  <MapPin /> {t("address-book.detect-via-map")}
                </h1>
              )}
              <Map
                updateAddress={updateAddressObject}
                loadMap={true}
                selectedPosition={selectedPosition}
                DisplayName={displayName}
                setTurnMapOff={setTurnMapOff}
              ></Map>
            </>
          )}
          <div className="address-two-fields margin-top-1">
            <InputWithInnerLabel
              stateValue={firstName}
              setStateValue={setFirstName}
              labelText={t("input.first-name") + "*"}
              idText={"first-name"}
              redBorder={true}
            />
            <InputWithInnerLabel
              stateValue={lastName}
              setStateValue={setLastName}
              labelText={t("input.last-name") + "*"}
              idText={"last-name"}
              redBorder={true}
            />
          </div>
          <SelectWithInnerLabel
            stateValue={country}
            setStateValue={setCountry}
            labelText={t("input.country") + "*"}
            idText={"country-selector"}
            options={countriesArr}
            redBorder={true}
          />
          <div className="address-two-fields">
            <SelectWithInnerLabel
              stateValue={city}
              setStateValue={setCity}
              labelText={t("input.city") + "*"}
              idText={"city-selector"}
              options={citiesArr}
              redBorder={true}
            />
            <SelectWithInnerLabel
              stateValue={area}
              setStateValue={setArea}
              labelText={t("input.area") + "*"}
              idText={"area-selector"}
              options={areasArr}
              redBorder={true}
            />
          </div>
          <InputWithInnerLabel
            stateValue={addressLine1}
            setStateValue={setAddressLine1}
            labelText={t("order.address-line-1") + "*"}
            idText={"address-details-one"}
            redBorder={true}
            minLength={10}
          />
          <InputWithInnerLabel
            stateValue={addressLine2}
            setStateValue={setAddressLine2}
            labelText={t("order.address-line-2")}
            idText={"address-details-two"}
          />
          <InputWithInnerLabel
            stateValue={postalCode}
            setStateValue={setPostalCode}
            labelText={t("input.postal-code") + "*"}
            idText={"postal-code"}
            redBorder={true}
          />
          <div className="add-address-form-buttons">
            {isEditForm && (
              <span
                className="add-address-form-cancel-button"
                onClick={() => cancelEdit()}
              >
                Cancel
              </span>
            )}
            <button
              className="add-address-button"
              type="submit"
              disabled={AddressHookLoading || !isFormValid()}
            >
              {!isFormValid() ? (
                t("checkout.kindly-fill-all-fields")
              ) : (
                <>
                  {AddressHookLoading
                    ? t("account.loading...")
                    : t("account.save")}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
      {!isEditForm && (
        <div className="address-book-addresses">
          <h2 className="address-book-subtitle">
            {t("address-book.all-addresses")}
          </h2>
          <div className="addresses-container">
            <LoaderOverlay
              loadingState={areAddressesLoading}
              errorState={areAddressesError}
              setErrorState={setAreAddressesError}
              retryFunc={retryAddresses}
            />
            {!areAddressesLoading && !areAddressesLoading && (
              <LoaderOverlay
                loadingState={AddressHookLoading}
                errorState={AddressHookError}
                setErrorState={setAddressHookError}
                retryFunc={AddressHookRetryFunc}
              />
            )}
            {addressesArr.map((address) => (
              <AddressCard
                address={address}
                remove={removeAddress}
                edit={editAddress}
                setDefault={setAsDefault}
              />
            ))}
            {addressesArr?.length === 0 &&
              !areAddressesLoading &&
              !AddressHookLoading && (
                <h3 className="no-addresses">
                  {t("address-book.you-have-no-addresses")}
                </h3>
              )}
          </div>
        </div>
      )}
    </section>
  );
};

export default AddressBook;
