import { useState, useEffect } from "react";

/**
 * Custom hook to determine if the page is viewed on a mobile or big screen.
 * @returns {string} 'mobile' or 'desktop' based on the viewport width.
 */
const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(
    window.matchMedia("(max-width: 1024px)").matches ? "mobile" : "desktop"
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1024px)");

    const handleDeviceChange = (e) => {
      setDeviceType(e.matches ? "mobile" : "desktop");
    };

    mediaQuery.addEventListener("change", handleDeviceChange);

    return () => mediaQuery.removeEventListener("change", handleDeviceChange);
  }, []);

  return deviceType;
};

export default useDeviceType;