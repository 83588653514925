import React, { useState, useEffect, useRef } from "react";

import useDeviceType from "../../hooks/useDeviceType";

import { useDispatch } from "react-redux";
import { disable, enable } from "../../redux/overlayReducer";

import { useNavigate } from "react-router-dom";

import { ReactComponent as Search } from "../../SVGs/Search.svg";

import "./NavbarSearch.scss";

import { useTranslation } from "react-i18next";

const NavbarSearch = () => {
  const deviceType = useDeviceType();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const button = useRef(null);
  const searchRef = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSelectOnClick = (event) => {
    event.stopPropagation();
    setOpen((prev) => {
      if (prev) {
        dispatch(disable());
      } else {
        dispatch(enable());
        setTimeout(() => {
          searchRef.current.focus();
        }, 500);
      }

      return !prev;
    });
  };

  const handleOnSubmitSearch = (event) => {
    event.preventDefault();
    if (searchValue.trim() !== "") {
      navigate(`/search/${searchValue}`);
      dispatch(disable());
      setOpen(false);
    }
    setSearchValue("");
  };

  // disable scoll bar when overlay is present
  // if(open) {
  //     document.body.style.overflow = 'scroll';
  // } else {
  //     document.body.style.overflow = 'auto';
  // }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !button.current.contains(event.target)
      ) {
        dispatch(disable());
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar-search">
      <span
        className={[
          "dropdown-label",
          deviceType == 'mobile' ? "" : "text-underline-animation",
        ].join(" ")}
        onClick={handleSelectOnClick}
        ref={button}
      >
        <Search />
      </span>
      <div
        className={[
          "dropdown-container",
          "dropdown-search",
          open ? "active" : "hidden",
        ].join(" ")}
      >
        <form className="navbar-search-form" onSubmit={handleOnSubmitSearch}>
          <input
            className="search-field"
            type="text"
            placeholder={t("home.start-typing")}
            ref={searchRef}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span onClick={handleOnSubmitSearch}>
            <Search />
          </span>
        </form>
      </div>
    </div>
  );
};

export default NavbarSearch;
