import React, { useEffect, useState, useRef } from "react";
import useDeviceType from "../../hooks/useDeviceType";
import { Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from "../Card/Card";
import LoaderOverlay from "../LoaderOverlay/LoaderOverlay";
import "./FeaturedProducts.scss";
import { useTranslation } from "react-i18next";

const FeaturedProducts = ({
  title,
  link,
  data,
  loading,
  error,
  retryFunc,
  type = "featured",
}) => {
  const deviceType = useDeviceType();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sliderRef = useRef(null);
  let isDragging = false;
  let startX = 0;
  let startTouchX = 0;
  let draggedAmount = 0;

  const prevSlide = () => {
    setCurrentSlide(
      currentSlide === 0 ? products.length - (deviceType == "mobile" ? 1 : 3) : (prev) => prev - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide(
      currentSlide === products.length - (deviceType == "mobile" ? 1 : 3) ? 0 : (prev) => prev + 1
    );
  };

  // Mouse and touch event handlers
  const handleMouseDown = (e) => {
    if (deviceType !== "mobile") return;
    e.preventDefault();
    if (e.target.classList.contains("icon") || e.target.tagName === "svg")
      return;
    isDragging = true;
    startX = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (!isDragging || deviceType !== "mobile") return;

    let deltaX = e.movementX * 25;
    draggedAmount = startX + deltaX;

    sliderRef.current.style.transform = `translateX(${deltaX}px)`;
  };

  const handleMouseUpOrLeave = (e) => {
    e.preventDefault();

    if (!isDragging || deviceType !== "mobile") return;

    const newSlide = Math.round(draggedAmount / 320);
    setCurrentSlide(newSlide);

    isDragging = false;
    draggedAmount = 0;
    startX = 0;
  };

  const handleTouchStart = (e) => {
    if (e.target.classList.contains("icon") || e.target.tagName === "svg")
      return;
    isDragging = true;
    startX = e.touches[0].clientX;

    sliderRef.current.style.transition = "none";
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;

    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;

    sliderRef.current.style.transform = `translateX(calc(${
      currentLanguage == "ar" ? "+" : "-"
    }${currentSlide * /*(deviceType === "mobile" ? 200 : 304)*/ 304}px ${
      currentLanguage == "ar" ? "+" : "-"
    } ${currentSlide * 1}rem + ${deltaX}px))`;

    draggedAmount = deltaX;
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;

    isDragging = false;

    sliderRef.current.style.transition = "transform 0.3s ease";

    const threshold = 50;    
    if (draggedAmount > threshold) {
      prevSlide();
    } else if (draggedAmount < -threshold) {
      nextSlide();
    } else {
      sliderRef.current.style.transform = `translateX(calc(${
        currentLanguage == "ar" ? "+" : "-"
      }${currentSlide * /*(deviceType === "mobile" ? 200 : 304)*/ 304}px ${
        currentLanguage == "ar" ? "+" : "-"
      } ${currentSlide * 1}rem))`;
    }

    draggedAmount = 0;
  };

  useEffect(() => {
    if (!loading && data) {
      if (type === "featured") {
        setProducts([...data]);
      } else if (type === "similar") {
        setProducts(data[0]?.attributes?.RelatedProducts?.data);
      }
      setIsLoading(false);
    } else if (loading && !error) {
      setIsLoading(true);
    } else if (!loading && error && !data) {
      setIsLoading(false);
      setIsError(true);
    }
  }, [data, loading]);

  return (
    <section className="featuredProducts">
      <LoaderOverlay
        loadingState={isLoading}
        errorState={isError}
        setErrorState={setIsError}
        retryFunc={retryFunc}
      />
      <div className="featured-info">
        <h2>{title}</h2>
        <Link to={link} className="cta-button">
          {t("home.shop-the-collection")}
        </Link>
      </div>
      <div
        className="products-slider"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          ref={sliderRef}
          className="container"
          style={{
            transform: `translateX(calc(${currentLanguage == "ar" ? "+" : "-"}${
              currentSlide * /*(deviceType === "mobile" ? 200 : 304)*/ 304
            }px ${currentLanguage == "ar" ? "+" : "-"} ${
              currentSlide * 1
            }rem))`,
          }}
        >
          {!isLoading && !isError ? (
            <>
              {products?.map((product, index) => {
                return <Card key={index} product={product} isSlider={true}></Card>;
              })}
            </>
          ) : null}
        </div>
        {products?.length > (deviceType == "mobile" ? 1 : 3) ? (
          <>
            {currentSlide !== 0 ? (
              <div className="icon icon-prev" onClick={prevSlide}>
                {(document.documentElement.dir || "ltr") === "ltr" ? (
                  <KeyboardArrowLeftIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </div>
            ) : null}
            {currentSlide !== products?.length - (deviceType == "mobile" ? 1 : 3) ? (
              <div className="icon icon-next" onClick={nextSlide}>
                {(document.documentElement.dir || "ltr") === "ltr" ? (
                  <KeyboardArrowRightIcon />
                ) : (
                  <KeyboardArrowLeftIcon />
                )}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </section>
  );
};

export default FeaturedProducts;
