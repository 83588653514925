import React from "react";

import { Link } from "react-router-dom";

import "./NotFound.scss";

import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="not-found">
      <h1 className="not-found-loading">{t("general.not-found")}</h1>
      <Link to={"/"} className="not-found-loading-link">{t("general.return-homepage")}</Link>
    </div>
  );
};

export default NotFound;
