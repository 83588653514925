import React from "react";

import "./ComingSoon.scss";

import { useTranslation } from "react-i18next";

const ComingSoon = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="coming-soon-container">
            <h1 className="coming-soon-label">{t("general.coming-soon")}!</h1>
        </div>
    )
}

export default ComingSoon;