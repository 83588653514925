import { useState, useEffect } from "react";

import useFetch from "./useFetch";

import { useTranslation } from "react-i18next";

const useZones = (countryID, cityID) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [data, setData] = useState({
    countries: [],
    cities: [],
    areas: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "*",
    locale: 'en',
    try: tries,
    "pagination[limit]": -1,
  });

  // get countries
  const countriesBaseUrl = "/countries";
  const countriesUrl = `${countriesBaseUrl}?${Params.toString()}`;
  const {
    data: countriesData,
    loading: countriesLoading,
    error: countriesError,
  } = useFetch(countriesUrl);

  // get cities
  const cityParams = new URLSearchParams(Params);
  cityParams.append("filters[Country][id][$eq]", countryID);
  const citiesBaseUrl = "/cities";
  const citiesUrl = `${citiesBaseUrl}?${cityParams.toString()}`;
  const {
    data: citiesData,
    loading: citiesLoading,
    error: citiesError,
  } = useFetch(countryID ? citiesUrl : "");

  // get areas
  const areaParams = new URLSearchParams(Params);
  areaParams.append("filters[City][id][$eq]", cityID);
  const areasBaseUrl = "/areas";
  const areasUrl = `${areasBaseUrl}?${areaParams.toString()}`;
  const {
    data: areasData,
    loading: areasLoading,
    error: areasError,
  } = useFetch(cityID ? areasUrl : "");

  useEffect(() => {
    if (countriesError || citiesError || areasError) {
      setError(true);
      setLoading(false);
      return;
    }

    if (
      (!countriesLoading && countriesData?.length > 0) ||
      (!citiesLoading && citiesData?.length > 0) ||
      (!areasLoading && areasData?.length > 0)
    ) {
      const countries = countriesData;
      const cities = citiesData;
      const areas = areasData;

      const newData = {
        countries: countries,
        cities: cities,
        areas: areas,
      };

      setData(newData);
      setLoading(false);
      setError(false)
    }
  }, [countriesLoading, citiesLoading, areasLoading]);

  return {
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export { useZones };
