import React, { useEffect, useState } from "react";

import "./ProductOptions.scss";

import { useTranslation } from "react-i18next";

const ProductOptions = ({
  variants,
  setBarcode,
  setSelectedVariantId,
  setIsOutOfStock,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [selectedSize, setSelectedSize] = useState(0);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getColorName = (color) => {
    let colorName = color?.data?.attributes?.Name;
    if(currentLanguage != "en") {
      const localization = color?.data?.attributes?.localizations?.data?.find(
        (local) => local?.attributes?.locale === currentLanguage
      );
      if (localization) {
        colorName = localization?.attributes?.Name
      }
    }

    return colorName;
  }

  const getSizeName = (size) => {
    let sizeName = size?.data?.attributes?.Name;
    if(currentLanguage != "en") {
      const localization = size?.data?.attributes?.localizations?.data?.find(
        (local) => local?.attributes?.locale === currentLanguage
      );
      if (localization) {
        sizeName = localization?.attributes?.Name
      }
    }

    return sizeName;
  }

  const getFirstAvailableSizeIndex = (sizes) => {
    for(const [index, size] of sizes?.entries()) {
      if(size?.Quantity > 0) {
        return index;
      }
    }
  }

  const changeColor = (variant) => {
    setSelectedVariant(variant);
    setSelectedVariantId(variant?.id);
    const availableSizeIndex = getFirstAvailableSizeIndex(variant?.Sizes);
    setSelectedSize(variant?.Sizes[availableSizeIndex]);
    setBarcode(variant?.Sizes[availableSizeIndex]?.Barcode);
  };

  const changeSize = (size) => {
    setSelectedSize(size);
    setBarcode(size?.Barcode);
  };

  const initVariants = (variants) => {
    if (variants?.length === 0) return;

    let isAllOutOfStock = true;
    for (const variant of variants) {
      for (const size of variant?.Sizes) {
        if (size?.Quantity > 0) {
          if(variant?.Images?.data) {
            setSelectedVariant(variant);
            setSelectedVariantId(variant?.id);
            setSelectedSize(size);
            setBarcode(size?.Barcode);
            isAllOutOfStock = false;
            break;
          }
        }
      }
      if (!isAllOutOfStock) break;
    }

    if (isAllOutOfStock) {
      setIsOutOfStock(true);
    }
  };

  useEffect(() => {
    initVariants(variants);
  }, [variants]);

  return (
    <div className="product-options">
      <div className="size-group">
        <h3 className="title">{t("product.sizes")}</h3>
        <div className="container">
          {variants?.map((variant) => {
            if (variant?.id === selectedVariant?.id) {
              return variant?.Sizes?.map((size, sizeIndex) => {
                return (
                  <button
                    key={sizeIndex}
                    id={size?.id}
                    className={[
                      "option-button",
                      size?.id === selectedSize?.id ? "active" : "none",
                    ].join(" ")}
                    onClick={() => {
                      if (size?.id != selectedSize?.id) {
                        changeSize(size);
                      }
                    }}
                    disabled={size?.Quantity <= 0}
                  >
                    {getSizeName(size?.Size)}
                  </button>
                );
              });
            }
          })}
        </div>
      </div>
      <div className="color-group">
        <h3 className="title">{t("product.colors")}</h3>
        <div className="container">
          {variants?.map((variant, index) => {
            if (variant?.Images?.data) {
              return (
                <button
                  key={index}
                  id={variant?.id}
                  className={[
                    "option-button",
                    variant?.id === selectedVariant?.id ? "active" : "none",
                  ].join(" ")}
                  onClick={() => {
                    if (variant?.id != selectedVariant?.id) {
                      changeColor(variant);
                    }
                  }}
                  disabled={
                    variant?.Sizes?.filter((size) => size?.Quantity > 0)
                      ?.length === 0
                  }
                >
                  {getColorName(variant?.Color)}
                </button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductOptions;
