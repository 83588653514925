import React, { useState, useEffect, useRef } from "react";

import useFetch from "../../hooks/useFetch";
import {
  encodeURL,
  capitalizeWords,
  applyDiscountRules,
} from "../../helpers/common";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import { openCart } from "../../redux/cartVisibilityReducer";

import "./Product.scss";
import { ReactComponent as Share } from "../../SVGs/Share.svg";
import { ReactComponent as Whatsapp } from "../../SVGs/Whatsapp.svg";
import { ReactComponent as Close } from "../../SVGs/CloseWhite.svg";

import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";
import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import ProductOptions from "../../components/ProductOptions/ProductOptions";
import ProductDetails from "../../components/ProductDetails/ProductDetails";
import SizeTable from "../../components/SizeTable/SizeTable";
import { enable } from "../../redux/overlayReducer";
import useDeviceType from "../../hooks/useDeviceType";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Product = () => {
  const slug = useParams().slug;
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const imageGallery = useRef(null);

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  const [productInfo, setProductInfo] = useState({});
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isProductError, setIsProductError] = useState(false);
  const [productRetries, setProductRetries] = useState(0);

  const [barcode, setBarcode] = useState("");
  const [images, setImages] = useState([]);
  const [selectedVariantId, setSelectedVariantId] = useState(0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [image, setImage] = useState("");
  const [isZooming, setIsZooming] = useState(false);

  const discountRules = useSelector((state) => state.discountRules);

  const productBaseUrl = "/products";
  const productParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    "filters[Status][$eq]": true,
    "filters[SEOURL][$eq]": slug,
    try: productRetries,
  });
  const productUrl = `${productBaseUrl}?${productParams.toString()}`;
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useFetch(productUrl);

  const retryProduct = () => setProductRetries((prev) => prev + 1);

  const openImage = (imageURL) => {
    setImageGalleryOpen(true);
    setImage(imageURL);
  };

  const closeImage = () => {
    setImageGalleryOpen(false);
    setImage("");
  };

  const toggleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: document.title,
        url: window.location.href,
      });
    }
  };

  const zoomMouseClick = (e) => {
    if(deviceType === "mobile") return;

    if (isZooming) {
      setIsZooming(false);
      imageGallery.current.style.transform = `scale(1) ${
        currentLanguage == "en" ? "translateX(-50%)" : "translateX(50%)"
      }`;
      imageGallery.current.style.transformOrigin = "center center";
      imageGallery.current.style.cursor = "zoom-in";
    } else {
      setIsZooming(true);
      const { clientX, clientY, target } = e;
      const { width, height } = target;

      const xPercent = (clientX / width) * 100;
      const yPercent = (clientY / height) * 100;

      imageGallery.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      imageGallery.current.style.transform = `scale(2)`;
      imageGallery.current.style.cursor = "zoom-out";
    }
  };

  const zoomMouseMove = (e) => {
    if (!isZooming) return;

    const { clientX, clientY, target } = e;
    const { width, height } = target;

    const xPercent = (clientX / width) * 100;
    const yPercent = (clientY / height) * 100;

    imageGallery.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
    imageGallery.current.style.transform = `scale(2)`;
  };

  const addProduct = () => {
    dispatch(
      addToCart({
        id: productInfo?.id,
        quantity: 1,
        barcode: barcode,
        selectedVariantId: selectedVariantId,
        image: productInfo?.attributes?.DefaultImage?.data?.id
      })
    );
    dispatch(openCart());
    dispatch(enable());
  };

  useEffect(() => {
    if (!productLoading && productData?.length > 0) {
      let product = productData[0];
      if (currentLanguage != "en") {
        const localization = product?.attributes?.localizations?.data?.find(
          (local) => local?.attributes?.locale === currentLanguage
        );
        if (localization) {
          product = {
            ...product,
            attributes: {
              ...product?.attributes,
              ...localization?.attributes,
              SizeGuide: product?.attributes?.SizeGuide,
            },
          };
        }
      }
      const newProducts = applyDiscountRules(
        [product],
        discountRules,
        selectedVariantId,
        barcode
      );
      setProductInfo(newProducts[0]);
      setMetaTitle(newProducts[0]?.attributes?.SEO?.metaTitle);
      setMetaDescription(newProducts[0]?.attributes?.SEO?.metaDescription);
      setMetaKeywords(newProducts[0]?.attributes?.SEO?.keywords);
      setIsProductLoading(false);
    } else if (!productLoading && productData?.length == 0) {
      setIsProductLoading(false);
    } else if (productLoading && !productError) {
      setIsProductLoading(true);
    } else if (!productLoading && productError && !productData) {
      setIsProductLoading(false);
      setIsProductError(true);
    }
  }, [productLoading, currentLanguage]);

  useEffect(() => {
    if (!productInfo) return;
    const variant = productInfo?.attributes?.Variants.find(
      (variant) => variant.id === selectedVariantId
    );
    if (variant) {
      let newImages = variant?.Images?.data;
      if (newImages?.length === 0) {
        newImages = productInfo?.attributes?.Images?.data;
      }
      setImages(newImages);
    }
    const discountedProduct = applyDiscountRules(
      [productInfo],
      discountRules,
      selectedVariantId,
      barcode
    );
    setProductInfo((prev) => ({ ...prev, ...discountedProduct[0] }));
  }, [barcode]);

  useEffect(() => {
    if (isOutOfStock) {
      let newImages = productInfo?.attributes?.Images?.data;
      if(!newImages) {
        newImages = [productInfo?.attributes?.DefaultImage?.data]
      }
      setImages(newImages);
    }
  }, [isOutOfStock]);

  useEffect(() => {
    setProductInfo({});
    setImages([]);
    setSelectedVariantId(0);
    setBarcode("");
    setIsOutOfStock(false);
    setMetaTitle("");
    setMetaDescription("");
    setMetaKeywords("");
    setProductRetries(0);
    setIsProductError(false);
    setIsProductLoading(false);
  }, [slug]);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t(
      "product.product"
    )} ${capitalizeWords(slug)}`;
  }, [productInfo]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  let isDragging = false;
  let startX = 0;
  let draggedAmount = 0;

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  // Mouse Handlers
  const handleMouseDown = (e) => {
    if (deviceType !== "mobile") return;
    isDragging = true;
    startX = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (!isDragging || deviceType !== "mobile") return;
    const deltaX = e.movementX;
    sliderRef.current.style.transform = `translateX(calc(${
      currentLanguage == "ar" ? "+" : "-"
    }${currentSlide * 100}% + ${deltaX}px))`;
    draggedAmount = deltaX;
  };

  const handleMouseUpOrLeave = () => {
    if (!isDragging || deviceType !== "mobile") return;
    isDragging = false;
    if (draggedAmount > 50) {
      prevSlide();
    } else if (draggedAmount < -50) {
      nextSlide();
    } else {
      sliderRef.current.style.transform = `translateX(calc(${
        currentLanguage == "ar" ? "+" : "-"
      }${currentSlide * 100}%))`;
    }
    draggedAmount = 0;
  };

  // Touch Handlers
  const handleTouchStart = (e) => {
    isDragging = true;
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;
    sliderRef.current.style.transform = `translateX(calc(${
      currentLanguage == "ar" ? "+" : "-"
    }${currentSlide * 100}% + ${deltaX}px))`;
    draggedAmount = deltaX;
  };

  const handleTouchEnd = () => {
    if (!isDragging) return;
    isDragging = false;
    if (draggedAmount > 50) {
      prevSlide();
    } else if (draggedAmount < -50) {
      nextSlide();
    } else {
      sliderRef.current.style.transform = `translateX(calc(${
        currentLanguage == "ar" ? "+" : "-"
      }${currentSlide * 100}%))`;
    }
    draggedAmount = 0;
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={metaDescription || t("home.default-description")}
        />
        <meta
          name="keywords"
          content={metaKeywords || t("home.default-keywords")}
        />
      </Helmet>
      <section className="product">
        <LoaderOverlay
          loadingState={isProductLoading}
          errorState={isProductError}
          setErrorState={setIsProductError}
          retryFunc={retryProduct}
        />
        <div
          className={[
            "image-gallery",
            imageGalleryOpen ? "active" : "none",
          ].join(" ")}
        >
          <div className="image-gallery-overlay" onClick={closeImage}></div>
          <Close className="close-btn" onClick={closeImage} />
          <img
            className="image-gallery-img"
            src={image}
            alt={`image`}
            ref={imageGallery}
            onClick={zoomMouseClick}
            onMouseMove={zoomMouseMove}
            style={{
              transform:
                currentLanguage == "en"
                  ? "translateX(-50%)"
                  : "translateX(50%)",
            }}
          />
        </div>
        {productData?.length != 0 ? (
          <>
            <main className="main">
              <div className="images">
                {deviceType === "mobile" ? (
                  <div
                    className="images-slider"
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUpOrLeave}
                    onMouseLeave={handleMouseUpOrLeave}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                  >
                    <div
                      ref={sliderRef}
                      className="images-container"
                      style={{
                        transform: `translateX(calc(${
                          currentLanguage == "ar" ? "+" : "-"
                        }${currentSlide * 100}%))`,
                      }}
                    >
                      {images?.map((img, index) => (
                        <div key={index} className="image-slide">
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              img?.attributes?.url
                            }
                            alt={`image-${index}`}
                            onClick={() => {
                              openImage(
                                process.env.REACT_APP_IMAGE_URL +
                                  img?.attributes?.url
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    {/* Pagination */}
                    <div className="pagination">
                      {images?.map((_, index) => (
                        <span
                          key={index}
                          className={`dot ${
                            currentSlide === index ? "active" : ""
                          }`}
                          onClick={() => setCurrentSlide(index)}
                        />
                      ))}
                    </div>

                    {/* Navigation Buttons */}
                    <button className="prev-btn" onClick={prevSlide}>
                      &lt;
                    </button>
                    <button className="next-btn" onClick={nextSlide}>
                      &gt;
                    </button>
                  </div>
                ) : (
                  images?.map((img, index) => (
                    <img
                      key={index}
                      src={
                        process.env.REACT_APP_IMAGE_URL + img?.attributes?.url
                      }
                      alt={`image-${index}`}
                      onClick={() => {
                        openImage(
                          process.env.REACT_APP_IMAGE_URL + img?.attributes?.url
                        );
                      }}
                    />
                  ))
                )}
              </div>

              <div className="info">
                <div className="heading">
                  <div className="title-container">
                    <h1 className="title">{productInfo?.attributes?.Title}</h1>
                    <img
                      className="trademark"
                      src="/img/trademark.png"
                      alt={productInfo?.attributes?.Title}
                    ></img>
                  </div>
                  <p className="subtitle">
                    <span className="keyword">
                      {productInfo?.attributes?.Subtitle}
                    </span>
                  </p>
                  <p className="code">
                    <span className="keyword">{t("product.code")}: </span>
                    <span className="code-text">
                      {barcode ? barcode : productInfo?.attributes?.CODE}
                    </span>
                  </p>
                </div>
                <div className="price">
                  {productInfo?.attributes?.discountedPrice &&
                  productInfo?.attributes?.discountedPrice !==
                    productInfo?.attributes?.Price ? (
                    <div className="real-price">
                      <div className="container">
                        <span className="numerical-price">
                          {productInfo?.attributes?.Price}
                        </span>
                        <span className="currency">{t("home.aed")}</span>
                      </div>
                      <span className="vat">{t("home.price-with-vat")}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="discounted-price">
                    <div className="container">
                      <span className="numerical-price">
                        {productInfo?.attributes?.discountedPrice ??
                          productInfo?.attributes?.Price}
                      </span>
                      <span className="currency">{t("home.aed")}</span>
                    </div>
                    <span className="vat">{t("home.price-with-vat")}</span>
                  </div>
                </div>
                <p className="description">
                  {productInfo?.attributes?.Description}
                </p>
                {productInfo?.attributes?.Variants && !isOutOfStock && (
                  <>
                    <div className="options">
                      <ProductOptions
                        variants={productInfo?.attributes?.Variants}
                        setBarcode={setBarcode}
                        setSelectedVariantId={setSelectedVariantId}
                        setIsOutOfStock={setIsOutOfStock}
                      />
                    </div>
                  </>
                )}
                <div className="buttons">
                  <button
                    className="add-to-cart-btn"
                    onClick={addProduct}
                    disabled={isOutOfStock}
                  >
                    {!isOutOfStock
                      ? t("product.add-to-cart")
                      : t("product.out-of-stock")}
                  </button>
                  <Share onClick={toggleShare} className="share-btn" />
                </div>
                <div className="questions">
                  <h3 className="got-questions">
                    {t("product.got-questions")}
                  </h3>
                  <div className="whatsapp" dir="ltr">
                    <Whatsapp />
                    <a
                      className="whatsapp-link"
                      href="https://api.whatsapp.com/send?phone=971544438476"
                    >
                      +971 54 443 8476
                    </a>
                  </div>
                </div>
                <div className="product-details">
                  <ProductDetails details={productInfo} />
                </div>
                {productInfo?.attributes?.SizeGuide && (
                  <div className="size-table">
                    <SizeTable details={productInfo} />
                  </div>
                )}
              </div>
            </main>
            {!productLoading &&
              !productError &&
              productInfo?.attributes?.RelatedProducts?.data?.length > 0 && (
                <>
                  <hr />
                  <aside className="similar">
                    <FeaturedProducts
                      data={productData}
                      loading={productLoading}
                      error={productError}
                      retryFunc={retryProduct}
                      title={t("product.similar")}
                      link={`/category/${encodeURL(
                        productInfo?.attributes?.Categories?.data[0]?.attributes
                          ?.Title
                      )}`}
                      type="similar"
                    />
                  </aside>
                </>
              )}
          </>
        ) : (
          <>
            <main className="main">
              <h1 className="product-not-found">
                {t("product.product-not-found")}
              </h1>
            </main>
          </>
        )}
      </section>
    </>
  );
};

export default Product;
