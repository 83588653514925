import React, { useState, useEffect, useRef } from "react";

import useFetch from "../../hooks/useFetch";
import usePost from "../../hooks/usePost";
import usePut from "../../hooks/usePut";
import { useZones } from "../../hooks/useZones";
import { useGetCity } from "../../hooks/useGetCity";
import { useGetArea } from "../../hooks/useGetArea";
import { usePaymentMethods } from "../../hooks/usePaymentMethods";
import useFoloosiReferenceToken from "../../hooks/useFoloosiReferenceToken";
import useNetworkPayment from "../../hooks/useNetworkPayment";
import { useShippingProviders } from "../../hooks/useShippingProviders";
import { useShippingRates } from "../../hooks/useShippingRates";
import useDHLShipping from "../../hooks/useDHLShipping";
import useSMSAShipping from "../../hooks/useSMSAShipping";
import useSettings from "../../hooks/useSettings";
import useGetOffers from "../../hooks/useGetOffers";
import { useAddress } from "../../hooks/useAddress";

import {
  calculatePromoDeduct,
  applyDiscountRules,
  generateUser,
  isPhoneValid,
  updateCountryFromPhoneNumber,
  isPromoValid,
  getShippingRate,
  getColorAndSize,
  calculateWeight,
  subscribeToNewsletter,
  generateInvoiceNumber,
  generatePackageID,
  uploadBase64PdfToStrapi,
  updateOrderWithInvoice,
  applyOffers,
  calculateFreeShipping,
  getUserPoints,
  getProductsQuantities,
} from "../../helpers/common";

import { useDispatch, useSelector } from "react-redux";
import {
  enableIsPromoApplied,
  disableIsPromoApplied,
  enableIsVoucher,
  disableIsVoucher,
  setVoucherAmount,
  addPromo,
  removePromo,
  addPoints,
  removePoints,
  addShippingData,
  setOrderID,
} from "../../redux/checkoutReducer";
import { setUser } from "../../redux/userReducer";

import { Link, useNavigate, useLocation } from "react-router-dom";

import "./Checkout.scss";

import SelectWithInnerLabel from "../../components/SelectWithInnerLabel/SelectWithInnerLabel";
import InputWithInnerLabel from "../../components/InputWithInnerLabel/InputWithInnerLabel";
import RadioBoxCollection from "../../components/RadioBoxCollection/RadioBoxCollection";
import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import CartCard from "../../components/CartCard/CartCard";
import FoloosiPayment from "../../components/FoloosiPayment/FoloosiPayment";
import Map from "../../components/Map/Map";

import { ReactComponent as MapPin } from "../../SVGs/MapPin.svg";

import { useTranslation } from "react-i18next";

const Checkout = () => {
  // global state
  const placeOrderBtn = useRef(null);
  const user = useSelector((state) => state.user.user);
  const productsIds = useSelector((state) => state.cart.products);
  const checkout = useSelector((state) => state.checkout);
  const discountRules = useSelector((state) => state.discountRules);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();

  //   personal info
  const [errorMessage, setErrorMessage] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [emailMe, setEmailMe] = useState(false);
  const [country, setCountry] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState(0);
  const [area, setArea] = useState(0);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [canRedeem, setCanRedeem] = useState(false);
  const [isUsingPoints, setIsUsingPoints] = useState(false);
  const [pointsToRedeem, setPointsToRedeem] = useState(0);
  const [pointsTotal, setPointsTotal] = useState(0);
  const [userPoints, setUserPoints] = useState(0);
  const [redeemPointsPercentage, setRedeemPointsPercentage] = useState(0);
  const [minimumPoints, setMinimumPoints] = useState(0);
  const [pointsErrorMessage, setPointsErrorMessage] = useState("");

  const [addressesArr, setAddressesArr] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [areAddressesLoading, setAreAddressesLoading] = useState(false);
  const [areAddressesError, setAreAddressesError] = useState(false);
  const [addressesRetries, setAddressesRetries] = useState(0);

  const [turnMapOff, setTurnMapOff] = useState(false);
  const [AddressViaMap, setAddressViaMap] = useState({
    Country: 0,
    CountryName: "",
    City: 0,
    CityName: "",
    CityName2: "",
    Area: 0,
    AreaName: "",
    AreaName2: "",
    AddressLine1: "",
    AddressLine2: "",
    PostalCode: "",
    FirstName: "",
    LastName: "",
    MapLink: "",
    DisplayName: "",
    SelectedPosition: [],
  });
  const [cityViaMap, setCityViaMap] = useState("");
  const [city2ViaMap, setCity2ViaMap] = useState("");
  const [isCitySorted, setIsCitySorted] = useState(false);
  const [areaViaMap, setAreaViaMap] = useState("");
  const [area2ViaMap, setArea2ViaMap] = useState("");
  const [isAreaSorted, setIsAreaSorted] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [mapLink, setMapLink] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState([
    25.276987, 55.296249,
  ]);

  const [phone, setPhone] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  const [shippingProvidersArr, setShippingProvidersArr] = useState([]);
  const [shippingRatesArr, setShippingRatesArr] = useState([]);
  const [shipping, setShipping] = useState("");
  const [shippingProductCode, setShippingProductCode] = useState("");
  const [hasFreeShipping, setHasFreeShipping] = useState(false);

  const [deliveryNotes, setDeliveryNotes] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [showGift, setShowGift] = useState(false);
  const [giftMessage, setGiftMessage] = useState("");

  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [additionalMethods, setAdditionalMethods] = useState(new Set([]));

  const [countriesArr, setCountriesArr] = useState([]);
  const [citiesArr, setCitiesArr] = useState([]);
  const [areasArr, setAreasArr] = useState([]);

  //   products
  const [products, setProducts] = useState([]);
  const [areProductsLoading, setAreProductsLoading] = useState(false);
  const [areProductsError, setAreProductsError] = useState(false);
  const [productsRetries, setProductsRetries] = useState(0);

  const [promoValue, setPromoValue] = useState("");
  const [isPromoLoading, setIsPromoLoading] = useState(false);
  const [isPromoError, setIsPromoError] = useState(false);
  const [promoRetries, setPromoRetries] = useState(0);
  const [promoParams, setPromoParams] = useState(
    new URLSearchParams({
      populate: "*",
      "filters[Name][$eq]": "",
      try: promoRetries,
    })
  );
  const [showPromoMessage, setShowPromoMessage] = useState(false);
  const [promoMessage, setPromoMessage] = useState("");
  const [promoAmountToDeduct, setPromoAmountToDeduct] = useState(0);

  const [isVoucherLoading, setIsVoucherLoading] = useState(false);
  const [isVoucherError, setIsVoucherError] = useState(false);
  const [voucherRetries, setVoucherRetries] = useState(0);
  const [voucherParams, setVoucherParams] = useState(
    new URLSearchParams({
      populate: "*",
      "filters[Code][$eq]": "",
      try: voucherRetries,
    })
  );

  const [subtotal, setSubtotal] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [total, setTotal] = useState(0);
  const [vat, setVat] = useState(0);

  // place order request state variables
  const [placeOrderData, setPlaceOrderData] = useState({});
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [placeOrderError, setPlaceOrderError] = useState(false);
  const [placeOrderRetries, setPlaceOrderRetries] = useState(0);
  const [requestData, setRequestData] = useState({});
  const [checkoutBtnDisabled, setCheckoutBtnDisabled] = useState(false);

  const [settings, setSettings] = useState([]);

  const [offers, setOffers] = useState([]);
  const [appliedOffer, setAppliedOffer] = useState({});
  const [offerTip, setOfferTip] = useState("");
  const [offeredProducts, setOfferedProducts] = useState([]);

  const [packages, setPackages] = useState([]);
  const [invoice, setInvoice] = useState("");

  // update order state variables
  const [updateOrderRequestData, setUpdateOrderRequestData] = useState({});
  const [updateOrderData, setUpdateOrderData] = useState({});
  const [updateOrderRetries, setUpdateOrderRetries] = useState(0);
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
  const [updateOrderError, setUpdateOrderError] = useState(false);
  const [noRedirect, setNoRedirect] = useState(false);

  const updateOrderUrl = `/orders/${placeOrderData?.data?.id}`;
  const {
    data: UpdateOrderData,
    loading: UpdateOrderLoading,
    error: UpdateOrderError,
    status: UpdateOrderStatus,
    errorMessage: UpdateOrderErrorMessage,
  } = usePut(updateOrderUrl, updateOrderRequestData);

  const productsBaseUrl = "/products";
  const productsParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    try: productsRetries,
  });
  productsIds.forEach((product, index) => {
    productsParams.append(`filters[id][$in][${index}]`, product.id);
  });

  const productsUrl = `${productsBaseUrl}?${productsParams.toString()}`;
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useFetch(productsUrl);

  const promoBaseUrl = "/promo-codes";
  const promoUrl = `${promoBaseUrl}?${promoParams?.toString()}`;
  const {
    data: promoData,
    loading: promoLoading,
    error: promoError,
  } = useFetch(promoUrl);

  const voucherBaseUrl = "/vouchers";
  const voucherUrl = `${voucherBaseUrl}?${voucherParams?.toString()}`;
  const {
    data: voucherData,
    loading: voucherLoading,
    error: voucherError,
  } = useFetch(voucherUrl);

  const addressesBaseUrl = "/addresses";
  const addressesParams = new URLSearchParams({
    populate: "deep",
    "filters[User][id][$eq]": user?.id,
    sort: "updatedAt:desc",
    try: addressesRetries,
  });

  const addressesUrl = `${addressesBaseUrl}?${addressesParams.toString()}`;
  const {
    data: addressesData,
    loading: addressesLoading,
    error: addressesError,
  } = useFetch(addressesUrl);

  const PlaceOrderUrl = "/orders";
  const {
    data: PlaceOrderData,
    loading: PlaceOrderLoading,
    error: PlaceOrderError,
    status: PlaceOrderStatus,
    errorMessage: PlaceOrderErrorMessage,
  } = usePost(PlaceOrderUrl, requestData);

  const {
    data: zonesData,
    loading: zonesLoading,
    error: zonesError,
    setError: setZonesError,
    retry: zonesRetryFunc,
  } = useZones();

  const {
    data: CityData,
    loading: CityLoading,
    error: CityError,
  } = useGetCity(country);

  const {
    data: AreaData,
    loading: AreaLoading,
    error: AreaError,
  } = useGetArea(city);

  const {
    addAddress,
    updateAddress,
    loading: AddressHookLoading,
    error: AddressHookError,
    setError: setAddressHookError,
    retry: AddressHookRetryFunc,
  } = useAddress();

  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    error: paymentMethodsError,
    setError: setPaymentMethodsError,
    retry: paymentMethodsRetryFunc,
  } = usePaymentMethods();

  const {
    data: shippingProvidersData,
    loading: shippingProvidersLoading,
    error: shippingProvidersError,
    setError: setShippingProvidersError,
    retry: shippingProvidersRetryFunc,
  } = useShippingProviders();

  const {
    data: shippingRatesData,
    loading: shippingRatesLoading,
    error: shippingRatesError,
    setError: setShippingRatesError,
    retry: shippingRatesRetryFunc,
  } = useShippingRates();

  const {
    referenceToken: foloosiReferenceToken,
    loading: foloosiReferenceTokenLoading,
    error: foloosiReferenceTokenError,
    getReferenceToken,
  } = useFoloosiReferenceToken();

  const {
    paymentLink: NetworkPaymentLink,
    loading: NetworkLoading,
    error: NetworkError,
    initiatePayment,
  } = useNetworkPayment();

  const {
    getDHLSettings,
    createShipment,
    getRates: getDHLRates,
    loading: dhlLoading,
    error: dhlError,
    setError: setDhlError,
    retry: retryDHL,
  } = useDHLShipping();

  const {
    createSMSAShipment,
    loading: SMSALoading,
    error: SMSAError,
    setError: setSMSAError,
    retry: retrySMSA,
  } = useSMSAShipping();

  const {
    getSiteSettings,
    data: siteSettingsData,
    loading: siteSettingsLoading,
    error: siteSettingsError,
    setError: siteSettingsSetError,
    retry: siteSettingsRetry,
  } = useSettings();

  const {
    getOffers,
    data: offersData,
    loading: offersLoading,
    error: offersError,
    setError: offersSetError,
    retry: offersRetry,
  } = useGetOffers();

  const retryProduct = () => setProductsRetries((prev) => prev + 1);
  const retryPromo = () => setPromoRetries((prev) => prev + 1);
  const retryVouchers = () => setVoucherRetries((prev) => prev + 1);
  const retryPlaceOrder = () => setPlaceOrderRetries((prev) => prev + 1);
  const retryUpdateOrder = () => setUpdateOrderRetries((prev) => prev + 1);
  const retryAddresses = () => setAddressesRetries((prev) => prev + 1);

  //   handler functions
  const handleOffers = (products, offers) => {
    const { offer, tip, offerAppliedProducts } = applyOffers(products, offers);
    if (offer && offerAppliedProducts?.length > 0) {
      setAppliedOffer(offer);
      setOfferedProducts(offerAppliedProducts);
      setOfferTip("");
    } else if (!offer && tip) {
      setAppliedOffer({});
      setOfferedProducts([]);
      setOfferTip(tip);
    } else if (!offer && !tip) {
      setAppliedOffer({});
      setOfferedProducts([]);
      setOfferTip("");
    }

    if (offer && offerAppliedProducts?.length > 0 && products?.length > 0) {
      const offerDeductionValue = offer?.attributes?.DiscountValue;
      const offerDeductionType = offer?.attributes?.DiscountType;

      const newProducts = [];
      for (const product of products) {
        const offeredProduct = offerAppliedProducts?.find(
          (prod) => product?.product?.id == prod?.productID
        );
        if (!offeredProduct) {
          const newProd = structuredClone(product);
          newProducts.push(newProd);
          continue;
        }
        let newProductDiscount = 0;
        const productPrice =
          product?.product?.attributes?.discountedPrice ??
          product?.product?.attributes?.Price;
        if (offerDeductionType == "Percentage") {
          newProductDiscount = productPrice * (offerDeductionValue / 100);
        } else {
          newProductDiscount = offerDeductionValue;
        }
        const newProd = structuredClone(product);
        if (newProd.qty > offeredProduct?.quantity) {
          const newestProd = structuredClone(newProd);
          newProd.qty = offeredProduct?.quantity;
          newestProd.qty -= offeredProduct?.quantity;
          newProducts.push(newestProd);
        }
        newProd.product.attributes.discountedPrice = parseFloat(
          (productPrice - newProductDiscount).toFixed(2)
        );
        newProducts.push(newProd);
      }
      return newProducts;
    }

    return products;
  };

  const processProducts = () => {
    const newProducts = productsIds.map((localProduct) => {
      const product = productsData?.find(
        (product) => product.id === localProduct.id
      );
      if (product) {
        const newProduct = structuredClone(product);
        const discountedProduct = applyDiscountRules(
          [newProduct],
          discountRules,
          localProduct.selectedVariantId,
          localProduct.barcode,
          country
        );
        return {
          product: discountedProduct[0],
          barcode: localProduct.barcode,
          selectedVariantId: localProduct.selectedVariantId,
          qty: localProduct.quantity,
        };
      }
    });

    // Check offers
    const newest = handleOffers(newProducts, offers);
    return newest;
  };

  const processTotals = () => {
    if (productsIds?.length === 0) {
      dispatch(disableIsPromoApplied());
      dispatch(removePromo());
      setPromoValue("");
      setShowPromoMessage(false);
      setPromoRetries(0);
      setPromoMessage("");
      return;
    }

    let subtotal = 0;
    let vat = 0;
    let total = 0;
    let amountToDeduct = 0;

    products.forEach((prod) => {
      const quantity = prod?.qty;
      let productPrice = prod?.product?.attributes?.discountedPrice
        ? prod?.product?.attributes?.discountedPrice
        : prod?.product?.attributes?.Price;
      let productPriceWithoutVAT = productPrice / 1.05;
      subtotal += productPrice * quantity;
      vat += (productPrice - productPriceWithoutVAT) * quantity;
    });

    total = subtotal + shippingValue;

    if (hasFreeShipping) {
      total -= parseFloat(shippingValue);
    }

    if (checkout?.isPromoApplied) {
      amountToDeduct = calculatePromoDeduct(checkout?.promo, subtotal);
      setPromoValue(checkout?.promo?.attributes?.Name);

      if (checkout?.isVoucher) {
        if (parseFloat(amountToDeduct) >= total) {
          amountToDeduct = total;
        }
        if (parseFloat(shippingValue) == 0 && !hasFreeShipping) {
          amountToDeduct = 0;
        }
        setAdditionalMethods((prev) => prev.add("Voucher"));
      }
    }

    total -= parseFloat(amountToDeduct);
    if (total <= 0) {
      total = 0;
    }

    if (parseFloat(pointsTotal) > 0 && total > 0) {
      if (parseFloat(pointsTotal) >= total) total = 0;
      else total -= parseFloat(pointsTotal);
      setAdditionalMethods((prev) => prev.add("Points"));
    }

    setPromoAmountToDeduct(amountToDeduct);
    setSubtotal((subtotal.toFixed(2) - vat.toFixed(2)).toFixed(2));
    setVat(vat.toFixed(2));
    setTotal(total.toFixed(2));
  };

  const submitPromo = (event) => {
    event.preventDefault();
    if (promoValue.trim() === "") return;
    const newPromoParams = new URLSearchParams(promoParams);
    newPromoParams.set("filters[Name][$eq]", promoValue);
    setPromoParams(newPromoParams);
  };

  const applyPromo = async (promo, isVoucher) => {
    if (
      promo?.length === 0 &&
      promoParams.get("filters[Name][$eq]") === "" &&
      voucherParams.get("filters[Code][$eq]") === ""
    ) {
      return;
    }

    if (
      promo?.length === 0 &&
      promoParams.get("filters[Name][$eq]") !== "" &&
      !isVoucher
    ) {
      const newVoucherParams = new URLSearchParams(voucherParams);
      newVoucherParams.set("filters[Code][$eq]", promoValue);
      setVoucherParams(newVoucherParams);
      return;
    }

    if (
      promo?.length === 0 &&
      voucherParams.get("filters[Code][$eq]") !== "" &&
      isVoucher
    ) {
      setShowPromoMessage(true);
      setPromoMessage(t("errors.promo-not-exist"));
      return;
    }

    // if (isVoucher && user?.id !== promo[0]?.attributes?.User?.data?.id) {
    //   setShowPromoMessage(true);
    //   setPromoMessage(t("errors.promo-not-exist"));
    //   return;
    // }

    const isValid = await isPromoValid(user, promo[0], isVoucher);
    if (!isValid) {
      setShowPromoMessage(true);
      setPromoMessage(t("errors.promo-not-exist"));
      return;
    }
    setShowPromoMessage(false);

    dispatch(enableIsPromoApplied());
    dispatch(addPromo(promo[0]));
    if (isVoucher) {
      dispatch(enableIsVoucher());
    } else {
      dispatch(disableIsVoucher());
    }
  };

  const handlePayment = () => {
    if (parseFloat(total) == 0) {
      updatePaymentResponse({}, true);
    } else if (paymentMethod === "Foloosi") {
      const merchantKey = process.env.REACT_APP_FOLOOSI_MERCHANT_KEY;
      const payload = {
        transaction_amount: total,
        currency: "AED",
        customer_name: `${firstName} ${lastName}`,
        customer_email: registerEmail,
        customer_mobile: phone,
      };
      getReferenceToken(merchantKey, payload);
    } else if (paymentMethod === "Network International") {
      initiatePayment("AED", total, registerEmail);
    } else {
      updatePaymentResponse({}, true);
    }
  };

  const submitOrder = async () => {
    let User = user?.id;
    let UserObject = user;
    let Email = registerEmail;
    let FirstName = firstName;
    let LastName = lastName;
    let Address1 = addressLine1;
    let Address2 = addressLine2;
    let PostalCode = postalCode;
    let PaymentMethod = [paymentMethod, ...additionalMethods].join(" - ");
    let ShippingMethod = shipping;
    let PaymentResponse = "";
    let TotalBeforVAT = `${(total - vat).toFixed(2)}`;
    let TotalAfterVAT = total;
    let Status = "";
    let Phone = phone;
    let ShippingCost = shippingValue.toFixed(2);
    let IsGift = showGift;
    let GiftMessage = giftMessage;
    let Notes = deliveryNotes;
    let SpecialRequest = specialRequests;
    let VoucherCode = "";
    let CouponCode = "";
    let DicountAmount = 0;
    let Packages = [];
    let AddressDisplayName = displayName;
    let AddressMapLink = mapLink;

    const productsQtyIds = products?.map((prod) => prod?.product?.id);
    const filteredIds = new Set(productsQtyIds);
    const quantities = await getProductsQuantities(filteredIds);

    let QtyError = "";
    for (const product of products) {
      for (const qty of quantities) {
        if (product?.product?.id !== qty?.id) continue;

        for (const variant of qty?.attributes?.Variants) {
          let sizeFound = false;
          for (const size of variant?.Sizes) {
            if (product?.barcode !== size?.Barcode) continue;

            if (parseFloat(size?.Quantity) < parseFloat(product?.qty)) {
              QtyError = `${product?.product?.attributes?.Title} ${t("checkout.with-barcode")} ${product?.barcode} ${t("checkout.has-only")} ${size?.Quantity} ${t("checkout.pieces-left")}.`;
            }
            sizeFound = true;
            break;
          }
          if(sizeFound) break;
        }
        break;
      }
      if(QtyError?.trim() !== "") break;
    }

    if(QtyError?.trim() !== "") {
      setErrorMessage(QtyError);
      setTimeout(() => {
        setErrorMessage("")
      }, 3000)
    }

    if (country != 231) {
      TotalBeforVAT = total;
      TotalAfterVAT = total;
    }

    const Address = addressesArr?.find(
      (address) => address?.id == selectedAddress
    );

    if (Object.keys(user)?.length > 0 && Address) {
      const newAddress = {
        IsDefault: true,
      };
      await updateAddress(newAddress, Address?.id, user?.id);
    }

    if (paymentMethod == "Cash On Delivery") {
      Status = "Open";
    } else {
      Status = "Draft";
    }

    let numOfPackages = 1;
    let weight = 0;
    const maxPackageWeight = parseFloat(
      settings?.find(
        (setting) =>
          setting?.attributes?.SettingName === "DHL Max Package Weight"
      )?.attributes?.SettingValue
    );

    let remainingProducts = products.map((product) => {
      const stateProduct = productsIds.find(
        (item) => item?.barcode === product?.barcode
      );
      return {
        ...product,
        remainingQty: stateProduct ? stateProduct.quantity : 0,
      };
    });

    while (remainingProducts.length > 0) {
      const currentPackage = {
        Number: generatePackageID(numOfPackages),
        Products: [],
        Weight: 0,
      };

      let addedToPackage = false;
      
      for (let i = 0; i < remainingProducts.length; ) {
        const product = remainingProducts[i];
        const productWeight = parseFloat(calculateWeight([product], false));
        const [color, size] = getColorAndSize(
          product?.product,
          product?.barcode,
          product?.selectedVariantId
        );
        const remainingWeight = maxPackageWeight - weight;

        if (remainingWeight >= productWeight && product.remainingQty > 0) {
          const unitsThatFit = Math.min(
            Math.floor(remainingWeight / productWeight) || 0,
            product.remainingQty
          );
          if (unitsThatFit > 0) {
            currentPackage.Products.push({
              ProductName: product?.product?.attributes?.Title,
              Quantity: unitsThatFit,
              Barcode: product?.barcode,
              Color: color,
              Size: size,
            });

            weight += unitsThatFit * productWeight;
            currentPackage.Weight = parseFloat(weight.toFixed(2));
            product.remainingQty -= unitsThatFit;
            addedToPackage = true;

            if (product.remainingQty === 0) {
              remainingProducts.splice(i, 1);
            } else {
              i++;
            }
          } else {
            i++;
          }
        } else {
          i++;
        }
      }

      if (!addedToPackage) {
        console.error(
          "Cannot fit any remaining products in the package. Check maxPackageWeight or product data."
        );
        break;
      }

      Packages.push(currentPackage);
      numOfPackages++;
      weight = 0;
    }

    setPackages(
      Packages?.map((pkg) => {
        return {
          PackageNumber: pkg?.Number,
          Weight: pkg?.Weight,
        };
      })
    );

    let City = citiesArr?.find((cityObj) => cityObj?.id == city)?.Name;
    let Country = countriesArr?.find(
      (countObj) => countObj?.id == country
    )?.Name;
    let Area = areasArr?.find((areaObj) => areaObj?.id == area)?.Name;

    if (checkout?.isPromoApplied) {
      DicountAmount = parseFloat(promoAmountToDeduct);
      if (checkout?.isVoucher) {
        VoucherCode = checkout?.promo?.attributes?.Code;
        dispatch(setVoucherAmount(DicountAmount));
        if (total > 0) {
          if (TotalBeforVAT < 0) TotalBeforVAT = `0`;
          if (TotalAfterVAT < 0) TotalAfterVAT = `0`;
        } else {
          TotalBeforVAT = `0`;
          TotalAfterVAT = `0`;
        }
      } else {
        CouponCode = checkout?.promo?.attributes?.Name;
      }
    }

    if (emailMe) {
      const result = await subscribeToNewsletter(registerEmail);
    }

    if (Object.keys(user)?.length === 0) {
      setPlaceOrderLoading(true);
      const { error, data } = await generateUser(
        registerEmail,
        firstName,
        lastName,
        phone
      );
      setPlaceOrderLoading(false);

      if (error) {
        navigate("/login?to=checkout");
        return;
      }

      dispatch(
        setUser({
          jwt: data?.jwt,
          user: data?.user,
        })
      );

      User = data?.user?.id;
      UserObject = data?.user;

      const newAddress = {
        User: data?.user?.id,
        FirstName: firstName,
        LastName: lastName,
        Address1: addressLine1,
        Address2: addressLine2,
        Postcode: postalCode,
        IsDefault: true,
        Country: country,
        City: city,
        Area: null,
      };

      if (area) newAddress.Area = area;
      if (mapLink?.trim() !== "") newAddress.MapLink = mapLink;
      if (displayName?.trim() !== "") newAddress.DisplayName = displayName;

      await addAddress(newAddress, data?.user?.id);
    }

    if (checkout?.isPromoApplied) {
      const isValid = await isPromoValid(
        UserObject,
        checkout?.promo,
        checkout?.isVoucher
      );
      if (!isValid) {
        dispatch(disableIsPromoApplied());
        dispatch(disableIsVoucher());
        dispatch(removePromo());
        VoucherCode = "";
        CouponCode = "";
        DicountAmount = 0;
      }
    }

    let productsArr = [];
    let totalProductsDiscounts = 0;
    let productsPricesBeforeDiscount = 0;
    let productsVATs = 0;

    products?.forEach((prod) => {
      const [color, size] = getColorAndSize(
        prod?.product,
        prod?.barcode,
        prod?.selectedVariantId
      );
      let prodPrice = prod?.product?.attributes?.Price;
      productsPricesBeforeDiscount += prodPrice * prod?.qty;
      let prodPriceWithoutVAT = parseFloat((prodPrice / 1.05).toFixed(2));
      let vatAmount = prodPrice - prodPriceWithoutVAT;
      productsVATs += vatAmount * prod?.qty;
      let discountAmount = 0;
      let grossAmount = prodPrice;
      if (prod?.product?.attributes?.discountedPrice) {
        discountAmount = prodPrice - prod?.product?.attributes?.discountedPrice;
        totalProductsDiscounts += discountAmount;
        grossAmount = prod?.product?.attributes?.discountedPrice;
      }
      productsArr.push({
        ProductName: prod?.product?.attributes?.Title,
        PriceBeforeVAT: country != 231 ? prodPrice : prodPriceWithoutVAT,
        PriceAfterVAT: prodPrice,
        Quantity: prod?.qty,
        Product: prod?.product?.id,
        Barcode: prod?.barcode,
        Size: size,
        Color: color,
        DiscountAmount: discountAmount,
        VAT: country != 231 ? "0.00" : parseFloat(vatAmount.toFixed(2)),
        GrossAmount: grossAmount * prod?.qty,
        Weight: parseFloat(calculateWeight([prod], false)),
        Image: prod?.product?.attributes?.DefaultImage?.data?.id,
      });
    });

    DicountAmount =
      parseFloat(DicountAmount) + parseFloat(totalProductsDiscounts);

    let totalsArr = [];

    if (country != 231) {
      productsVATs = 0;
    }

    totalsArr.push({
      TotalName: "Net Amount",
      TotalValue: (
        parseFloat(productsPricesBeforeDiscount) - parseFloat(productsVATs)
      ).toFixed(2),
    });
    totalsArr.push({
      TotalName: "VAT",
      TotalValue: parseFloat(productsVATs)?.toFixed(2),
    });
    totalsArr.push({
      TotalName: "Subtotal",
      TotalValue: parseFloat(productsPricesBeforeDiscount).toFixed(2),
    });
    totalsArr.push({
      TotalName: "Shipping",
      TotalValue: shippingValue.toFixed(2),
    });
    if (totalProductsDiscounts > 0) {
      totalsArr.push({
        TotalName: "Discount",
        TotalValue: totalProductsDiscounts.toFixed(2),
      });
    }
    if (hasFreeShipping) {
      totalsArr.push({
        TotalName: `Discount - Free Shipping`,
        TotalValue: shippingValue.toFixed(2),
      });
    }
    if (
      checkout?.isPromoApplied &&
      (await isPromoValid(UserObject, checkout?.promo, checkout?.isVoucher))
    ) {
      totalsArr.push({
        TotalName: `${checkout?.isVoucher ? "Voucher" : "Promo"} - ${
          checkout?.isVoucher
            ? checkout?.promo?.attributes?.Code
            : checkout?.promo?.attributes?.Name
        }`,
        TotalValue: promoAmountToDeduct,
      });
    }
    if (parseFloat(pointsTotal) > 0) {
      totalsArr.push({
        TotalName: `Redeemed Points`,
        TotalValue: parseFloat(pointsTotal).toFixed(2),
      });
    }
    totalsArr.push({
      TotalName: "Total",
      TotalValue: total,
    });

    let orderPoints =
      parseFloat(total) * parseFloat(redeemPointsPercentage / 100);

    if (pointsTotal > 0) {
      orderPoints -= parseFloat(pointsTotal);
    }
    dispatch(addPoints(orderPoints?.toFixed(2)));

    const placeOrderRequest = {
      User,
      Email,
      FirstName,
      LastName,
      Address1,
      Address2,
      PostalCode,
      City,
      Country,
      Area,
      PaymentMethod,
      ShippingMethod,
      PaymentResponse,
      TotalBeforVAT,
      TotalAfterVAT,
      Status,
      Phone,
      ShippingCost,
      IsGift,
      GiftMessage,
      Notes,
      SpecialRequest,
      VoucherCode,
      CouponCode,
      DicountAmount,
      Products: productsArr,
      Totals: totalsArr,
      Packages: Packages,
      AddressDisplayName,
      AddressMapLink,
    };

    setRequestData({
      data: placeOrderRequest,
    });
    setPlaceOrderRetries((prev) => prev + 1);
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    // Add suffix to the day (st, nd, rd, th)
    const daySuffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    return `${day}${daySuffix} of ${month}, ${year}`;
  };

  const prepareDHLShippingDataObject = () => {
    let Invoice = generateInvoiceNumber(PlaceOrderData?.data?.id);
    setInvoice(Invoice);
    const countryName = countriesArr?.find(
      (count) => count?.id == country
    )?.Name;
    const cityName = citiesArr?.find((cit) => cit?.id == city)?.Name;
    const length = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Length"
    )?.attributes?.SettingValue;
    const width = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Width"
    )?.attributes?.SettingValue;
    const height = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Height"
    )?.attributes?.SettingValue;

    const lineItems = [];
    let i = 1;
    for (const product of products) {
      const [color, size] = getColorAndSize(
        product?.product,
        product?.barcode,
        product?.selectedVariantId
      );

      let Quantity = product?.qty;

      const weight = calculateWeight([product], false);

      lineItems.push({
        number: i,
        description: `Color: ${color}, Size: ${size}`,
        price:
          product?.product?.attributes?.discountedPrice ??
          product?.product?.attributes?.Price,
        quantity: {
          value: Quantity,
          unitOfMeasurement: "PCS",
        },
        commodityCodes: [
          {
            typeCode: "outbound",
            value: "61044300",
          },
          {
            typeCode: "inbound",
            value: "61044300",
          },
        ],
        manufacturerCountry: "AE",
        weight: {
          netValue: weight,
          grossValue: weight,
        },
      });
    }

    const pkgs = [];
    const inv = Invoice;

    packages.forEach((pkg) => {
      pkgs.push({
        weight: pkg?.Weight,
        customerReferences: [
          {
            value: pkg?.PackageNumber,
            typeCode: "CU",
          },
          {
            value: `ORD_${PlaceOrderData?.data?.id}`,
            typeCode: "CO",
          },
        ],
        dimensions: {
          length: parseFloat(length),
          width: parseFloat(width),
          height: parseFloat(height),
        },
      });
    });

    const dataObject = {
      shippingMethod: shipping,
      countryName: countryName,
      cityName: cityName,
      postalCode: postalCode,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      customerName: `${firstName} ${lastName}`,
      customerEmail: registerEmail,
      customerPhone: phone,
      shippingProductCode: shippingProductCode,
      deliveryNotes: deliveryNotes,
      total: (parseFloat(subtotal) + parseFloat(vat)).toFixed(2),
      totalCurrency: "AED",
      description: `Order #${
        PlaceOrderData?.data?.id
      } - Ordered at ${formatDate(new Date())}`,
      lineItems: lineItems,
      invoiceNum: inv,
      pkgs: pkgs,
      orderID: PlaceOrderData?.data?.id,
    };

    return dataObject;
  };

  const prepareSMSAShippingDataObject = () => {
    const countryName = countriesArr?.find(
      (count) => count?.id == country
    )?.Name;
    const cityName = citiesArr?.find((cit) => cit?.id == city)?.Name;

    const pkgs = [];
    packages.forEach((pkg) => {
      pkgs.push({
        weight: pkg?.Weight,
      });
    });

    const selectedShippingCode = shippingProvidersArr?.find(
      (provider) => provider?.Name == shipping
    )?.ShippingCode;

    const dataObject = {
      shippingMethod: shipping,
      countryName: countryName,
      cityName: cityName,
      postalCode: postalCode,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      customerName: `${firstName} ${lastName}`,
      customerPhone: phone,
      selectedShippingCode: selectedShippingCode,
      total: (parseFloat(subtotal) + parseFloat(vat)).toFixed(2),
      totalCurrency: "AED",
      description: `Order #${
        PlaceOrderData?.data?.id
      } - Ordered at ${formatDate(new Date())}`,
      pkgs: pkgs,
      orderID: PlaceOrderData?.data?.id,
    };

    return dataObject;
  };

  const createOrderShipment = async (type, Invoice = "") => {
    if (type == "DHL") {
      let base64EncodedPDF = "";
      let base64EncodedInvoice = "";
      let trackingNumber = "";
      let fileID = 0;
      let fileUploadError = false;
      let invoiceID = 0;
      let invoiceUploadError = false;

      const shippingObject = prepareDHLShippingDataObject();

      [trackingNumber, base64EncodedPDF, base64EncodedInvoice] =
        await createShipment(
          shippingObject?.countryName,
          shippingObject?.cityName,
          shippingObject?.postalCode,
          shippingObject?.addressLine1,
          shippingObject?.addressLine2,
          shippingObject?.customerName,
          shippingObject?.customerEmail,
          shippingObject?.customerPhone,
          shippingObject?.shippingProductCode,
          shippingObject?.deliveryNotes,
          shippingObject?.total,
          shippingObject?.totalCurrency,
          shippingObject?.description,
          shippingObject?.lineItems,
          shippingObject?.invoiceNum,
          shippingObject?.pkgs,
          shippingObject?.orderID
        );

      if (base64EncodedPDF) {
        [fileID, fileUploadError] = await uploadBase64PdfToStrapi(
          base64EncodedPDF,
          `DHL shipment label for order #${PlaceOrderData?.data?.id}`
        );
      }
      if (base64EncodedInvoice) {
        [invoiceID, invoiceUploadError] = await uploadBase64PdfToStrapi(
          base64EncodedInvoice,
          `DHL invoice label for order #${PlaceOrderData?.data?.id}`
        );
      }
      return {
        base64EncodedPDF,
        base64EncodedInvoice,
        trackingNumber,
        fileID,
        fileUploadError,
        invoiceID,
        invoiceUploadError,
      };
    } else if (type?.includes("SMSA")) {
      let base64EncodedPDF = "";
      let trackingNumber = "";
      let fileID = 0;
      let fileUploadError = false;

      const shippingObject = prepareSMSAShippingDataObject();

      [trackingNumber, base64EncodedPDF] = await createSMSAShipment(
        shippingObject?.countryName,
        shippingObject?.cityName,
        shippingObject?.postalCode,
        shippingObject?.addressLine1,
        shippingObject?.addressLine2,
        shippingObject?.customerName,
        shippingObject?.customerPhone,
        shippingObject?.selectedShippingCode,
        shippingObject?.total,
        shippingObject?.totalCurrency,
        shippingObject?.description,
        shippingObject?.pkgs,
        shippingObject?.orderID
      );

      if (base64EncodedPDF) {
        [fileID, fileUploadError] = await uploadBase64PdfToStrapi(
          base64EncodedPDF,
          `SMSA shipment label for order #${PlaceOrderData?.data?.id}`
        );
      }
      return {
        base64EncodedPDF,
        trackingNumber,
        fileID,
        fileUploadError,
      };
    }

    return null;
  };

  const updatePaymentResponse = async (
    paymentResponse,
    paymentSuccess,
    stayInPage = false
  ) => {
    let paymentStatus = "";

    let trackingNumber = "";

    let base64EncodedPDF = "";
    let base64EncodedInvoice = "";
    let fileID = 0;
    let fileUploadError = false;
    let invoiceID = 0;
    let invoiceUploadError = false;

    let Invoice = generateInvoiceNumber(PlaceOrderData?.data?.id);
    setInvoice(Invoice);

    if (!paymentSuccess && stayInPage) {
      setNoRedirect(true);
    }

    if (Object.keys(paymentResponse).length === 0) {
      // cash on delivery
      paymentStatus = "Open";
    } else {
      paymentStatus = paymentSuccess ? "Paid" : "Payment Failed";
    }

    if (paymentStatus != "Payment Failed" && shipping == "DHL") {
      ({
        base64EncodedPDF,
        base64EncodedInvoice,
        trackingNumber,
        fileID,
        fileUploadError,
        invoiceID,
        invoiceUploadError,
      } = await createOrderShipment("DHL", Invoice));
    } else if (
      paymentStatus != "Payment Failed" &&
      shipping?.includes("SMSA")
    ) {
      ({ base64EncodedPDF, trackingNumber, fileID, fileUploadError } =
        await createOrderShipment("SMSA"));
    }

    // paid
    const newRequestData = {
      PaymentResponse: JSON.stringify(paymentResponse),
      Status: paymentStatus,
      InvoiceNumber: Invoice,
    };

    // add DHL props
    if (paymentStatus != "Payment Failed" && shipping == "DHL") {
      if (trackingNumber) newRequestData.DHLTrackingNumber = trackingNumber;
      if (fileID && !fileUploadError) newRequestData.DHLLabel = fileID;
      if (invoiceID && !invoiceUploadError)
        newRequestData.DHLInvoice = invoiceID;
    }

    // add SMSA props
    if (paymentStatus != "Payment Failed" && shipping?.includes("SMSA")) {
      if (trackingNumber) newRequestData.DHLTrackingNumber = trackingNumber;
      if (fileID && !fileUploadError) newRequestData.DHLLabel = fileID;
    }

    setUpdateOrderRequestData({ data: newRequestData });
    setUpdateOrderRetries((prev) => prev + 1);
  };

  const isFormValid = () => {
    const areaName = areasArr?.find((areaObj) => areaObj?.id == area)?.Name;
    const commonFields = [
      country,
      city,
      areaName?.trim() !== "",
      addressLine1.length > 10,
      shipping,
      paymentMethod,
      shippingValue > 0,
      postalCode,
    ];

    if (Object.keys(user).length > 0) {
      return commonFields.every(Boolean);
    }

    const additionalFields = [
      registerEmail,
      firstName,
      lastName,
      phone,
      isPhoneValid(phone),
    ];
    return [...commonFields, ...additionalFields].every(Boolean);
  };

  const updateShippingArray = async (products) => {
    const newShippingProviders = [];
    let shippingRate = 0;
    for (const shippingProvider of shippingProvidersData) {
      switch (country) {
        case 231:
          if (shippingProvider?.attributes?.Name != "DHL") {
            shippingRate = getShippingRate(
              shippingProvider?.attributes?.Name,
              shippingRatesArr,
              country,
              city,
              area,
              products
            );
            if (shippingRate != 0) {
              newShippingProviders.push({
                Name: shippingProvider?.attributes?.Name,
                DaysEstimate: shippingProvider?.attributes?.DeliveryTime,
                Value: shippingRate,
                ShippingCode: shippingProvider?.attributes?.Code,
              });
            }
          }
          break;
        default:
          if (shippingProvider?.attributes?.Name != "DHL") {
            const shippingRate = getShippingRate(
              shippingProvider?.attributes?.Name,
              shippingRatesArr,
              country,
              city,
              area,
              products
            );
            if (shippingRate != 0) {
              newShippingProviders.push({
                Name: shippingProvider?.attributes?.Name,
                DaysEstimate: shippingProvider?.attributes?.DeliveryTime,
                Value: shippingRate,
                ShippingCode: shippingProvider?.attributes?.Code,
              });
            }
          } else {
            const countryName = countriesArr?.find(
              (count) => count?.id == country
            )?.Name;
            const cityName = citiesArr?.find((cit) => cit?.id == city)?.Name;
            const weight = calculateWeight(products);
            let daysEstimate = "";
            let productCode = "";
            [shippingRate, daysEstimate, productCode] = await getDHLRates(
              weight,
              countryName,
              cityName,
              postalCode,
              settings
            );
            if (shippingRate > 0 && daysEstimate) {
              newShippingProviders.push({
                Name: shippingProvider?.attributes?.Name,
                DaysEstimate: daysEstimate,
                Value: Math.ceil(shippingRate),
              });
              setShippingProductCode(productCode);
            }
          }
          break;
      }
    }
    setShippingProvidersArr(newShippingProviders);
  };

  const updateAddressObject = ({
    DisplayName,
    Address1,
    Address2,
    PostCode,
    Country,
    City,
    City2,
    Area,
    Area2,
    MapLink,
  }) => {
    if (Object.keys(user)?.length > 0) return;

    const NewAddressViaMap = {
      Country: 0,
      CountryName: "",
      City: 0,
      CityName: "",
      CityName2: "",
      Area: 0,
      AreaName: "",
      AreaName2: "",
      AddressLine1: "",
      AddressLine2: "",
      PostalCode: "",
      FirstName: "",
      LastName: "",
      MapLink: "",
      DisplayName: "",
      SelectedPosition: [],
    };
    if (Address1?.trim() === "" && Address2?.trim() !== "") {
      NewAddressViaMap.AddressLine1 = Address2;
    } else if (Address1?.trim() !== "") {
      NewAddressViaMap.AddressLine1 = Address1;
      NewAddressViaMap.AddressLine2 = Address2;
    } else {
      NewAddressViaMap.AddressLine1 = Area;
      NewAddressViaMap.AddressLine2 = Address2;
    }
    NewAddressViaMap.PostalCode = PostCode?.trim() === "" ? "00000" : PostCode;
    const newCountry = countriesArr?.find(
      (country) => country?.Name === Country
    );
    if (newCountry) {
      NewAddressViaMap.Country = newCountry?.id;
      NewAddressViaMap.CountryName = newCountry?.Name;
    }
    NewAddressViaMap.CityName = City;
    NewAddressViaMap.CityName2 = City2;
    NewAddressViaMap.AreaName = Area;
    NewAddressViaMap.AreaName2 = Area2;
    NewAddressViaMap.MapLink = MapLink;
    NewAddressViaMap.DisplayName = DisplayName;
    const params = new URL(MapLink).searchParams;

    const lat = params.get("mlat");
    const lon = params.get("mlon");
    NewAddressViaMap.SelectedPosition = [lat, lon];
    setAddressViaMap(NewAddressViaMap);
  };

  const updateAddressFromMap = () => {
    try {
      // Only proceed if we have valid coordinates and data
      if (
        AddressViaMap.SelectedPosition?.length === 2 &&
        AddressViaMap.MapLink
      ) {
        setCountry(AddressViaMap.Country);
        setCityViaMap(AddressViaMap.CityName);
        setCity2ViaMap(AddressViaMap.CityName2);
        setIsCitySorted((prev) => !prev);
        setAreaViaMap(AddressViaMap.AreaName);
        setArea2ViaMap(AddressViaMap.AreaName2);
        setPostalCode(AddressViaMap.PostalCode);
        setMapLink(AddressViaMap.MapLink);
        setDisplayName(AddressViaMap.DisplayName);
        setSelectedPosition(AddressViaMap.SelectedPosition);
      }
    } catch (error) {
      console.error("Error updating address from map:", error);
      // Silently fail without showing errors to end user
      // Could also add error handling logic here if needed
    }
  };

  const submitPointsToRedeem = async (e) => {
    e.preventDefault();

    if (parseFloat(pointsToRedeem) > parseFloat(userPoints)) {
      setPointsErrorMessage(t("errors.not-enough-points"));
      setTimeout(() => {
        setPointsErrorMessage("");
      }, 3000);
      return;
    }

    if (parseFloat(pointsToRedeem) < parseFloat(minimumPoints)) {
      setPointsErrorMessage(t("errors.minimum-points") + " " + minimumPoints);
      setTimeout(() => {
        setPointsErrorMessage("");
      }, 3000);
      return;
    }

    setIsUsingPoints(true);
    const newTotal = parseFloat(total) + parseFloat(pointsTotal);
    setPointsTotal(
      pointsToRedeem >= newTotal
        ? parseFloat(newTotal)
        : parseFloat(pointsToRedeem)
    );
  };

  useEffect(() => {
    if (!promoLoading && promoData) {
      applyPromo(promoData, false);
      setIsPromoLoading(false);
    } else if (promoLoading && !promoError) {
      setIsPromoLoading(true);
    } else if (!promoLoading && promoError && !promoData) {
      setIsPromoLoading(false);
      setIsPromoError(true);
    }
  }, [promoLoading]);

  useEffect(() => {
    if (!voucherLoading && voucherData) {
      applyPromo(voucherData, true);
      setIsVoucherLoading(false);
    } else if (voucherLoading && !voucherError) {
      setIsVoucherLoading(true);
    } else if (!voucherLoading && voucherError && !voucherData) {
      setIsVoucherLoading(false);
      setIsVoucherError(true);
    }
  }, [voucherLoading]);

  useEffect(() => {
    if (!addressesLoading && addressesData) {
      const addressesArray = [];
      addressesData?.forEach((address) => {
        const newAddress = {
          id: address?.id,
          Country: address?.attributes?.Country?.data?.id,
          City: address?.attributes?.City?.data?.id,
          Area: address?.attributes?.Area?.data?.id,
          Address1: address?.attributes?.Address1,
          Address2: address?.attributes?.Address2,
          PostCode: address?.attributes?.Postcode,
          FirstName: address?.attributes?.FirstName,
          LastName: address?.attributes?.LastName,
          DisplayName: address?.attributes?.DisplayName,
          MapLink: address?.attributes?.MapLink,
        };

        let CountryName = address?.attributes?.Country?.data?.attributes?.Name;
        let CityName = address?.attributes?.City?.data?.attributes?.Name;
        let AreaName =
          address?.attributes?.Area?.data?.attributes?.Name ?? "All Areas";

        newAddress.CountryName = CountryName;
        newAddress.CityName = CityName;
        newAddress.AreaName = AreaName;

        const name = [
          CountryName,
          CityName,
          AreaName,
          newAddress.Address1,
          newAddress.PostCode,
        ];
        newAddress.Name = name?.join(", ");

        addressesArray.push(newAddress);
      });

      setAddressesArr(addressesArray);
      setSelectedAddress(addressesArray[0]?.id);
      setAreAddressesLoading(false);
    } else if (addressesLoading && !addressesError) {
      setAreAddressesLoading(true);
    } else if (!addressesLoading && addressesError && !addressesData) {
      setAreAddressesLoading(false);
      setAreAddressesError(true);
    }
  }, [addressesLoading]);

  useEffect(() => {
    const Address = addressesArr?.find(
      (address) => address?.id == selectedAddress
    );
    if (Address) {
      setFirstName(Address?.FirstName);
      setLastName(Address?.LastName);
      setCountry(Address?.Country);
      setSelectedCity(Address?.CityName);
      setSelectedArea(Address?.AreaName);
      setAddressLine1(Address?.Address1);
      setAddressLine2(Address?.Address2);
      setPostalCode(Address?.PostCode);
      if (Address?.MapLink) {
        const params = new URL(Address?.MapLink).searchParams;

        const lat = params.get("mlat");
        const lon = params.get("mlon");
        setSelectedPosition([lat, lon]);
        setDisplayName(Address?.DisplayName);
        setMapLink(Address?.MapLink);
      }
    }
  }, [selectedAddress]);

  useEffect(() => {
    const handlePlaceOrder = async () => {
      if (PlaceOrderLoading) {
        setPlaceOrderLoading(true);
        return;
      }
      setPlaceOrderLoading(false);

      if (PlaceOrderStatus !== 200 && placeOrderRetries > 0) {
        setPlaceOrderError(true);
        setCheckoutBtnDisabled(false);

        if (PlaceOrderStatus === 400) {
          setErrorMessage(PlaceOrderErrorMessage);
        } else {
          setErrorMessage(t("errors.unknown"));
        }
        return;
      }

      if (PlaceOrderData) {
        setPlaceOrderData(PlaceOrderData);
        setPlaceOrderError(false);
        setPlaceOrderLoading(false);
        dispatch(setOrderID(PlaceOrderData?.data?.id));
        await updateOrderWithInvoice(PlaceOrderData?.data?.id);
        handlePayment();
      }
    };
    handlePlaceOrder();
  }, [PlaceOrderLoading]);

  useEffect(() => {
    if (NetworkLoading) {
      return;
    }

    if (NetworkError !== "") {
      setErrorMessage(NetworkError);
      setTimeout(() => {
        setErrorMessage("");
        setCheckoutBtnDisabled(false);
      }, 5000);
      return;
    }

    if (NetworkPaymentLink) {
      let shippingDataObject = {};
      if (shipping == "DHL") {
        shippingDataObject = prepareDHLShippingDataObject();
      } else if (shipping?.includes("SMSA")) {
        shippingDataObject = prepareSMSAShippingDataObject();
      }
      dispatch(addShippingData(shippingDataObject));
      window.location.href = NetworkPaymentLink;
    }
  }, [NetworkLoading]);

  useEffect(() => {
    if (UpdateOrderLoading) {
      setUpdateOrderLoading(true);
      return;
    }
    setUpdateOrderLoading(false);

    if (UpdateOrderStatus !== 200 && updateOrderRetries > 0) {
      setUpdateOrderError(true);
      setCheckoutBtnDisabled(false);

      if (UpdateOrderStatus === 400) {
        setErrorMessage(UpdateOrderErrorMessage);
      } else {
        setErrorMessage(t("errors.unknown"));
      }
      retryUpdateOrder();
      return;
    }

    if (UpdateOrderData) {
      setUpdateOrderData(UpdateOrderData);
      setUpdateOrderError(false);
      setUpdateOrderLoading(false);
      if (!noRedirect) {
        navigate("/order-submitted");
      } else {
        setErrorMessage(t("errors.unknown"));
        setTimeout(() => {
          setCheckoutBtnDisabled(false);
          setErrorMessage("");
        }, 5000);
      }
    }
  }, [UpdateOrderLoading]);

  useEffect(() => {
    if (!productsLoading && productsData) {
      if (productsIds.length === 0) {
        setProducts([]);
      } else {
        const newProducts = processProducts();
        setProducts(newProducts);
      }
      setAreProductsLoading(false);
    } else if (productsLoading && !productsError) {
      setAreProductsLoading(true);
    } else if (!productsLoading && productsError && !productsData) {
      setAreProductsLoading(false);
      setAreProductsError(true);
    }
  }, [productsLoading]);

  useEffect(() => {
    const newProducts = processProducts();
    setProducts(newProducts);
  }, [productsIds]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      processTotals();
    }, 500);
    return () => clearTimeout(debounceTimeout);
  }, [products, checkout?.promo, pointsTotal, shippingValue, hasFreeShipping]);

  useEffect(() => {
    if (!zonesLoading && !zonesError && zonesData) {
      setCountriesArr(
        zonesData.countries.map((country) => {
          return {
            Name: country?.attributes?.Name,
            id: country?.id,
          };
        })
      );
      setCountry(231);
    }
  }, [zonesLoading, zonesError]);

  useEffect(() => {
    if (!paymentMethodsLoading && !paymentMethodsError && paymentMethodsData) {
      const newPaymentMethods = [];
      paymentMethodsData.forEach((paymentMethod) => {
        if (paymentMethod?.attributes?.Countries?.data?.length > 0) {
          for (const paymentCountry of paymentMethod?.attributes?.Countries
            ?.data) {
            if (country == paymentCountry?.id) {
              newPaymentMethods.push({
                Name: paymentMethod?.attributes?.Name,
              });
            }
          }
        } else {
          newPaymentMethods.push({
            Name: paymentMethod?.attributes?.Name,
          });
        }
      });
      setPaymentMethodsArr(newPaymentMethods);
    }
  }, [paymentMethodsLoading, paymentMethodsError, country]);

  useEffect(() => {
    if (!shippingRatesLoading && !shippingRatesError && shippingRatesData) {
      setShippingRatesArr(shippingRatesData);
    }
  }, [shippingRatesLoading, shippingRatesError]);

  useEffect(() => {
    if (!siteSettingsLoading && !siteSettingsError && siteSettingsData) {
      setSettings(siteSettingsData);

      const pointsRedeemPercentageSettingsValue = parseFloat(
        siteSettingsData?.find(
          (setting) => setting?.attributes?.SettingName === "Redeem Percentage "
        )?.attributes?.SettingValue
      );
      const minimumPointsSettingsValue = parseFloat(
        siteSettingsData?.find(
          (setting) =>
            setting?.attributes?.SettingName === "Redeem Points Threshold"
        )?.attributes?.SettingValue
      );
      const canRedeemPointsSettingsValue = siteSettingsData?.find(
        (setting) => setting?.attributes?.SettingName === "Redeem Status"
      )?.attributes?.Status;

      setRedeemPointsPercentage(pointsRedeemPercentageSettingsValue);
      setMinimumPoints(minimumPointsSettingsValue);
      setCanRedeem(canRedeemPointsSettingsValue);
    }
  }, [siteSettingsLoading, siteSettingsError]);

  useEffect(() => {
    const getPoints = async () => {
      const points = await getUserPoints(user?.id);
      setUserPoints(points);
    };

    if (Object.keys(user)?.length > 0 && canRedeem) {
      getPoints();
    }
  }, [canRedeem, user]);

  useEffect(() => {
    if (!offersLoading && !offersError && offersData) {
      setOffers(offersData);
    }
  }, [offersLoading, offersError]);

  useEffect(() => {
    if (
      !productsLoading &&
      !productsError &&
      products?.length > 0 &&
      !offersLoading &&
      !offersError &&
      offers.length > 0
    ) {
      const newProducts = processProducts();
      setProducts(newProducts);
    }
  }, [offers, productsIds]);

  useEffect(() => {
    const isFreeShipping = calculateFreeShipping(
      products,
      discountRules,
      subtotal,
      shipping,
      country
    );
    setHasFreeShipping(isFreeShipping);
  }, [country, shipping, subtotal, products]);

  useEffect(() => {
    if (isPhoneValid(phone) && phone?.trim() !== "") {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  }, [phone]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (
        shippingRatesArr?.length != 0 &&
        !shippingProvidersLoading &&
        !shippingProvidersError &&
        shippingProvidersData &&
        !CityLoading &&
        CityData &&
        !AreaLoading &&
        AreaData
      ) {
        if (country && city) {
          const newProducts = processProducts();
          setProducts(newProducts);
          setShipping("");
          updateShippingArray(newProducts);
          setPostalCode((prev) =>
            prev?.trim() === "" && country === 231 ? "00000" : prev
          );
        }
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [
    city,
    CityData,
    area,
    AreaData,
    productsIds,
    areProductsLoading,
    shippingProvidersLoading,
    shippingProvidersError,
  ]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (
        shippingRatesArr?.length != 0 &&
        !shippingProvidersLoading &&
        !shippingProvidersError &&
        shippingProvidersData &&
        !CityLoading &&
        CityData &&
        !AreaLoading &&
        AreaData
      ) {
        if (country && city && country != 231) {
          setShipping("");
          updateShippingArray(products);
        }
      }
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [postalCode, addressLine1]);

  useEffect(() => {
    let shippingRate = 0;
    setShippingValue(0);
    const newShippingProvider = shippingProvidersArr?.find(
      (provider) => provider?.Name == shipping
    );
    if (newShippingProvider) {
      shippingRate = newShippingProvider?.Value;
    }
    setShippingValue(shippingRate);
  }, [shipping, country, city, area, products]);

  useEffect(() => {
    if (!CityLoading && !CityError && CityData) {
      const newCitiesArr = [
        {
          Name: "Please Select",
          id: "",
        },
      ];
      if (CityData?.cities) {
        for (const newCity of CityData?.cities) {
          if (newCity?.attributes?.Country?.data?.id == country) {
            newCitiesArr.push({
              Name: newCity?.attributes?.Name,
              id: newCity?.id,
            });
          }
        }
      }

      if (cityViaMap?.trim() !== "" && newCitiesArr?.length > 0) {
        newCitiesArr?.sort((a, b) => {
          if (cityViaMap?.includes(a?.Name)) return -1;
          else if (cityViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (
        city2ViaMap?.trim() !== "" &&
        !cityViaMap?.includes(newCitiesArr[0]?.Name) &&
        newCitiesArr?.length > 0
      ) {
        newCitiesArr?.sort((a, b) => {
          if (city2ViaMap?.includes(a?.Name)) return -1;
          else if (city2ViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (selectedCity?.trim() !== "") {
        newCitiesArr?.sort((a, b) => {
          if (selectedCity?.includes(a?.Name)) return -1;
          else if (selectedCity?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (newCitiesArr.length === 0) {
        setCitiesArr([]);
        setCity(0);
      } else {
        setCitiesArr(newCitiesArr);
        setCity(newCitiesArr[0]?.id);
      }

      if (
        cityViaMap?.trim() !== "" &&
        city2ViaMap?.trim() !== "" &&
        newCitiesArr?.length > 0
      ) {
        setCityViaMap("");
        setCity2ViaMap("");
        setIsAreaSorted((prev) => !prev);
      }
    }
  }, [country, CityData, CityLoading, CityError, isCitySorted, selectedCity]);

  useEffect(() => {
    if (!AreaLoading && AreaData) {
      const newAreasArr = [];
      if (AreaData?.areas) {
        for (const newArea of AreaData?.areas) {
          if (newArea?.attributes?.City?.data?.id == city) {
            newAreasArr.push({
              Name: newArea?.attributes?.Name,
              id: newArea?.id,
            });
          }
        }
      }

      if (areaViaMap?.trim() !== "") {
        newAreasArr?.sort((a, b) => {
          if (areaViaMap?.includes(a?.Name)) return -1;
          else if (areaViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (
        area2ViaMap?.trim() !== "" &&
        !areaViaMap?.includes(newAreasArr[0]?.Name)
      ) {
        newAreasArr?.sort((a, b) => {
          if (area2ViaMap?.includes(a?.Name)) return -1;
          else if (area2ViaMap?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (selectedArea?.trim() !== "") {
        newAreasArr?.sort((a, b) => {
          if (selectedArea?.includes(a?.Name)) return -1;
          else if (selectedArea?.includes(b?.Name)) return 1;
          else return 0;
        });
      }

      if (
        !areaViaMap?.includes(newAreasArr[0]?.Name) &&
        !area2ViaMap?.includes(newAreasArr[0]?.Name) &&
        !selectedArea?.includes(newAreasArr[0]?.Name)
      ) {
        newAreasArr?.sort((a, b) => {
          if (a?.Name == "All Areas") return -1;
          else if (b?.Name == "All Areas") return 1;
          else return 0;
        });
      }

      if (newAreasArr.length === 0) {
        setAreasArr([]);
        setArea(0);
      } else {
        setAreasArr(newAreasArr);
        setArea(newAreasArr[0]?.id);
      }

      if (
        areaViaMap?.trim() !== "" &&
        area2ViaMap?.trim() !== "" &&
        newAreasArr?.length > 0
      ) {
        setAreaViaMap("");
        setArea2ViaMap("");
      }
    }
  }, [city, AreaData, AreaLoading, AreaError, isAreaSorted, selectedArea]);

  useEffect(() => {
    if (productsIds.length === 0) {
      navigate("/");
    }
  }, [productsIds]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get("error");
    if (errorParam) {
      setErrorMessage(errorParam);
      if (placeOrderBtn.current) {
        placeOrderBtn.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
    }
  }, []);

  useEffect(() => {
    // Only proceed if we have valid coordinates and data
    if (
      AddressViaMap.SelectedPosition?.length === 2 &&
      AddressViaMap.MapLink &&
      !Object.keys(user).length > 0 // Don't auto-update for logged in users
    ) {
      updateAddressFromMap();
    }
  }, [AddressViaMap]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setFirstName(user?.FirstName);
      setLastName(user?.LastName);
      setPhone(user?.PhoneNumber);
      setRegisterEmail(user?.email);
    }
  }, []);

  useEffect(() => {
    getSiteSettings();
    getOffers();
  }, []);

  return (
    <section className="checkout">
      <LoaderOverlay
        loadingState={placeOrderLoading}
        errorState={placeOrderError}
        setErrorState={setPlaceOrderError}
        retryFunc={retryPlaceOrder}
      />
      {!placeOrderLoading && !placeOrderError && (
        <LoaderOverlay
          loadingState={siteSettingsLoading}
          errorState={siteSettingsError}
          setErrorState={siteSettingsSetError}
          retryFunc={siteSettingsRetry}
        />
      )}
      {!placeOrderLoading &&
        !placeOrderError &&
        !siteSettingsLoading &&
        !siteSettingsError && (
          <LoaderOverlay
            loadingState={offersLoading}
            errorState={offersError}
            setErrorState={offersSetError}
            retryFunc={offersRetry}
          />
        )}
      {!foloosiReferenceTokenLoading &&
        !foloosiReferenceTokenError &&
        foloosiReferenceToken && (
          <FoloosiPayment
            referenceToken={foloosiReferenceToken}
            merchantKey={process.env.REACT_APP_FOLOOSI_MERCHANT_KEY}
            redirect={false} // Set to true if hosting payment is needed
            open={true}
            updatePaymentResponse={updatePaymentResponse}
          />
        )}
      <div className="checkout-banner">
        {offerTip.trim() !== "" && (
          <h2 className="offer-tip-message">{offerTip}</h2>
        )}
      </div>
      <div className="checkout-container">
        <div className="checkout-info">
          <div className="checkout-info-container">
            <h1 className="checkout-title">{t("checkout.checkout")}</h1>

            {Object.keys(user).length === 0 && (
              <>
                <h3 className="checkout-login">
                  {t("checkout.have-an-account")}{" "}
                  <Link to="/login?to=checkout" className="checkout-login-link">
                    {t("checkout.login")}
                  </Link>
                </h3>

                <div className="checkout-info-new-login-container">
                  <InputWithInnerLabel
                    stateValue={registerEmail}
                    setStateValue={setRegisterEmail}
                    type={"email"}
                    labelText={t("checkout.new-email") + "*"}
                    idText={"email"}
                    redBorder={true}
                  />
                </div>

                <div className="checkout-info-email-me">
                  <input
                    type="checkbox"
                    id="checkout-info-email-me-input"
                    checked={emailMe}
                    value={emailMe}
                    onChange={(e) => setEmailMe(e.target.checked)}
                  />
                  <label
                    htmlFor="checkout-info-email-me-input"
                    className="checkout-info-email-label"
                  >
                    {t("checkout.email-me")}
                  </label>
                </div>
              </>
            )}

            {Object.keys(user).length > 0 && (
              <>
                <h3 className="checkout-login">
                  {t("checkout.choose")}{" "}
                  <Link
                    to="/address-book?to=checkout"
                    className="checkout-login-link"
                  >
                    {t("checkout.or-add-address")}
                  </Link>
                </h3>

                <div className="address-selector">
                  {addressesArr?.length > 0 ? (
                    <SelectWithInnerLabel
                      stateValue={selectedAddress}
                      setStateValue={setSelectedAddress}
                      labelText={t("input.choose-address") + "*"}
                      idText={"address-selector"}
                      options={addressesArr}
                      redBorder={true}
                    />
                  ) : (
                    <h3 className="checkout-login">
                      {t("address-book.you-have-no-addresses")}
                    </h3>
                  )}
                </div>
              </>
            )}

            <div className="checkout-info-personal-information">
              <LoaderOverlay
                loadingState={zonesLoading}
                errorState={zonesError}
                setErrorState={setZonesError}
                retryFunc={zonesRetryFunc}
              />
              <div className="checkout-info-personal-information-title-container">
                <h3 className="checkout-info-personal-information-title">
                  {t("checkout.delivery-address")}
                </h3>
                {Object.keys(user)?.length === 0 &&
                  countriesArr.length > 0 &&
                  !turnMapOff && (
                    <h1
                      className="address-book-map-text"
                      onClick={() => updateAddressFromMap()}
                    >
                      <MapPin /> {t("address-book.detect-via-map")}
                    </h1>
                  )}
              </div>
              {countriesArr.length > 0 && !addressesLoading && (
                <Map
                  updateAddress={updateAddressObject}
                  loadMap={true}
                  selectedPosition={selectedPosition}
                  DisplayName={displayName}
                  userLoggedIn={Object.keys(user)?.length > 0}
                  setTurnMapOff={setTurnMapOff}
                ></Map>
              )}
              {Object.keys(user).length === 0 && (
                <div className="checkout-info-two-fields">
                  <InputWithInnerLabel
                    stateValue={firstName}
                    setStateValue={setFirstName}
                    labelText={t("input.first-name") + "*"}
                    idText={"first-name"}
                    redBorder={true}
                  />
                  <InputWithInnerLabel
                    stateValue={lastName}
                    setStateValue={setLastName}
                    labelText={t("input.last-name") + "*"}
                    idText={"last-name"}
                    redBorder={true}
                  />
                </div>
              )}
              <div className="" style={{ marginTop: "0.5rem" }}></div>
              <SelectWithInnerLabel
                stateValue={country}
                setStateValue={setCountry}
                labelText={t("input.country") + "*"}
                idText={"country-selector"}
                options={countriesArr}
                redBorder={true}
                disabled={Object.keys(user).length > 0}
              />

              <div className="checkout-info-two-fields">
                <SelectWithInnerLabel
                  stateValue={city}
                  setStateValue={setCity}
                  labelText={t("input.city") + "*"}
                  idText={"city-selector"}
                  options={citiesArr}
                  redBorder={true}
                  disabled={Object.keys(user).length > 0}
                />
                <SelectWithInnerLabel
                  stateValue={area}
                  setStateValue={setArea}
                  labelText={t("input.area") + "*"}
                  idText={"area-selector"}
                  options={areasArr}
                  redBorder={true}
                  disabled={Object.keys(user).length > 0}
                />
              </div>

              <InputWithInnerLabel
                stateValue={addressLine1}
                setStateValue={setAddressLine1}
                labelText={t("order.address-line-1") + "*"}
                idText={"address-details-one"}
                redBorder={true}
                disabled={Object.keys(user).length > 0}
                minLength={10}
              />
              <h6 className="example-text">
                {t("checkout.address-book-example")}
              </h6>
              <br />
              <InputWithInnerLabel
                stateValue={addressLine2}
                setStateValue={setAddressLine2}
                labelText={t("order.address-line-2")}
                idText={"address-details-two"}
                disabled={Object.keys(user).length > 0}
              />
              <InputWithInnerLabel
                stateValue={postalCode}
                setStateValue={setPostalCode}
                labelText={t("input.postal-code") + "*"}
                idText={"postal-code"}
                redBorder={true}
                disabled={Object.keys(user).length > 0}
              />
              {Object.keys(user).length === 0 && (
                <InputWithInnerLabel
                  stateValue={phone}
                  setStateValue={setPhone}
                  type={"tel"}
                  labelText={t("input.phone") + "*"}
                  idText={"phone"}
                  phoneInput={true}
                  updateCountryFromPhoneNumber={updateCountryFromPhoneNumber}
                  redBorder={!isPhoneNumberValid}
                />
              )}
            </div>

            <div className="checkout-info-shipping">
              <LoaderOverlay
                loadingState={shippingProvidersLoading}
                errorState={shippingProvidersError}
                setErrorState={setShippingProvidersError}
                retryFunc={shippingProvidersRetryFunc}
              />
              {!shippingProvidersLoading &&
                !shippingProvidersError &&
                dhlLoading && (
                  <LoaderOverlay
                    loadingState={dhlLoading}
                    errorState={dhlError}
                    setErrorState={setDhlError}
                    retryFunc={retryDHL}
                  />
                )}
              <h3 className="checkout-info-shipping-title">
                {t("checkout.shipping")}
              </h3>
              <RadioBoxCollection
                stateValue={shipping}
                setStateValue={setShipping}
                radioOptions={shippingProvidersArr}
                emptyMessage={t("checkout.add-your-full-address")}
              />
              {shippingProvidersArr?.length > 0 && (
                <h4 class="checkout-info-shipping-subtitle">
                  {t("checkout.customs-note")}
                </h4>
              )}
            </div>

            <div className="checkout-delivery-notes">
              <h3 className="checkout-delivery-notes-title">
                {t("order.delivery-notes")}
              </h3>

              <InputWithInnerLabel
                stateValue={deliveryNotes}
                setStateValue={setDeliveryNotes}
                labelText={t("checkout.example-delivery-notes")}
                idText={"delivery-notes"}
              />
            </div>

            {/*
            <div className="checkout-special-requests">
              <h3 className="checkout-special-requests-title">
                {t("checkout.tailoring-special-requests")}
              </h3>

              <InputWithInnerLabel
                stateValue={specialRequests}
                setStateValue={setSpecialRequests}
                labelText={t("checkout.example-special-requests")}
                idText={"special-requests"}
              />
            </div>
            */}

            <div className="checkout-gift-container">
              <div className="show-gift-container">
                <input
                  type="checkbox"
                  id="checkout-info-gift-input"
                  value={showGift}
                  onChange={(e) => {
                    setShowGift(e.target.checked);
                  }}
                />
                <label
                  htmlFor="checkout-info-gift-input"
                  className="checkout-info-gift-label"
                >
                  <span className="show-gift-label">
                    {t("checkout.this-order-is-a-gift")}!
                  </span>
                  <span className="show-git-message">
                    {t("checkout.prints-gift-receipt-on-delivery")}
                  </span>
                </label>
              </div>
              {showGift ? (
                <div className="gift-message-container">
                  <InputWithInnerLabel
                    stateValue={giftMessage}
                    setStateValue={setGiftMessage}
                    labelText={t("checkout.gift-placeholder")}
                    idText={"gift-message"}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {parseFloat(total) !== 0 && (
              <div className="checkout-info-payment">
                <LoaderOverlay
                  loadingState={paymentMethodsLoading}
                  errorState={paymentMethodsError}
                  setErrorState={setPaymentMethodsError}
                  retryFunc={paymentMethodsRetryFunc}
                />
                <h3 className="checkout-info-payment-title">
                  {t("checkout.payment")}
                </h3>
                <h4 className="checkout-info-payment-subtitle">
                  {t("checkout.payment-subtitle")}
                </h4>
                <RadioBoxCollection
                  stateValue={paymentMethod}
                  setStateValue={setPaymentMethod}
                  radioOptions={paymentMethodsArr}
                />
              </div>
            )}
          </div>
        </div>
        <div className="checkout-products-container">
          <LoaderOverlay
            loadingState={areProductsLoading}
            errorState={areProductsError}
            setErrorState={setAreProductsError}
            retryFunc={retryProduct}
          />

          <div className="checkout-products">
            {products?.map((product, index) => {
              return (
                <CartCard key={index} product={product} inCheckout={true} />
              );
            })}
          </div>

          <form className="promo" onSubmit={submitPromo}>
            <div className="promo-form-container">
              <input
                className="promo-input"
                type="text"
                placeholder={t("home.voucher-or-promo-code")}
                value={promoValue}
                onChange={(e) => setPromoValue(e.target.value)}
              />
              <button className="promo-btn" type="submit">
                {t("home.apply")}
              </button>
            </div>
            {showPromoMessage && (
              <h3 className="promo-error">{promoMessage}</h3>
            )}
          </form>

          {Object.keys(user).length > 0 &&
            canRedeem &&
            userPoints > 0 &&
            userPoints > minimumPoints && (
              <form className="redeem-points" onSubmit={submitPointsToRedeem}>
                <h3 className="redeem-points-title">
                  {t("checkout.you-have")} {userPoints}{" "}
                  {t("checkout.redeem-message")}
                </h3>
                <div className="redeem-points-form-container">
                  <input
                    className="redeem-points-input"
                    type="number"
                    placeholder={t("checkout.enter-points-to-redeem")}
                    value={pointsToRedeem}
                    step={0.01}
                    onChange={(e) => setPointsToRedeem(e.target.value)}
                    min={minimumPoints}
                  />
                  <button className="redeem-points-btn" type="submit">
                    {t("checkout.redeem")}
                  </button>
                </div>
                {pointsErrorMessage?.trim() !== "" && (
                  <h3 className="redeem-points-error">{pointsErrorMessage}</h3>
                )}
              </form>
            )}

          <div className="totals">
            {!areProductsLoading && (
              <LoaderOverlay
                loadingState={isPromoLoading}
                errorState={isPromoError}
                setErrorState={setIsPromoError}
                retryFunc={retryPromo}
              />
            )}
            {!areProductsLoading && !isPromoLoading && (
              <LoaderOverlay
                loadingState={isVoucherLoading}
                errorState={isVoucherError}
                setErrorState={setIsVoucherError}
                retryFunc={retryVouchers}
              />
            )}
            <div className="subtotal">
              <span>
                {t("home.subtotal")} . {products?.length} {t("home.items")}
              </span>
              <span>
                {country != 231
                  ? (parseFloat(subtotal) + parseFloat(vat)).toFixed(2)
                  : subtotal}{" "}
                {t("home.aed")}
              </span>
            </div>
            <div className="shipping">
              <span>{t("checkout.shipping")}</span>
              {shippingValue == 0 ? (
                <>
                  <span className="no-shipping-available">
                    {t("checkout.no-shipping-options")}
                  </span>
                </>
              ) : (
                <>
                  <span>
                    {shippingValue.toFixed(2)} {t("home.aed")}
                  </span>
                </>
              )}
            </div>
            {country == 231 && (
              <div className="vat">
                <span>{t("home.vat")}</span>
                <span>
                  {vat} {t("home.aed")}
                </span>
              </div>
            )}
            {checkout?.isPromoApplied && (
              <div className="promo-total">
                <span>
                  {checkout?.promo?.attributes?.Name ??
                    checkout?.promo?.attributes?.Code}
                </span>
                <span>
                  - {parseFloat(promoAmountToDeduct).toFixed(2)} {t("home.aed")}
                </span>
              </div>
            )}
            {hasFreeShipping && (
              <div className="promo-total">
                <span>
                  {t("checkout.discount")} - {t("checkout.free-shipping")}
                </span>
                <span>
                  - {shippingValue.toFixed(2)} {t("home.aed")}
                </span>
              </div>
            )}
            {isUsingPoints && pointsTotal > 0 && (
              <div className="promo-total">
                <span>{t("checkout.redeem-total-label")}</span>
                <span>
                  - {pointsTotal.toFixed(2)} {t("home.aed")}
                </span>
              </div>
            )}
            <div className="total">
              <span>{t("home.total")}</span>
              <span>
                {total} {t("home.aed")}
              </span>
            </div>
          </div>

          <div className="place-order-btn-container">
            {errorMessage.trim() !== "" && (
              <h2 className="login-error">{errorMessage}</h2>
            )}
            <button
              className="place-order-btn"
              ref={placeOrderBtn}
              onClick={() => {
                setCheckoutBtnDisabled(true);
                submitOrder();
              }}
              disabled={
                !isFormValid() ||
                checkoutBtnDisabled ||
                foloosiReferenceTokenLoading ||
                placeOrderLoading ||
                updateOrderLoading ||
                NetworkLoading ||
                (!NetworkLoading && NetworkPaymentLink && !NetworkError) ||
                dhlLoading ||
                errorMessage.trim() !== ""
              }
            >
              {!isFormValid() ? (
                t("checkout.kindly-fill-all-fields")
              ) : (
                <>
                  {!checkoutBtnDisabled &&
                  !foloosiReferenceTokenLoading &&
                  !placeOrderLoading &&
                  !updateOrderLoading &&
                  !NetworkLoading &&
                  !dhlLoading
                    ? t("checkout.place-order")
                    : t("home.loading")}
                </>
              )}
            </button>
            <h2 className="double-check">{t("checkout.double-check")}</h2>
            <div className="agree-to-policies">
              <h2 className="by-placing-order">
                {t("checkout.by-placing-order")}{" "}
                <Link to="/page/payment-and-shipping">
                  {t("checkout.payment-&-shipping")}
                </Link>{" "}
                |{" "}
                <Link to="/page/returns-and-exchanges">
                  {t("checkout.refund-&-return")}
                </Link>{" "}
                |{" "}
                <Link to="/page/shipping-and-delivery-policy">
                  {t("checkout.shipping-&-delivery")}
                </Link>{" "}
                {t("checkout.policies")}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
