import { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";
import { useExchangeRequest } from "../../hooks/useExchangeRequest";

import { uploadBlobsToStrapi } from "../../helpers/common";

import { useSelector } from "react-redux";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import "./ExchangeRequestForm.scss";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import TextArea from "../../components/TextArea/TextArea";
import InputWithInnerLabel from "../../components/InputWithInnerLabel/InputWithInnerLabel";

import { useTranslation } from "react-i18next";

const ExchangeRequestForm = () => {
  const id = useParams().id;

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const [exchange, setExchange] = useState([]);
  const [isExchangeLoading, setIsExchangeLoading] = useState(true);
  const [isExchangeError, setIsExchangeError] = useState(false);
  const [exchangeTries, setExchangeTries] = useState(0);

  const [productInfo, setProductInfo] = useState({});
  const [isProductLoading, setIsProductLoading] = useState(true);
  const [isProductError, setIsProductError] = useState(false);
  const [productTries, setProductTries] = useState(0);

  const [description, setDescription] = useState("");
  const [exchangeDescription, setExchangeDescription] = useState("");
  const [product, setProduct] = useState(searchParams.get("product"));
  const [barcode, setBarcode] = useState(searchParams.get("barcode"));
  const [orderID, setOrderID] = useState(searchParams.get("order"));
  const [mediaFiles, setMediaFiles] = useState([]);

  const [mediaUploadLoading, setMediaUploadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const retry = () => setTries((prev) => prev + 1);
  const retryExchange = () => setExchangeTries((prev) => prev + 1);
  const retryProduct = () => setProductTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "deep",
    locale: "en",
    try: tries,
  });
  const orderBaseUrl = `/orders/${orderID}`;
  const orderUrl = `${orderBaseUrl}?${Params.toString()}`;
  const {
    data: orderData,
    loading: orderLoading,
    error: orderError,
  } = useFetch(orderUrl);

  const exchangeParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    "filters[User][id][$eq]": user?.id,
    "filters[Product][id][$eq]": product,
    "filters[Barcode][$eq]": barcode,
    "filters[Order][id][$eq]": orderID,
    try: tries,
  });
  const exchangeBaseUrl = `/ecxhange-requests`;
  const exchangeUrl = `${exchangeBaseUrl}?${exchangeParams.toString()}`;
  const {
    data: exchangeData,
    loading: exchangeLoading,
    error: exchangeError,
  } = useFetch(exchangeUrl);

  const productParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    "filters[Status][$eq]": true,
    "filters[id][$eq]": product,
    try: productTries,
  });
  const productBaseUrl = `/products`;
  const productUrl = `${productBaseUrl}?${productParams.toString()}`;
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useFetch(productUrl);

  const {
    submitExchangeRequest,
    loading: submitExchangeRequestLoading,
    error: submitExchangeRequestError,
    setError: setSubmitExchangeRequestError,
    retry: submitExchangeRequestRetryFunc,
  } = useExchangeRequest();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setMediaUploadLoading(true);
    const [filesIDs, uploadError] = await uploadBlobsToStrapi(mediaFiles);

    if (uploadError) {
      setErrorMessage(t("errors.unknown"));
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      setMediaUploadLoading(false);
      return;
    }

    let productValue = 0;
    let productColorId = 0;
    let productSizeId = 0;

    const orderProduct = data?.attributes?.Products?.find(
      (prod) => prod?.Barcode === barcode
    );
    if (orderProduct) {
      productValue =
        parseFloat(orderProduct?.PriceAfterVAT) -
        parseFloat(orderProduct?.DiscountAmount / orderProduct?.Quantity);
    }

    for(const variant of productInfo?.attributes?.Variants) {
      let found = false;
      for(const size of variant?.Sizes) {
        if(size?.Barcode === barcode) {
          productColorId = variant?.Color?.data?.id;
          productSizeId = size?.Size?.data?.id;
          found = true;
          break;
        }
      }

      if(found) break;
    }

    const requestData = {
      User: user?.id,
      Description: description + ", exchange to: " + exchangeDescription,
      Images: filesIDs,
      Order: data?.id,
      Product: product,
      Barcode: barcode,
      ProductValue: parseFloat(productValue).toFixed(2),
    };

    if(productColorId) requestData.ProductColor = productColorId;
    if(productSizeId) requestData.ProductSize = productSizeId;

    const res = await submitExchangeRequest(requestData);
    setMediaUploadLoading(false);

    if (res) {
      setErrorMessage(t("exchange.exchange-request-sent"));
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      setErrorMessage(t("errors.unknown"));
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const isFormValid = () => {
    const fields = [
      description?.trim() !== "",
      mediaFiles?.length > 0,
      exchangeDescription?.trim() !== "",
    ];

    return fields.every(Boolean);
  };

  const handleMediaUpload = (event) => {
    const files = Array.from(event.target.files);

    const MAX_TOTAL_SIZE = 50 * 1024 * 1024; // 50MB max files size
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > MAX_TOTAL_SIZE) {
      alert(t("errors.total-file-size-exceeded"));
      return;
    }

    if (files.length > 5) {
      alert(t("errors.maximum-files"));
      return;
    }

    const mediaUrls = files.map((file) => URL.createObjectURL(file));
    setMediaFiles(mediaUrls);
  };

  const checkFormValidity = (orderData, doesExchangeExist) => {
    if (user?.id !== orderData?.attributes?.User?.data?.id) {
      navigate("/");
    }

    const prod = orderData?.attributes?.Products?.find(
      (p) => p?.Barcode == barcode && p?.Product?.data?.id == product
    );
    if (!prod) {
      setErrorMessage(t("exchange.product-not-exist"));
    }

    if (doesExchangeExist?.length > 0) {
      setErrorMessage(t("exchange.exists"));
    }
  };

  useEffect(() => {
    if (!orderLoading && orderData) {
      setData(orderData);
      setLoading(false);
    } else if (orderLoading && !orderError) {
      setLoading(true);
    } else if (!orderLoading && orderError && !orderData) {
      setLoading(false);
      setError(true);
    }
  }, [orderLoading]);

  useEffect(() => {
    if (!exchangeLoading && exchangeData) {
      setExchange(exchangeData);
      setIsExchangeLoading(false);
    } else if (exchangeLoading && !exchangeError) {
      setIsExchangeLoading(true);
    } else if (!exchangeLoading && exchangeError && !exchangeData) {
      setIsExchangeLoading(false);
      setIsExchangeError(true);
    }
  }, [exchangeLoading]);

  useEffect(() => {
    if (!productLoading && productData) {
      setProductInfo(productData[0]);
      setIsProductLoading(false);
    } else if (productLoading && !productError) {
      setIsProductLoading(true);
    } else if (!productLoading && productError && !productData) {
      setIsProductLoading(false);
      setIsProductError(true);
    }
  }, [productLoading]);

  useEffect(() => {
    if (!loading && !exchangeLoading) {
      checkFormValidity(data, exchange);
    }
  }, [data, exchange]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t(
      "exchange.submit-exchange-request"
    )} - #${id}`;
  }, []);

  return (
    <section className="exchange-request-form">
      <div className="exchange-request-container">
        <h1 className="exchange-request-title">
          {t("exchange.submit-exchange-request")}
        </h1>
        <form
          className="exchange-request-form-container"
          onSubmit={handleFormSubmit}
        >
          <LoaderOverlay
            loadingState={loading}
            errorState={error}
            setErrorState={setError}
            retryFunc={retry}
          />
          {!loading && (
            <LoaderOverlay
              loadingState={isExchangeLoading}
              errorState={isExchangeError}
              setErrorState={setIsExchangeError}
              retryFunc={retryExchange}
            />
          )}
          {!loading && !isExchangeLoading && (
            <LoaderOverlay
              loadingState={isProductLoading}
              errorState={isProductError}
              setErrorState={setIsProductError}
              retryFunc={retryProduct}
            />
          )}
          <TextArea
            stateValue={description}
            setStateValue={setDescription}
            labelText={t("input.description") + "*"}
            idText={"description"}
            redBorder={description?.trim() === ""}
          />
          <div className="flex-col">
            <h2 className="exchange-request-exchange-description">
              {t("exchange.exchange-product-title") + "*"}
            </h2>
            <InputWithInnerLabel
              stateValue={exchangeDescription}
              setStateValue={setExchangeDescription}
              type={"text"}
              labelText={t("exchange.exchange-product-description")}
              idText={"exchange-description"}
              redBorder={true}
            />
          </div>
          <div className="exchange-request-images-container">
            <h2 className="exchange-request-upload-title">
              {t("exchange.upload-images-videos")}
            </h2>
            <input
              type="file"
              accept="image/*, video/*"
              multiple
              onChange={handleMediaUpload}
            />
          </div>
          <div className="exchange-request-button">
            <h3 className="exchange-request-error-message">{errorMessage}</h3>
            <button
              className="exchange-request-form-button"
              type="submit"
              disabled={
                !isFormValid() ||
                errorMessage?.trim() != "" ||
                submitExchangeRequestLoading ||
                mediaUploadLoading ||
                loading ||
                isExchangeLoading ||
                isProductLoading
              }
            >
              {submitExchangeRequestLoading || mediaUploadLoading
                ? t("account.loading...")
                : t("exchange.send-request")}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ExchangeRequestForm;
