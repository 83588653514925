import React, { useEffect, useState, useRef } from "react";

import useFetch from "../../hooks/useFetch";
import { applyDiscountRules, capitalizeWords } from "../../helpers/common";
import { useFilters } from "../../hooks/useFilters";

import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { ReactComponent as Close } from "../../SVGs/Close.svg";

import useDeviceType from "../../hooks/useDeviceType";

import "./Category.scss";

import Card from "../../components/Card/Card";
import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import CircularProgress from "@mui/material/CircularProgress";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Category = ({ catType }) => {
  const slug = useParams().slug;
  const pageType = catType;
  const deviceType = useDeviceType();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const loadMoreRef = useRef(null);

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  const discountRules = useSelector((state) => state.discountRules);

  // Filters
  const {
    filters,
    areFiltersLoading,
    areFiltersError,
    setAreFiltersError,
    retryFilters,
  } = useFilters();
  const [selectedFilters, setSelectedFilters] = useState(new Map());
  const [expandedFilters, setExpandedFilters] = useState({});

  // Pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

  // Product
  const [productsList, setProductsList] = useState([]);
  const [productsMetaData, setProductsMetaData] = useState(null);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [isProductsError, setIsProductsError] = useState(false);
  const [productsRetries, setProductsRetries] = useState(0);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const [isNewPageLoading, setIsNewPageLoading] = useState(false);

  const [bestSellersIDs, setBestSellersIDs] = useState([]);
  const [bestSellersIDsLoading, setBestSellersIDsLoading] = useState(true);
  const [retryKeyBestSellersIDs, setRetryKeyBestSellersIDs] = useState(0);

  const toggleFiltersVisibility = () => {
    setIsFiltersVisible((prevState) => !prevState);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds a smooth scrolling effect
    });
  };
  const productsBaseUrl = "/products";
  const productsParams = new URLSearchParams({
    populate: "deep",
    "pagination[page]": page,
    "pagination[pageSize]": pageSize,
    "filters[Status][$eq]": true,
    sort: "CreationDate:desc",
    locale: "en",
    try: productsRetries,
  });

  // best seller IDs
  const bestSellersIDsBaseUrl = "/most-ordered-products";
  const bestSellersIDsParams = new URLSearchParams({
    try: retryKeyBestSellersIDs,
  });
  const bestSellersIDsUrl = `${bestSellersIDsBaseUrl}?${bestSellersIDsParams.toString()}`;
  const {
    data: BestSellersIDs,
    loading: BestSellersIDsLoading,
    error: BestSellersIDsError,
  } = useFetch(bestSellersIDsUrl);

  let filterKey = "";
  let secondFilterKey = "";
  if (pageType == "category") {
    filterKey = "filters[Categories][Title][$eq]";
  } else if (pageType == "tag") {
    filterKey = "filters[Tags][Name][$eq]";
  } else if (pageType == "search") {
    filterKey = "filters[$or][0][Title][$contains]";
    secondFilterKey = "filters[$or][1][localizations][Title][$contains]";
  }

  if (slug == "new") {
    productsParams.set("sort", "CreationDate:desc");
  } else if (slug == "best-sellers") {
    bestSellersIDs.forEach((id, index) => {
      productsParams.append(`filters[id][$in][${index}]`, id);
    });
  } else {
    productsParams.set(filterKey, slug);
    if (pageType == "search") {
      productsParams.set(secondFilterKey, slug);
    }
  }

  selectedFilters.forEach((ids, filter) => {
    let index = 0;
    switch (filter) {
      case "Vendor":
        ids.forEach((id) => {
          productsParams.append(`filters[vendor][id][$in][${index}]`, id);
          index++;
        });
        break;
      case "Color":
        ids.forEach((id) => {
          productsParams.append(
            `filters[Variants][Color][id][$in][${index}]`,
            id
          );
          index++;
        });
        break;
      case "Size":
        ids.forEach((id) => {
          productsParams.append(
            `filters[Variants][Sizes][Size][id][$in][${index}]`,
            id
          );
          index++;
        });
        break;
      case "Price":
        ids.forEach((id) => {
          productsParams.append(`filters[Price][$lt]`, id);
          index++;
        });
        break;
      default:
        break;
    }
  });

  const productsUrl = `${productsBaseUrl}?${productsParams.toString()}`;
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
    meta: productsMeta,
  } = useFetch(productsUrl, true);

  // Category
  const [categoryInfo, setCategoryInfo] = useState({});
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [isCategoryError, setIsCategoryError] = useState(false);
  const [categoryRetries, setCategoryRetries] = useState(0);

  const categoryBaseUrl = "/categories";
  const categoryParams = new URLSearchParams({
    populate: "*",
    locale: "en",
    "filters[Title][$eq]": slug,
    try: categoryRetries,
  });
  const categoryUrl = `${categoryBaseUrl}?${categoryParams.toString()}`;
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useFetch(categoryUrl);

  // Retry functions
  const retryProducts = () => setProductsRetries((prev) => prev + 1);
  const retryCategory = () => setCategoryRetries((prev) => prev + 1);
  const retryBestSellersIDs = () =>
    setRetryKeyBestSellersIDs((prev) => prev + 1);

  const toggleShowAll = (index) => {
    setExpandedFilters((prev) => {
      return {
        ...prev,
        [index]: !prev[index],
      };
    });
  };

  const handleFilterChange = (event) => {
    const [id, filter] = event.target.id.split("-");
    const isChecked = event.target.checked;

    setSelectedFilters((prev) => {
      const newFilters = new Map(prev);

      if (isChecked) {
        if (!newFilters.has(filter)) {
          newFilters.set(filter, new Set());
        }
        newFilters.get(filter).add(id);
      } else {
        if (newFilters.has(filter)) {
          newFilters.get(filter).delete(id);
          if (newFilters.get(filter).size === 0) {
            newFilters.delete(filter);
          }
        }
      }
      return newFilters;
    });

    setProductsList([]);
    setPage(1);
  };

  useEffect(() => {
    if (!BestSellersIDsLoading && BestSellersIDs && slug == "best-sellers") {
      setBestSellersIDs(BestSellersIDs);
      setBestSellersIDsLoading(false);
      retryProducts();
    } else if (BestSellersIDsLoading && !BestSellersIDsError) {
      setBestSellersIDsLoading(true);
    } else if (
      !BestSellersIDsLoading &&
      BestSellersIDsError &&
      !BestSellersIDs
    ) {
      retryBestSellersIDs();
    }
  }, [BestSellersIDsLoading]);

  useEffect(() => {
    if (!categoryLoading && categoryData) {
      setCategoryInfo(categoryData[0]);
      setIsCategoryLoading(false);
      if (pageType == "category" && slug != "new" && slug != "best-sellers") {
        setMetaTitle(categoryData[0]?.attributes?.SEO?.metaTitle);
        setMetaDescription(categoryData[0]?.attributes?.SEO?.metaDescription);
        setMetaKeywords(categoryData[0]?.attributes?.SEO?.keywords);
      }
    } else if (categoryLoading && !categoryError) {
      setIsCategoryLoading(true);
    } else if (!categoryLoading && categoryError && !categoryData) {
      setIsCategoryLoading(false);
      setIsCategoryError(true);
    }
  }, [categoryLoading]);

  useEffect(() => {
    setProductsList([]);
    setPage(1);
  }, [slug, pageType]);

  useEffect(() => {
    if (!productsLoading && productsData) {
      const discountedProductsData = applyDiscountRules(
        [...productsData],
        discountRules
      );
      setProductsList((prevProducts) =>
        page === 1
          ? [...discountedProductsData]
          : [...prevProducts, ...discountedProductsData]
      );
      setProductsMetaData(productsMeta);
      setIsProductsLoading(false);
      setIsNewPageLoading(false);
    } else if (productsLoading && !productsError) {
      setIsProductsLoading(true);
    } else if (!productsLoading && productsError && !productsData) {
      setIsProductsLoading(false);
      setIsProductsError(true);
    }
  }, [productsLoading]);

  useEffect(() => {
    if (!loadMoreRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
          setIsNewPageLoading(true);
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(loadMoreRef.current);

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [productsMetaData]);

  useEffect(() => {
    if (slug != "new" && slug != "best-sellers") {
      if (pageType == "category") {
        document.title = `${t("general.classy-ym")} - ${t(
          "category.category"
        )} ${capitalizeWords(slug)}`;
      } else if (pageType == "tag") {
        document.title = `${t("general.classy-ym")} - ${t(
          "category.tag"
        )} ${capitalizeWords(slug)}`;
      } else if (pageType == "search") {
        document.title = `${t("general.classy-ym")} - ${t(
          "category.search-for"
        )} ${capitalizeWords(slug)}`;
      }
    } else {
      if (slug == "new") {
        document.title = `${t("general.classy-ym")} - ${t("home.new-in")}`;
      } else if (slug == "best-sellers") {
        document.title = `${t("general.classy-ym")} - ${t(
          "home.best-sellers"
        )}`;
      }
    }
  }, [slug]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={metaDescription || t("home.default-description")}
        />
        <meta
          name="keywords"
          content={metaKeywords || t("home.default-keywords")}
        />
      </Helmet>
      <section className="category-page">
        <LoaderOverlay
          loadingState={isCategoryLoading}
          errorState={isCategoryError}
          setErrorState={setIsCategoryError}
          retryFunc={retryCategory}
        />
        {pageType == "category" &&
          slug != "new" &&
          slug != "best-sellers" &&
          categoryInfo?.attributes?.Image?.data?.attributes?.url && (
            <div
              className="banner"
              style={{
                background: `url(${
                  process.env.REACT_APP_IMAGE_URL +
                  categoryInfo?.attributes?.Image?.data?.attributes?.url
                }) no-repeat center center / cover`,
              }}
            ></div>
          )}
        <div className="category-content">
          <h1 className="category-title">
            {slug == "new" || slug == "best-sellers" ? (
              <>
                {slug == "new" && t("category.new")}
                {slug == "best-sellers" && t("home.best-sellers")}
              </>
            ) : (
              <>
                {pageType == "search" && t("category.search-results-for")}{" "}
                {pageType == "category"
                  ? categoryInfo?.attributes?.Title
                  : slug}
              </>
            )}
          </h1>
          <button
            className="filters-toggle-btn"
            onClick={toggleFiltersVisibility}
          >
            <p>{t("category.filters")}</p> <img src="/img/filter.svg" />
          </button>

          <div className="container">
            <div
              className={`filters ${
                deviceType == "mobile"
                  ? isFiltersVisible
                    ? "visible"
                    : "hidden"
                  : ""
              }`}
            >
              {areFiltersLoading && !isProductsLoading && !isCategoryLoading ? (
                <LoaderOverlay
                  loadingState={areFiltersLoading}
                  errorState={areFiltersError}
                  setErrorState={setAreFiltersError}
                  retryFunc={retryFilters}
                />
              ) : (
                <></>
              )}
              {filters.map((filter, index) => {
                const isExpanded = expandedFilters[index] || false;

                return (
                  <div className="filter-group" key={index}>
                    <h2 className="title">{filter.title}</h2>
                    <div className="values">
                      {(isExpanded
                        ? filter.values
                        : filter.values.slice(0, 5)
                      ).map((value) => {
                        return (
                          <div className="filter" key={value.id}>
                            <input
                              className="filter-checkbox"
                              type="checkbox"
                              name={value?.attributes?.Name}
                              id={`${value.id}-${filter.title}`}
                              onChange={handleFilterChange}
                            />
                            <label
                              className="filter-title"
                              htmlFor={`${value.id}-${filter.title}`}
                            >
                              {value?.attributes?.Name}
                            </label>
                          </div>
                        );
                      })}
                      {filter.values.length > 5 ? (
                        <div
                          className="show-more-filters"
                          onClick={() => toggleShowAll(index)}
                        >
                          {isExpanded
                            ? t("category.show-less")
                            : `${t("category.show-all")} (${
                                filter.values.length
                              })`}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                );
              })}
              <Close className="search-btn" onClick={toggleFiltersVisibility} />
            </div>
            <div className="products">
              {isProductsLoading && !areFiltersLoading && !isCategoryLoading && !isNewPageLoading ? (
                <LoaderOverlay
                  loadingState={isProductsLoading}
                  errorState={isProductsError}
                  setErrorState={setIsProductsError}
                  retryFunc={retryProducts}
                />
              ) : (
                <></>
              )}
              <div className="products-container">
                {productsList.length === 0 ? (
                  <span className="empty-list">
                    {t("home.no-products-available")}
                  </span>
                ) : (
                  <></>
                )}
                {productsList.map((product) => {
                  return (
                    <Card areDiscountRulesApplied={true} product={product} isCategory={true} />
                  );
                })}
              </div>
              {productsMetaData?.pagination?.page <
              productsMetaData?.pagination?.pageCount ? (
                <div className="show-more-products" ref={loadMoreRef}>
                  {isNewPageLoading && <CircularProgress />}
                  {/* {t("category.see-more")} */}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
