import React, { useState, useEffect } from "react";

import "./TextArea.scss";

const TextArea = ({
  stateValue,
  setStateValue,
  labelText,
  idText,
  redBorder = false,
}) => {
  return (
    <div className="text-area-container">
      <label className="account-label" htmlFor={`account-${idText}`}>
        {labelText}
      </label>
      <textarea
        className="text-area-input"
        rows={5}
        cols={40}
        id={`account-${idText}`}
        value={stateValue}
        style={{ border: `1px solid ${redBorder ? "red" : "#00000040"}` }}
        onChange={(e) => setStateValue(e.target.value)}
      ></textarea>
    </div>
  );
};

export default TextArea;
