import "./AddressCardEntry.scss";

const AddressCardEntry = ({ label, value }) => {
  return (
    <div className="address-card-entry">
      <h3 className="address-card-subtitle">{label}</h3>
      <span className="address-card-value">{value}</span>
    </div>
  );
};

export default AddressCardEntry;
