import React, { useState, useEffect } from "react";

import usePost from "../../hooks/usePost";

import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

import "./ForgotPassword.scss";

import InputWithFloatingLabel from "../../components/InputWithFloatingLabel/InputWithFloatingLabel";

import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [requestData, setRequestData] = useState({});
  const [forgotPasswordRetries, setForgotPasswordRetries] = useState(0);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState(false);

  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const ForgotPasswordUrl = "/auth/forgot-password";
  const {
    data: ForgotPasswordData,
    loading: ForgotPasswordLoading,
    error: ForgotPasswordError,
    status: ForgotPasswordStatus,
    errorMessage: ForgotPasswordErrorMessage,
  } = usePost(ForgotPasswordUrl, requestData);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (ForgotPasswordLoading) return;

    if (email.trim() === "") {
      setErrorMessage(t("errors.email-not-valid"));
      return;
    }

    setErrorMessage("");

    setRequestData({
      email: email,
    });
    setForgotPasswordRetries((prev) => prev + 1);
  };

  useEffect(() => {
    if (ForgotPasswordLoading) {
      setForgotPasswordLoading(true);
      return;
    }
    setForgotPasswordLoading(false);

    if (ForgotPasswordStatus !== 200 && forgotPasswordRetries > 0) {
      setForgotPasswordError(true);

      if (ForgotPasswordStatus === 400) {
        setErrorMessage(ForgotPasswordErrorMessage);
      } else {
        setErrorMessage(t("errors.unknown"));
      }
      return;
    }

    if (ForgotPasswordData) {
      if (ForgotPasswordData?.ok) {
        navigate("/reset-password");
      }
    }
  }, [ForgotPasswordLoading]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      navigate("/account");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t(
      "forget-password.forget-password"
    )}`;
  }, []);

  return (
    <section className="forgot-password">
      <div className="forgot-password-container">
        <h1 className="forgot-password-title">
          {t("forget-password.forget-password")}
        </h1>
        <form className="forgot-password-form" onSubmit={handleFormSubmit}>
          <InputWithFloatingLabel
            stateValue={email}
            setStateValue={setEmail}
            type={"email"}
            labelText={t("input.email")}
            idText={"email"}
          />
          {errorMessage.trim() !== "" && (
            <h2 className="forgot-password-error">{errorMessage}</h2>
          )}
          <button className="forgot-password-button" type="submit">
            {forgotPasswordLoading
              ? t("account.loading...")
              : t("forget-password.send-password-reset-email")}
          </button>
        </form>
        <div className="forgot-password-link-container">
          <Link className="forgot-password-link" to="/login">
            {t("forget-password.back-to-login")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
