import cartReducer from "./cartReducer";
import overlayReducer from "./overlayReducer";
import cartVisibilityReducer from "./cartVisibilityReducer";
import hamburgerVisibilityReducer from "./hamburgerVisibilityReducer";
import checkoutReducer from "./checkoutReducer";
import discountRulesReducer from "./discountRulesReducer";
import generalReducer from "./generalReduer"
import userReducer from "./userReducer"

import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistCartConfig = {
  key: "cart",
  version: 1,
  storage,
};
const persistCheckoutConfig = {
  key: "checkout",
  version: 1,
  storage,
};
const persistDiscountRulesConfig = {
  key: "discount",
  version: 1,
  storage,
};
const persistUserConfig = {
  key: "user",
  version: 1,
  storage,
};
const persistGeneralConfig = {
  key: "general",
  version: 1,
  storage,
};

const persistedCartReducer = persistReducer(persistCartConfig, cartReducer);
const persistedCheckoutReducer = persistReducer(
  persistCheckoutConfig,
  checkoutReducer
);
const persistedDiscountRulesReducer = persistReducer(
  persistDiscountRulesConfig,
  discountRulesReducer
);
const persistedUserReducer = persistReducer(persistUserConfig, userReducer);
const persistedGeneralReducer = persistReducer(persistGeneralConfig, generalReducer);

const overlayReducerWithDefaultReset = (state, action) => {
  if (action.type === "RESET_STATE") {
    return undefined;
  }
  return overlayReducer(state, action);
};
const cartVisibilityReducerWithDefaultReset = (state, action) => {
  if (action.type === "RESET_STATE") {
    return undefined;
  }
  return cartVisibilityReducer(state, action);
};
const hamburgerVisibilityReducerWithDefaultReset = (state, action) => {
  if (action.type === "RESET_STATE") {
    return undefined;
  }
  return hamburgerVisibilityReducer(state, action);
};

export const store = configureStore({
  reducer: {
    cart: persistedCartReducer,
    overlay: overlayReducerWithDefaultReset,
    cartVisibility: cartVisibilityReducerWithDefaultReset,
    hamburgerVisibility: hamburgerVisibilityReducerWithDefaultReset,
    checkout: persistedCheckoutReducer,
    discountRules: persistedDiscountRulesReducer,
    user: persistedUserReducer,
    general: persistedGeneralReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
