import { useState, useCallback } from 'react';

const useFoloosiReferenceToken = () => {
  const [referenceToken, setReferenceToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getReferenceToken = useCallback(async (merchantKey, payload) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.foloosi.com/aggregatorapi/web/initialize-setup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'merchant_key': merchantKey,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok && data.data?.reference_token) {
        setReferenceToken(data.data.reference_token);
      } else {
        setError(data.message || 'Failed to generate reference token');
      }
    } catch (err) {
      setError('Network error or invalid API call');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    referenceToken,
    loading,
    error,
    getReferenceToken,
  };
};

export default useFoloosiReferenceToken;
