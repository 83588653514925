import React, { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";

import { getDHLShipmentEvents } from "../../helpers/common";

import { useSelector } from "react-redux";

import { useParams, useNavigate, Link } from "react-router-dom";

import "./Order.scss";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";

import { useTranslation } from "react-i18next";

const Order = () => {
  const id = useParams().id;

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const [shippingEvents, setShippingEvents] = useState([]);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "deep",
    locale: "en",
    try: tries,
  });

  const pageBaseUrl = `/orders/${id}`;
  const pageUrl = `${pageBaseUrl}?${Params.toString()}`;
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useFetch(pageUrl);

  const formatTime = (time) => {
    const utcDate = new Date(time);
    utcDate.setHours(utcDate.getHours() - 3);
    const userTimezoneOffset = -utcDate.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(userTimezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(userTimezoneOffset % 60).padStart(2, "0");
    const offsetSign = userTimezoneOffset >= 0 ? "+" : "-";
    const timezoneString = `(UTC ${offsetSign}${offsetHours}:${offsetMinutes})`;
    const formattedDate = new Intl.DateTimeFormat(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(utcDate);
    return `${formattedDate} ${timezoneString}`;
  };

  const isExchangable = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);

    const tenDaysAgo = new Date();
    tenDaysAgo.setHours(0, 0, 0, 0);
    tenDaysAgo.setDate(tenDaysAgo.getDate() - 15); // Subtract 15 days
    return tenDaysAgo <= normalized;
  };

  useEffect(() => {
    if (!pageLoading && pageData) {
      if (user?.id !== pageData?.attributes?.User?.data?.id) {
        navigate("/");
      }
      setData(pageData);
      setLoading(false);
    } else if (pageLoading && !pageError) {
      setLoading(true);
    } else if (!pageLoading && pageError && !pageData) {
      setLoading(false);
      setError(true);
    }
  }, [pageLoading]);

  useEffect(() => {
    const handleDHLShippingInfo = async () => {
      if (data?.attributes?.ShippingMethod == "DHL") {
        const events = await getDHLShipmentEvents(
          data?.attributes?.DHLTrackingNumber
        );
        const eventGroups = [];
        events.forEach((event) => {
          const group = eventGroups?.find((g) => g?.date == event?.date);
          if (group) {
            group.events.push(event);
          } else {
            const newGroup = {
              date: event?.date,
              events: [],
            };
            newGroup.events.push(event);
            eventGroups.push(newGroup);
          }
        });
        eventGroups?.sort((a, b) => {
          const aDate = new Date(a?.date).getTime();
          const bDate = new Date(b?.date).getTime();
          return bDate - aDate;
        });
        eventGroups?.forEach((eventGroup) => {
          eventGroup?.events?.sort((a, b) => {
            const aDate = new Date(`${a?.date}T${a?.time}Z`).getTime();
            const bDate = new Date(`${b?.date}T${b?.time}Z`).getTime();
            return bDate - aDate;
          });
        });
        setShippingEvents(eventGroups);
      }
    };

    const debounceTimeout = setTimeout(handleDHLShippingInfo, 500);
    return () => clearTimeout(debounceTimeout);
  }, [data]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t("order.order")} ${id}`;
  }, []);

  return (
    <section className="order-details">
      <LoaderOverlay
        loadingState={loading}
        errorState={error}
        setErrorState={setError}
        retryFunc={retry}
      />
      {!loading && data && (
        <>
          <h1 className="order-details-title">{t("order.order-details")}</h1>
          <div className="order-details-container">
            <div className="order-details-table-container">
              <h2 className="order-details-label">
                {t("order.products-info")}:
              </h2>
              <table className="order-details-table">
                <thead>
                  <th>{t("order.product-name")}</th>
                  <th>{t("home.barcode")}</th>
                  <th>{t("order.quantity")}</th>
                  <th>{t("home.color")}</th>
                  <th>{t("home.size")}</th>
                  <th>{t("order.price-before-vat")}</th>
                  <th>{t("order.price-after-vat")}</th>
                  <th>{t("home.vat")}</th>
                  <th>{t("order.discount-amount")}</th>
                  <th>{t("order.gross-amount")}</th>
                  {isExchangable(data?.attributes?.createdAt) && (
                    <th>{t("orders.actions")}</th>
                  )}
                </thead>
                <tbody>
                  {data?.attributes?.Products?.map((product, index) => {
                    return (
                      <tr key={index}>
                        <td>{product?.ProductName}</td>
                        <td>{product?.Barcode}</td>
                        <td>{product?.Quantity}</td>
                        <td>{product?.Color}</td>
                        <td>{product?.Size}</td>
                        <td>
                          {product?.PriceBeforeVAT?.toFixed(2)} {t("home.aed")}
                        </td>
                        <td>
                          {product?.PriceAfterVAT?.toFixed(2)} {t("home.aed")}
                        </td>
                        <td>
                          {product?.VAT} {t("home.aed")}
                        </td>
                        <td>
                          {product?.DiscountAmount} {t("home.aed")}
                        </td>
                        <td>
                          {product?.GrossAmount} {t("home.aed")}
                        </td>
                        {isExchangable(data?.attributes?.createdAt) && (
                          <td>
                            <Link
                              to={`/submit-exchange-request?order=${data?.id}&product=${product?.Product?.data?.id}&barcode=${product?.Barcode}`}
                              className="order-details-link"
                            >
                              {t("orders.exchange")}
                            </Link>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <h2 className="order-details-table-tip">{t("order.products-exchange-tip")}</h2>
            </div>
            <div className="order-details-table-container">
              <h2 className="order-details-label">{t("order.order-info")}:</h2>
              <table className="order-details-table">
                <thead>
                  <th>{t("order.order-id")}</th>
                  <th>{t("order.order-status")}</th>
                  <th>{t("order.order-date")}</th>
                  <th>{t("order.order-total")}</th>
                </thead>
                <tbody>
                  <td>#{data?.id}</td>
                  <td>{data?.attributes?.Status}</td>
                  <td>
                    {(() => {
                      const date = new Date(data?.attributes?.createdAt);

                      const formattedDate = new Intl.DateTimeFormat("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }).format(date);
                      return formattedDate;
                    })()}
                  </td>
                  <td>
                    {data?.attributes?.TotalAfterVAT} {t("home.aed")}
                  </td>
                </tbody>
              </table>
            </div>
            <div className="order-details-table-container">
              <h2 className="order-details-label">
                {t("order.additional-info")}:
              </h2>
              <table className="order-details-table">
                <thead>
                  <th>{t("order.delivery-notes")}</th>
                  <th>{t("order.special-requests")}</th>
                  <th>{t("order.is-the-order-a-gift")}</th>
                  <th>{t("order.gift-message")}</th>
                </thead>
                <tbody>
                  <td>{data?.attributes?.Notes}</td>
                  <td>{data?.attributes?.SpecialRequest}</td>
                  <td>
                    {data?.attributes?.IsGift ? t("order.yes") : t("order.no")}
                  </td>
                  <td>{data?.attributes?.GiftMessage}</td>
                </tbody>
              </table>
            </div>
            <div className="order-details-table-container">
              <h2 className="order-details-label">
                {t("order.address-info")}:
              </h2>
              <table className="order-details-table">
                <thead>
                  <th>{t("order.country")}</th>
                  <th>{t("order.city")}</th>
                  <th>{t("order.area")}</th>
                  <th>{t("order.address-line-1")}</th>
                  <th>{t("order.address-line-2")}</th>
                  {data?.attributes?.AddressMapLink && (
                    <th>{t("order.map-link")}</th>
                  )}
                </thead>
                <tbody>
                  <td>{data?.attributes?.Country}</td>
                  <td>{data?.attributes?.City}</td>
                  <td>{data?.attributes?.Area}</td>
                  <td>{data?.attributes?.Address1}</td>
                  <td>{data?.attributes?.Address2}</td>
                  {data?.attributes?.AddressMapLink && (
                    <td>
                      <Link
                        to={data?.attributes?.AddressMapLink}
                        className="order-details-link"
                        target="_blank"
                      >
                        {t("order.link")}
                      </Link>
                    </td>
                  )}
                </tbody>
              </table>
            </div>
            <div className="order-details-table-container">
              <h2 className="order-details-label">{t("order.totals-info")}:</h2>
              <table className="order-details-table">
                <thead>
                  <th>{t("order.total-name")}</th>
                  <th>{t("order.total-value")}</th>
                </thead>
                <tbody>
                  {data?.attributes?.Totals?.map((total, index) => {
                    return (
                      <tr key={index}>
                        <td>{total?.TotalName}</td>
                        <td>
                          {total?.TotalName?.includes("Voucher") ||
                          total?.TotalName?.includes("Promo") ||
                          total?.TotalName == "Discount"
                            ? "-"
                            : ""}{" "}
                          {total?.TotalValue?.toFixed(2)} {t("home.aed")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {shippingEvents?.length > 0 && (
              <div className="order-details-table-container">
                <h2 className="order-details-label">
                  {t("order.shipping-info")}:
                </h2>
                <div className="shipping-timeline">
                  {shippingEvents.map((eventGroup, index) => {
                    return (
                      <div className="event-group" key={index}>
                        <h2 className="event-group-date">
                          {(() => {
                            const date = new Date(eventGroup?.date);

                            const formattedDate = new Intl.DateTimeFormat(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            ).format(date);
                            return formattedDate;
                          })()}
                          :
                        </h2>
                        <ul className="shipment-events">
                          {eventGroup.events.map((event, index) => {
                            return (
                              <li className="shipment-event-entry" key={index}>
                                <span className="event-time">
                                  {formatTime(`${event?.date}T${event?.time}Z`)}
                                  :
                                </span>
                                <span className="event-description">
                                  {event?.description}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {!loading && !data && (
        <div className="orders-page-error">{t("order.no-such-order")}!</div>
      )}
    </section>
  );
};

export default Order;
