import { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";

import { useSelector } from "react-redux";

import { useParams, useNavigate, Link } from "react-router-dom";

import "./ExchangeDetails.scss";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";

import { useTranslation } from "react-i18next";

const ExchangeDetails = () => {
  const id = useParams().id;

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const [originalProduct, setOriginalProduct] = useState({
    image: "",
    color: "",
    size: "",
    title: "",
    barcode: "",
    price: 0,
  });
  const [exchangeProduct, setExchangeProduct] = useState({
    image: "",
    color: "",
    size: "",
    title: "",
    barcode: "",
    price: 0,
  });

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "deep",
    locale: "en",
    try: tries,
  });

  const pageBaseUrl = `/ecxhange-requests/${id}`;
  const pageUrl = `${pageBaseUrl}?${Params.toString()}`;
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useFetch(pageUrl);

  const getBarcodeImage = (Product, Barcode) => {
    let imageURL = "";

    for (const variant of Product?.data?.attributes?.Variants) {
      let isFound = false;

      for (const size of variant?.Sizes) {
        if (size?.Barcode === Barcode) {
          imageURL = variant?.Images?.data[0]?.attributes?.url;
          isFound = true;
          break;
        }
      }

      if (isFound) break;
    }

    if (imageURL?.trim() === "") {
      imageURL = Product?.DefaultImage?.data?.attributes?.url;
    }

    return imageURL;
  };

  const initData = (data) => {
    if (!data) return;

    const attrs = data.attributes;

    // Original product data
    setOriginalProduct({
      image: getBarcodeImage(attrs?.Product, attrs?.Barcode),
      color: attrs?.ProductColor?.data?.attributes?.Name || "",
      size: attrs?.ProductSize?.data?.attributes?.Name || "",
      title: attrs?.Product?.data?.attributes?.Title || "",
      barcode: attrs?.Barcode || "",
      price: attrs?.ProductValue || 0,
    });

    // Exchange product data
    if (attrs?.ExchangeProduct.data) {
      setExchangeProduct({
        image: getBarcodeImage(attrs?.ExchangeProduct, attrs?.ExchangeBarcode),
        color: attrs?.color?.data?.attributes?.Name || "",
        size: attrs?.ExchangeSize?.data?.attributes?.Name || "",
        title: attrs?.ExchangeProduct?.data?.attributes?.Title || "",
        barcode: attrs?.ExchangeBarcode || "",
        price: attrs?.ExchangeProductValue || 0,
      });
    }
  };

  useEffect(() => {
    if (!pageLoading && pageData) {
      setData(pageData);
      initData(pageData);
      setLoading(false);
    } else if (pageLoading && !pageError) {
      setLoading(true);
    } else if (!pageLoading && pageError && !pageData) {
      setLoading(false);
      setError(true);
    }
  }, [pageLoading]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t(
      "exchange.exchange-details"
    )} ${id}`;
  }, []);

  return (
    <section className="exchange-details-page">
      <LoaderOverlay
        loadingState={loading}
        errorState={error}
        setErrorState={setError}
        retryFunc={retry}
      />
      {!loading && data && (
        <>
          <h1 className="exchange-details-title">
            {t("exchange.exchange-details")}
          </h1>
          <div className="exchange-details-container">
            <div className="exchange-details-table-container">
              <h2 className="exchange-details-label">
                {t("exchange.general-info")}:
              </h2>
              <table className="exchange-details-table">
                <thead>
                  <th>{t("exchange.exchange-id")}</th>
                  <th>{t("exchange.exchange-status")}</th>
                  <th>{t("exchange.exchange-date")}</th>
                  <th>{t("order.order-id")}</th>
                </thead>
                <tbody>
                  <td>#{data?.id}</td>
                  <td>{data?.attributes?.Status}</td>
                  <td>
                    {(() => {
                      const createdAt = data?.attributes?.createdAt;
                      if (!createdAt) return "-";

                      try {
                        const date = new Date(createdAt);
                        if (isNaN(date.getTime())) return "-";

                        return new Intl.DateTimeFormat("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }).format(date);
                      } catch (error) {
                        return "-";
                      }
                    })()}
                  </td>
                  <td>
                    <Link
                      to={`/order/${data?.attributes?.Order?.data?.id}`}
                      className="exchange-details-link"
                    >
                      #{data?.attributes?.Order?.data?.id}
                    </Link>
                  </td>
                </tbody>
              </table>
            </div>
            <div className="exchange-details-two-cols-container">
              <div className="product-col">
                <h2 className="exchange-details-label">
                  {t("exchange.product-info")}:
                </h2>
                <div className="exchange-details-product-card">
                  <img
                    className="product-card-image"
                    src={`${
                      process.env.REACT_APP_IMAGE_URL + originalProduct.image
                    }`}
                    alt={originalProduct.title}
                  />
                  <div className="product-card-info">
                    <h3 className="product-card-title-text">
                      {originalProduct.title}
                    </h3>
                    <div className="product-card-entry">
                      <h3 className="product-card-label">
                        {t("home.barcode")}:
                      </h3>
                      <h3 className="product-card-text">
                        {originalProduct.barcode}
                      </h3>
                    </div>
                    <div className="product-card-entry">
                      <h3 className="product-card-label">{t("home.color")}:</h3>
                      <h3 className="product-card-text">
                        {originalProduct.color}
                      </h3>
                    </div>
                    <div className="product-card-entry">
                      <h3 className="product-card-label">{t("home.size")}:</h3>
                      <h3 className="product-card-text">
                        {originalProduct.size}
                      </h3>
                    </div>
                    <div className="product-card-entry">
                      <h3 className="product-card-label">{t("home.price")}:</h3>
                      <h3 className="product-card-text">
                        {originalProduct.price} {t("home.aed")}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-col">
                <h2 className="exchange-details-label">
                  {t("exchange.exchange-product-info")}:
                </h2>
                {exchangeProduct.barcode.trim() !== "" && (
                  <div className="exchange-details-product-card">
                    <img
                      className="product-card-image"
                      src={`${
                        process.env.REACT_APP_IMAGE_URL + exchangeProduct.image
                      }`}
                      alt={exchangeProduct.title}
                    />
                    <div className="product-card-info">
                      <h3 className="product-card-title-text">
                        {exchangeProduct.title}
                      </h3>
                      <div className="product-card-entry">
                        <h3 className="product-card-label">
                          {t("home.barcode")}:
                        </h3>
                        <h3 className="product-card-text">
                          {exchangeProduct.barcode}
                        </h3>
                      </div>
                      <div className="product-card-entry">
                        <h3 className="product-card-label">
                          {t("home.color")}:
                        </h3>
                        <h3 className="product-card-text">
                          {exchangeProduct.color}
                        </h3>
                      </div>
                      <div className="product-card-entry">
                        <h3 className="product-card-label">
                          {t("home.size")}:
                        </h3>
                        <h3 className="product-card-text">
                          {exchangeProduct.size}
                        </h3>
                      </div>
                      <div className="product-card-entry">
                        <h3 className="product-card-label">
                          {t("home.price")}:
                        </h3>
                        <h3 className="product-card-text">
                          {exchangeProduct.price} AED
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                {exchangeProduct.barcode.trim() === "" && (
                  <div className="tbd-message-container">
                    <h2 className="tbd-message">To be determined</h2>
                  </div>
                )}
              </div>
            </div>

            {/* Exchange Images */}
            <div className="exchange-details-media-container">
              <h2 className="exchange-details-label">
                {t("exchange.media-info")}:
              </h2>
              <div className="media-files-container">
                {data?.attributes?.Images?.data?.map((media, index) => {
                  return (
                    <div className="media-file">
                      {media?.attributes?.mime?.includes("video") && (
                        <video controls>
                          <source
                            src={`${
                              process.env.REACT_APP_IMAGE_URL +
                              media?.attributes?.url
                            }`}
                            type={media?.attributes?.mime}
                          />
                          <p>
                            Your browser doesn't support HTML video. Here is a
                            <a href="myVideo.mp4" download="myVideo.mp4">
                              link to the video
                            </a>{" "}
                            instead.
                          </p>
                        </video>
                      )}
                      {media?.attributes?.mime?.includes("image") && (
                        <img
                          src={`${
                            process.env.REACT_APP_IMAGE_URL +
                            media?.attributes?.url
                          }`}
                          alt={`file-${index + 1}`}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Payment Information */}
            {data?.attributes?.ToBePaid > 0 &&
              data?.attributes?.ExtraAmountAction && (
                <div className="exchange-details-payment">
                  <h2 className="exchange-details-label">
                    {t("exchange.payment-info")}:
                  </h2>
                  <div className="exchange-details-payment-info-container">
                    <h2 className="payment-action-text">
                      {data?.attributes?.ToBePaid} {t("home.aed")}{" "}
                      {t("exchange.needs-to-be-paid")}{" "}
                      {data?.attributes?.ExtraAmountAction === "Online Payment"
                        ? t("exchange.online-payment")
                        : t("exchange.cod")}
                      .
                    </h2>

                    {data.attributes.PaymentLink && (
                      <>
                        <span className="payment-action-text">
                          {t("exchange.payment-link")}:{" "}
                          <a
                            href={data.attributes.PaymentLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="payment-action-text"
                          >
                            {t("exchange.pay-now")}
                          </a>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}

            {/* Refund Information */}
            {data?.attributes?.ToRefund && (
              <div className="exchange-details-voucher">
                <h2 className="exchange-details-label">
                  {t("exchange.refund-info")}:
                </h2>
                <div className="exchange-details-voucher-info-container">
                  <h2 className="voucher-code-message">
                    {t("exchange.we-owe-you")} {data.attributes.ToRefund}{" "}
                    {t("home.aed")}!
                  </h2>
                  {data?.attributes?.RemainingAmountAction === "Voucher" &&
                    data?.attributes?.VoucherCode && (
                      <>
                        <h2 className="voucher-code-message">
                          {t("exchange.we-decided-voucher")}.
                        </h2>
                        <h2 className="voucher-code-message">
                          {t("exchange.voucher-code")}:{" "}
                          <span className="voucher-code-text">
                            {data.attributes.VoucherCode}
                          </span>
                        </h2>
                        <h2 className="voucher-code-message">
                          {t("exchange.voucher-amount")}:{" "}
                          {data.attributes.ToRefund} {t("home.aed")}.
                        </h2>
                      </>
                    )}
                  {data?.attributes?.RemainingAmountAction === "Refund" && (
                    <>
                      <h2 className="voucher-code-message">
                        {t("exchange.we-decided-refund")}.
                      </h2>
                      <h2 className="voucher-code-message">
                        {t("exchange.refund-amount")}:{" "}
                        {data.attributes.ToRefund} {t("home.aed")}.
                      </h2>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {!loading && !data && (
        <div className="orders-page-error">
          {t("exchange.no-such-exchange")}!
        </div>
      )}
    </section>
  );
};

export default ExchangeDetails;
