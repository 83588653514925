import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { disable, enable } from "../../redux/overlayReducer";
import {
  closeHamburger,
  toggleHamburger,
} from "../../redux/hamburgerVisibilityReducer";
import { encodeURL } from "../../helpers/common";
import Dropdown from "../Dropdown/Dropdown";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { removeUser } from "../../redux/userReducer";

import { Link } from "react-router-dom";

import "./HamburgerMenu.scss";

import { ReactComponent as Hamburger } from "../../SVGs/Hamburger.svg";
import { ReactComponent as Close } from "../../SVGs/Close.svg";

import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";

import { useTranslation } from "react-i18next";

const HamburgerMenu = ({ data, lang }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const button = useRef(null);
  const isOpen = useSelector((state) => state.hamburgerVisibility.isOpen);
  const dispatch = useDispatch();

  const handleSelectOnClick = () => {
    if (isOpen) {
      dispatch(disable());
    } else {
      dispatch(enable());
    }
    dispatch(toggleHamburger());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !button.current.contains(event.target)
      ) {
        dispatch(disable());
        dispatch(closeHamburger());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const user = useSelector((state) => state.user.user);

  return (
    <div className="hamburger">
      <button
        onClick={handleSelectOnClick}
        className="dropdown-label dropdown-select"
        ref={button}
      >
        <span>
          <Hamburger />
        </span>
      </button>
      <div
        className={[
          "dropdown-container",
          "hamburger-options",
          isOpen ? "active" : "hidden",
        ].join(" ")}
      >
        <div className="container">
          <Close className="close-btn" onClick={handleSelectOnClick} />
          <LanguageSelector
            languagesArray={lang?.attributes?.Language?.languages?.data}
          />

          {Object.keys(user).length === 0 ? (
            <>
              <div className="profile-dropdown-link-container">
                <Link
                  className="list-item"
                  to="/login"
                  onClick={() => {
                    dispatch(disable());
                    dispatch(closeHamburger());
                  }}
                >
                  <span>{t("home.login")}</span>
                </Link>
                <Link
                  className="list-item"
                  to="/register"
                  onClick={() => {
                    dispatch(disable());
                    dispatch(closeHamburger());
                  }}
                >
                  <span>{t("home.register")}</span>
                </Link>
              </div>
            </>
          ) : (
            <>
              <span className="profile-dropdown-title">
                {t("home.welcome")} {user?.FirstName} {user?.LastName}
              </span>
            </>
          )}
          <>
            {data?.attributes?.SubMenus?.map((el, index) => {
              if (
                el?.categories?.data?.length <= 1 &&
                el?.Tags?.data?.length <= 1
              ) {
                let encodedURI = "";
                if (el?.categories?.data?.length == 1) {
                  encodedURI = `/category/${encodeURL(
                    el?.categories?.data[0]?.attributes?.Title
                  )}`;
                } else if (el?.Tags?.data?.length == 1) {
                  encodedURI = `/tag/${encodeURL(
                    el?.Tags?.data[0]?.attributes?.Name
                  )}`;
                }
                return (
                  <div className="">
                    <Link
                      key={index}
                      to={encodedURI}
                      onClick={() => {
                        dispatch(disable());
                        dispatch(closeHamburger());
                      }}
                      className="nav-link link-container text-underline-animation"
                    >
                      {el?.Title}
                    </Link>
                  </div>
                );
              } else {
                const links = [];
                if (el?.categories?.data?.length > 1) {
                  el?.categories?.data?.forEach((link) => {
                    links.push({
                      title: link?.attributes?.Title,
                      link: `/category/${encodeURL(link?.attributes?.Title)}`,
                    });
                  });
                } else if (el?.Tags?.data?.length > 1) {
                  el?.Tags?.data?.forEach((link) => {
                    links.push({
                      title: link?.attributes?.Name,
                      link: `/tag/${encodeURL(link?.attributes?.Name)}`,
                    });
                  });
                }

                return (
                  <Dropdown
                    key={index}
                    title={el?.Title}
                    dropdownTitle={el?.InnerTitle}
                    links={links}
                    featured={[
                      {
                        photoLink:
                          process.env.REACT_APP_IMAGE_URL +
                          el?.ProductL?.data?.attributes?.DefaultImage?.data
                            ?.attributes?.url,
                        label: el?.ProductL?.data?.attributes?.Title,
                        link: `/product/${encodeURL(
                          el?.ProductL?.data?.attributes?.Title
                        )}`,
                      },
                      {
                        photoLink:
                          process.env.REACT_APP_IMAGE_URL +
                          el?.ProductR?.data?.attributes?.DefaultImage?.data
                            ?.attributes?.url,
                        label: el?.ProductR?.data?.attributes?.Title,
                        link: `/product/${encodeURL(
                          el?.ProductR?.data?.attributes?.Title
                        )}`,
                      },
                    ]}
                  />
                );
              }
            })}
          </>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
