import { useState, useEffect } from "react";

import useFetch from "./useFetch";

import { useTranslation } from "react-i18next";

const useShippingProviders = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    "populate": '*',
    locale: 'en',
    "filters[Status][$eq]": true,
    try: tries,
  });

  // get payment methods
  const shippingProvidersBaseUrl = "/shipping-providers";
  const shippingProvidersUrl = `${shippingProvidersBaseUrl}?${Params.toString()}`;
  const {
    data: shippingProvidersData,
    loading: shippingProvidersLoading,
    error: shippingProvidersError,
  } = useFetch(shippingProvidersUrl);

  useEffect(() => {
    if (shippingProvidersError) {
      setError(true);
      setLoading(false);
      return;
    }

    if (
      !shippingProvidersLoading &&
      shippingProvidersData?.length > 0
    ) {
      setData(shippingProvidersData);
      setLoading(false);
    }
  }, [shippingProvidersLoading]);

  return {
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export { useShippingProviders };
