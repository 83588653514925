import React, { useEffect, useState } from "react";

import { applyDiscountRules, isOutOfStock } from "../../helpers/common";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import "./Card.scss";

import { useTranslation } from "react-i18next";

const Card = ({
  product,
  areDiscountRulesApplied = false,
  isSlider = true,
  isCategory = false,
}) => {
  const [newProduct, setNewProduct] = useState(structuredClone(product));
  const [outOfStock, setOutOfStock] = useState(false);
  const discountRules = useSelector((state) => state.discountRules);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (!areDiscountRulesApplied) {
      const discountedProduct = applyDiscountRules([newProduct], discountRules);
      setNewProduct(discountedProduct[0]);
      setOutOfStock(isOutOfStock(discountedProduct[0]?.attributes?.Variants));
    } else {
      setOutOfStock(isOutOfStock(newProduct?.attributes?.Variants));
    }
  }, [product, discountRules, newProduct]);

  return (
    <Link
      className="card"
      to={`/product/${newProduct?.attributes?.SEOURL}`}
      key={newProduct?.id}
    >
      <div className="image">
        {outOfStock && (
          <p className="out-of-stock-banner">{t("product.out-of-stock")}</p>
        )}
        <img
          className={[
            "img",
            isCategory ? "category-card" : "",
            outOfStock ? "out-of-stock" : "",
          ].join(" ")}
          src={`${
            process.env.REACT_APP_IMAGE_URL +
            newProduct?.attributes?.DefaultImage?.data?.attributes?.url
          }`}
          alt={
            newProduct?.attributes?.DefaultImage?.data?.attributes
              ?.alternativeText
          }
        />
      </div>
      <div className="info">
        <h1 className="name">{newProduct?.attributes?.Title}</h1>
        <div className="price">
          {newProduct?.attributes?.discountedPrice &&
          newProduct?.attributes?.discountedPrice !==
            newProduct?.attributes?.Price ? (
            <div className="real-price">
              <div className="container">
                <span className="numerical-price">
                  {newProduct?.attributes?.Price}
                </span>
                <span className="currency">{t("home.aed")}</span>
              </div>
              {!isSlider && (
                <span className="vat">{t("home.price-with-vat")}</span>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="discounted-price">
            <div className="container">
              <span className="numerical-price">
                {newProduct?.attributes?.discountedPrice ??
                  newProduct?.attributes?.Price}
              </span>
              <span className="currency">{t("home.aed")}</span>
            </div>
            {!isSlider && (
              <span className="vat">{t("home.price-with-vat")}</span>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
