import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) => item.barcode === action.payload.barcode
      );
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
    },
    editQty: (state, action) => {
      const item = state.products.find(
        (item) => item.barcode === action.payload.barcode
      );
      if (item) {
        if (action.payload.operation === "+") {
          item.quantity += 1;
        } else if (action.payload.operation === "-" && item.quantity > 1) {
          item.quantity -= 1;
        }
      }
    },
    removeItem: (state, action) => {
      state.products = state.products.filter(
        (item) => item.barcode !== action.payload
      );
    },
    resetCart: (state) => {
      state.products = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, editQty, removeItem, resetCart } = cartSlice.actions;

export default cartSlice.reducer;
