import React, { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";
import usePost from "../../hooks/usePost";
import { encodeURL } from "../../helpers/common";

import { Link } from "react-router-dom";

import "./Footer.scss";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [emailValue, setEmailValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [requestData, setRequestData] = useState({});
  const [newsletterRetries, setNewsletterRetries] = useState(0);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterError, setNewsletterError] = useState(false);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [tries, setTries] = useState(0);

  const NewsletterUrl = "/newsletters";
  const {
    data: NewsletterData,
    loading: NewsletterLoading,
    error: NewsletterError,
    status: NewsletterStatus,
    errorMessage: NewsletterErrorMessage,
  } = usePost(NewsletterUrl, requestData);

  const footerBaseUrl = "/footer";
  const footerParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    try: tries,
  });
  const footerUrl = `${footerBaseUrl}?${footerParams.toString()}`;
  const {
    data: footerData,
    loading: footerLoading,
    error: footerError,
  } = useFetch(footerUrl);

  const retryFooter = () => setTries((prev) => prev + 1);

  const handleOnSubmit = (event) => {
    event.preventDefault();

    if (NewsletterLoading) return;

    if (emailValue.trim() === "") {
      setErrorMessage(t("errors.email-not-valid"));
      return;
    }
    setErrorMessage("");

    setRequestData({
      data: {
        Email: emailValue,
      },
    });
    setNewsletterRetries((prev) => prev + 1);
  };

  useEffect(() => {
    if (NewsletterLoading) {
      setNewsletterLoading(true);
      return;
    }
    setNewsletterLoading(false);

    if (NewsletterStatus !== 200 && newsletterRetries > 0) {
      setNewsletterError(true);
      setErrorMessage(t("errors.email-not-unique"));
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    if (NewsletterData) {
      setNewsletterError(false);
      setNewsletterLoading(false);
      setEmailValue("");
      setSuccessMessage(t("home.subscribed"));
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  }, [NewsletterLoading]);

  useEffect(() => {
    if (!footerLoading && footerData) {
      setData(footerData);
      setLoading(false);
    } else if (footerLoading && !footerError) {
      setLoading(true);
    } else if (!footerLoading && footerError && !footerData) {
      retryFooter();
    }
  }, [footerLoading]);

  return (
    <footer className="footer">
      <div className="contanct-container">
        <div className="customer-service">
          <h2 className="title">{data?.attributes?.CustomerService?.Title}</h2>
          <div className="info">
            <a
              className="email"
              href={`mailto:${data?.attributes?.CustomerService?.Email}`}
            >
              {data?.attributes?.CustomerService?.Email}
            </a>
            <h2 className="number">
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  data?.attributes?.CustomerService?.PhoneIcon?.data?.attributes
                    ?.url
                }
              />
              <a
                className="whatsapp-link"
                href={`https://api.whatsapp.com/send?phone=${data?.attributes?.CustomerService?.Phone.replace(
                  /\s/g,
                  ""
                ).replace("+", "")}`}
              >
                {data?.attributes?.CustomerService?.Phone}
              </a>
            </h2>
          </div>
        </div>
        <div className="complaints">
          <h2 className="title">{data?.attributes?.Complains?.Title}</h2>
          <div className="info">
            <a
              className="email"
              href={`mailto:${data?.attributes?.Complains?.Email}`}
            >
              {data?.attributes?.Complains?.Email}
            </a>
            <h2 className="number">
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  data?.attributes?.Complains?.PhoneIcon?.data?.attributes?.url
                }
              />
              <a
                className="whatsapp-link"
                href={`https://api.whatsapp.com/send?phone=${data?.attributes?.Complains?.Phone.replace(
                  /\s/g,
                  ""
                ).replace("+", "")}`}
              >
                {data?.attributes?.Complains?.Phone}
              </a>
            </h2>
          </div>
        </div>
        <div className="contact-us">
          <h2 className="title">{data?.attributes?.NewsLetterText}</h2>
          <div className="info">
            {errorMessage.trim() !== "" && (
              <h2 className="footer-error">{errorMessage}</h2>
            )}
            {successMessage.trim() !== "" && (
              <h2 className="footer-success">{successMessage}</h2>
            )}
            <form onSubmit={handleOnSubmit}>
              <input
                className="email-input"
                type="email"
                placeholder={t("home.enter-your-email")}
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />
              <button
                className="submit-btn"
                type="submit"
                disabled={newsletterLoading}
              >
                {newsletterLoading
                  ? t("account.loading...")
                  : t("home.subscribe")}
              </button>
            </form>
          </div>
          <div className="icons">
            {data?.attributes?.SocailMedia?.map((el, index) => {
              return (
                <Link key={index} to={`${el?.URL}`}>
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      el?.Icon?.data?.attributes?.url
                    }
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="footer-links">
        <div className="container">
          {data?.attributes?.Menu?.map((menu, index) => {
            return (
              <div key={index} className="footer-menu-container">
                <h2 className="title">{menu?.Name}</h2>
                <div className="links">
                  {menu?.SubMenu?.map((submenu, index) => {
                    const isCategory = submenu?.Category?.data;
                    const isPage = submenu?.Page?.data;
                    let url = "";

                    if (isCategory) {
                      url = `/category/${encodeURL(
                        isCategory?.attributes?.Title
                      )}`;
                    }

                    if (isPage) {
                      url = `/page/${isPage?.attributes?.SEOURL}`;
                    }
                    return (
                      <Link key={index} className="link" to={`${url}`}>
                        {submenu?.Title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="logo-container">
        <Link to="/" className="logo">
          Classy . YM
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
