import { useState } from "react";
import axios from "axios";
import { makeRequest } from "../makeRequest";
import countries from "i18n-iso-countries";
import { useTranslation } from "react-i18next";

const useDHLShipping = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tries, setTries] = useState(0);

  const retry = () => {
    setTries((prev) => prev + 1);
  };

  const getDHLSettings = async () => {
    setLoading(true);
    setError("");
    let result = [];

    const Params = new URLSearchParams({
      populate: "*",
      "pagination[limit]": -1,
    });

    // get countries
    const countriesBaseUrl = "/settings";
    const countriesUrl = `${countriesBaseUrl}?${Params.toString()}`;

    try {
      setLoading(true);
      result = await makeRequest.get(countriesUrl);
    } catch (err) {
      setError(t("errors.unknown"));
    }
    setLoading(false);
    return result?.data?.data;
  };

  const getProductPrice = async (product, exchange) => {
    let productPrice = 0;
    for (const totalPrice of product?.totalPrice) {
      if (totalPrice?.priceCurrency == "AED") {
        productPrice = totalPrice?.price;
        break;
      }
    }

    if (productPrice == 0) {
      let price = product?.totalPrice[0]?.price;
      let currency = product?.totalPrice[0]?.priceCurrency;

      for (const rate of exchange) {
        if (rate?.baseCurrency == currency && rate?.currency == "AED") {
          price = price / rate?.currentExchangeRate;
        }
      }

      productPrice = price;
    }

    return Number(productPrice.toFixed(2));
  };

  const getRates = async (
    weight,
    countryName,
    cityName,
    postalCode,
    settings
  ) => {
    setLoading(true);
    setError("");

    const packageWeigth = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Max Package Weight"
    )?.attributes?.SettingValue;
    const totalWeight = parseFloat(weight);

    const length = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Length"
    )?.attributes?.SettingValue;

    const width = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Width"
    )?.attributes?.SettingValue;

    const height = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Height"
    )?.attributes?.SettingValue;

    const today = new Date();
    let plannedShippingDate = new Date(today);
    plannedShippingDate.setDate(today.getDate() + 1);

    if (plannedShippingDate.getDay() === 0) {
      plannedShippingDate.setDate(plannedShippingDate.getDate() + 1);
    }

    // Format the date as YYYY-MM-DD
    const formattedPlannedShippingDate = `${plannedShippingDate.getFullYear()}-${String(
      plannedShippingDate.getMonth() + 1
    ).padStart(2, "0")}-${String(plannedShippingDate.getDate()).padStart(
      2,
      "0"
    )}`;

    // Get country
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${currentLanguage}.json`)
    );
    const countryIso2Code = countries.getAlpha2Code(
      countryName,
      currentLanguage
    );

    // Define the API endpoint and query parameters
    const params = {
      originCountryCode: "AE",
      originCityName: "Ajman",
      destinationCountryCode: countryIso2Code,
      destinationCityName: cityName,
      weight: totalWeight,
      length: length,
      width: width,
      height: height,
      plannedShippingDate: formattedPlannedShippingDate,
      isCustomsDeclarable: true,
      unitOfMeasurement: "metric",
      tries: tries,
    };

    if (postalCode) {
      params.destinationPostalCode = postalCode;
    }

    let shippingCost = 0.0;
    let shippingProduct = null;
    let shippingProductCode = "";
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}dhl-rates`,
        params
      );
      const exchangeRates = response.data.exchangeRates;
      for (const product of response.data.products) {
        if (product.productName == "EXPRESS WORLDWIDE") {
          shippingProduct = product;
        }
      }

      // if (!shippingProduct) {
      //   let cheapestProduct = null;
      //   for (const product of response.data.products) {
      //     if (!cheapestProduct) {
      //       cheapestProduct = product;
      //     } else {
      //       if (
      //         getProductPrice(product, exchangeRates) <
      //         getProductPrice(cheapestProduct, exchangeRates)
      //       ) {
      //         cheapestProduct = product;
      //       }
      //     }
      //   }
      //   shippingProduct = cheapestProduct;
      // }

      if (shippingProduct) {
        shippingCost = await getProductPrice(shippingProduct, exchangeRates);
        shippingProductCode = shippingProduct?.productCode;
      }
    } catch (err) {
      setError("Failed to get shipping rates.");
      setTimeout(() => {
        setError("");
      }, 5000);
    }

    setLoading(false);
    let daysEstimate = "";
    const deliveryDate = new Date(
      shippingProduct?.deliveryCapabilities?.estimatedDeliveryDateAndTime
    );
    const currentDate = new Date();

    const timeDifference = deliveryDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    const minDays = daysDifference - 1;
    const maxDays = daysDifference + 1;

    daysEstimate = `${Math.max(0, minDays)}-${Math.max(0, maxDays)}`;
    return [shippingCost, daysEstimate, shippingProductCode];
  };

  const getDHLPageRates = async (
    weight,
    senderCountryName,
    senderCityName,
    senderPostalCode,
    receiverCountryName,
    receiverCityName,
    receiverPostalCode
  ) => {
    const settings = await getDHLSettings();
    setLoading(true);
    setError("");

    const totalWeight = parseFloat(weight);

    const length = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Length"
    )?.attributes?.SettingValue;

    const width = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Width"
    )?.attributes?.SettingValue;

    const height = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Height"
    )?.attributes?.SettingValue;

    const today = new Date();
    let plannedShippingDate = new Date(today);
    plannedShippingDate.setDate(today.getDate() + 1);

    if (plannedShippingDate.getDay() === 0) {
      plannedShippingDate.setDate(plannedShippingDate.getDate() + 1);
    }

    // Format the date as YYYY-MM-DD
    const formattedPlannedShippingDate = `${plannedShippingDate.getFullYear()}-${String(
      plannedShippingDate.getMonth() + 1
    ).padStart(2, "0")}-${String(plannedShippingDate.getDate()).padStart(
      2,
      "0"
    )}`;

    // Get country
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${currentLanguage}.json`)
    );
    const senderCountryIso2Code = countries.getAlpha2Code(
      senderCountryName,
      currentLanguage
    );
    const receiverCountryIso2Code = countries.getAlpha2Code(
      receiverCountryName,
      currentLanguage
    );

    // Define the API endpoint and query parameters
    const params = {
      originCountryCode: senderCountryIso2Code,
      originCityName: senderCityName,
      destinationCountryCode: receiverCountryIso2Code,
      destinationCityName: receiverCityName,
      weight: totalWeight,
      length: length,
      width: width,
      height: height,
      plannedShippingDate: formattedPlannedShippingDate,
      isCustomsDeclarable: true,
      unitOfMeasurement: "metric",
      tries: tries,
    };

    if (senderPostalCode) {
      params.originPostalCode = senderPostalCode;
    }
    if (receiverPostalCode) {
      params.destinationPostalCode = receiverPostalCode;
    }

    let shippingCost = 0.0;
    let shippingProduct = null;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}dhl-rates`,
        params
      );
      const exchangeRates = response.data.exchangeRates;
      for (const product of response.data.products) {
        if (product.productName == "EXPRESS WORLDWIDE") {
          shippingProduct = product;
        }
      }

      // if (!shippingProduct) {
      //   let cheapestProduct = null;
      //   for (const product of response.data.products) {
      //     if (!cheapestProduct) {
      //       cheapestProduct = product;
      //     } else {
      //       if (
      //         getProductPrice(product, exchangeRates) <
      //         getProductPrice(cheapestProduct, exchangeRates)
      //       ) {
      //         cheapestProduct = product;
      //       }
      //     }
      //   }
      //   shippingProduct = cheapestProduct;
      // }

      if (shippingProduct) {
        shippingCost = await getProductPrice(shippingProduct, exchangeRates);
      }
    } catch (err) {
      setError("Failed to get shipping rates.");
      setTimeout(() => {
        setError("");
      }, 5000);
    }

    setLoading(false);
    let daysEstimate = "";
    const deliveryDate = new Date(
      shippingProduct?.deliveryCapabilities?.estimatedDeliveryDateAndTime
    );
    const currentDate = new Date();

    const timeDifference = deliveryDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    const minDays = daysDifference - 1;
    const maxDays = daysDifference + 1;

    daysEstimate = `${Math.max(0, minDays)}-${Math.max(0, maxDays)}`;
    return [shippingCost, daysEstimate];
  };

  const createShipment = async (
    countryName,
    cityName,
    postalCode,
    addressLine1,
    addressLine2,
    customerName,
    customerEmail,
    customerPhone,
    shippingProductCode,
    deliveryNotes,
    total,
    totalCurrency,
    description,
    lineItems,
    invoiceNum,
    pkgs,
    orderID
  ) => {
    const settings = await getDHLSettings();
    setLoading(true);
    setError("");

    const accountNumber = settings?.find(
      (setting) => setting?.attributes?.SettingName == "DHL Account Number"
    )?.attributes?.SettingValue;

    const today = new Date();
    let plannedShippingDate = new Date(today);
    plannedShippingDate.setDate(today.getDate() + 1);

    if (plannedShippingDate.getDay() === 0) {
      plannedShippingDate.setDate(plannedShippingDate.getDate() + 1);
    }

    // Format the date as YYYY-MM-DD
    const dateWithoutTime = `${plannedShippingDate.getFullYear()}-${String(
      plannedShippingDate.getMonth() + 1
    ).padStart(2, "0")}-${String(plannedShippingDate.getDate()).padStart(
      2,
      "0"
    )}`;
    const formattedPlannedShippingDate = `${dateWithoutTime}T12:00:00 GMT+04:00`;

    // Get country
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${currentLanguage}.json`)
    );
    const countryIso2Code = countries.getAlpha2Code(
      countryName,
      currentLanguage
    );

    // Define the API endpoint and query parameters
    const url = `${process.env.REACT_APP_API_URL}dhl-create-shipment`;
    const params = {
      plannedShippingDateAndTime: formattedPlannedShippingDate,
      productCode: shippingProductCode,
      pickup: {
        isRequested: false,
        specialInstructions: [
          {
            value: deliveryNotes,
            typeCode: "TBD",
          },
        ],
      },
      valueAddedServices: [
        {
          serviceCode: "WY",
        },
        {
          serviceCode: "DS",
        },
      ],
      outputImageProperties: {
        printerDPI: 300,
        encodingFormat: "pdf",
        imageOptions: [
          {
            typeCode: "invoice",
            templateName: "COMMERCIAL_INVOICE_P_10",
            isRequested: true,
            invoiceType: "commercial",
            languageCode: "eng",
            languageCountryCode: "US",
          },
          {
            typeCode: "waybillDoc",
            templateName: "ARCH_8x4",
            isRequested: true,
            hideAccountNumber: false,
            numberOfCopies: 1,
          },
          {
            typeCode: "label",
            templateName: "ECOM26_84CI_003",
            renderDHLLogo: true,
            fitLabelsToA4: false,
          },
        ],
      },
      accounts: [
        {
          number: accountNumber,
          typeCode: "shipper",
        },
      ],
      customerReferences: [
        {
          value: `ORD_${orderID}`,
          typeCode: "CO",
        },
      ],
      customerDetails: {
        shipperDetails: {
          postalAddress: {
            postalCode: "00000",
            cityName: "Ajman",
            countryCode: "AE",
            addressLine1: "Al JURF 3 Jeddah street",
          },
          contactInformation: {
            fullName: "Maryam",
            phone: "+971 56 525 5245",
            email: "classyym.dhl@gmail.com",
            companyName: "CLASSY Y Y M GARMENTS TRADING L.L.C",
          },
        },
        receiverDetails: {
          postalAddress: {
            postalCode: postalCode,
            cityName: cityName,
            countryCode: countryIso2Code,
            addressLine1: addressLine1,
          },
          contactInformation: {
            fullName: customerName,
            phone: customerPhone,
            email: customerEmail,
            companyName: customerName,
          },
        },
      },
      content: {
        packages: pkgs,
        exportDeclaration: {
          lineItems: lineItems,
          invoice: {
            number: invoiceNum,
            date: dateWithoutTime,
          },
          exportReason: "Personal, Not for Resale",
          exportReasonType: "personal_belongings_or_personal_use"
        },
        isCustomsDeclarable: true,
        declaredValue: total,
        declaredValueCurrency: totalCurrency,
        unitOfMeasurement: "metric",
        description: description,
        incoterm: "DAP",
      },
    };

    if(addressLine2?.trim() != "") {
      params.customerDetails.receiverDetails.postalAddress.addressLine2 = addressLine2;
    }

    let trackingNumber = "";
    let pdfDocument = "";
    let invoiceDocument = "";
    try {
      const response = await axios.post(url, params);
      trackingNumber = response.data.shipmentTrackingNumber;
      pdfDocument = response?.data?.documents?.find(
        (doc) => doc?.imageFormat == "PDF" && doc?.typeCode == "label"
      )?.content;
      invoiceDocument = response?.data?.documents?.find(
        (doc) => doc?.imageFormat == "PDF" && doc?.typeCode == "invoice"
      )?.content;
    } catch (err) {
      setError("Failed to create shipment.");
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setLoading(false);
    return [trackingNumber, pdfDocument, invoiceDocument];
  };

  return {
    getDHLSettings,
    getRates,
    getDHLPageRates,
    createShipment,
    loading,
    error,
    setError,
    retry,
  };
};

export default useDHLShipping;
