import { useState, useEffect } from "react";

import useFetch from "./useFetch";

import { useTranslation } from "react-i18next";

const useGetArea = (cityID) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [data, setData] = useState({
    areas: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "*",
    locale: "en",
    try: tries,
    "pagination[limit]": -1,
    "filters[City][id][$eq]": cityID,
  });

  // get areas
  const areasBaseUrl = "/areas";
  const areasUrl = `${areasBaseUrl}?${Params.toString()}`;
  const {
    data: areasData,
    loading: areasLoading,
    error: areasError,
  } = useFetch(cityID ? areasUrl : "");

  useEffect(() => {
    if (!areasLoading && areasData) {
      let areas = [];
      areas = areasData;

      areas.push({
        id: 0,
        attributes: {
          Name: "All Areas",
          City: {
            data: {
              id: cityID,
            },
          },
        },
      });

      const newData = {
        areas: areas,
      };

      setData(newData);
      setLoading(false);
      setError(false);
    }
  }, [areasLoading]);

  return {
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export { useGetArea };
