import { useState, useEffect } from "react";

import useFetch from "./useFetch";

const useShippingRates = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    "populate": 'deep',
    "pagination[limit]": -1,
    try: tries,
  });

  // get payment methods
  const shippingRatesBaseUrl = "/shipping-rates";
  const shippingRatesUrl = `${shippingRatesBaseUrl}?${Params.toString()}`;
  const {
    data: shippingRatesData,
    loading: shippingRatesLoading,
    error: shippingRatesError,
  } = useFetch(shippingRatesUrl);

  useEffect(() => {
    if (shippingRatesError) {
      setError(true);
      setLoading(false);
      return;
    }

    if (
      !shippingRatesLoading &&
      shippingRatesData?.length > 0
    ) {
      setData(shippingRatesData);
      setLoading(false);
    }
  }, [shippingRatesLoading]);

  return {
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export { useShippingRates };
