import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  cities: [],
  areas: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    addCountries: (state, action) => {
      state.countries = action.payload;
    },
    removeCountries: (state) => {
      state.countries = [];
    },
    addCities: (state, action) => {
      state.cities = action.payload;
    },
    removeCities: (state) => {
      state.cities = [];
    },
    addAreas: (state, action) => {
      state.areas = action.payload;
    },
    removeAreas: (state) => {
      state.areas = [];
    },
  },
});

export const {
  addCountries,
  removeCountries,
  addCities,
  removeCities,
  addAreas,
  removeAreas,
} = generalSlice.actions;
export default generalSlice.reducer;
