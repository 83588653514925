import React, { useState, useEffect } from "react";

import {
  generateInvoiceNumber,
  uploadBase64PdfToStrapi,
} from "../../helpers/common";

import usePut from "../../hooks/usePut";
import useNetworkPayment from "../../hooks/useNetworkPayment";
import useDHLShipping from "../../hooks/useDHLShipping";
import useSMSAShipping from "../../hooks/useSMSAShipping";

import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import "./NetworkPaymentRedirect.scss";

import { useTranslation } from "react-i18next";

const NetworkPaymentRedirect = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();

  const checkout = useSelector((state) => state.checkout);
  const navigate = useNavigate();

  const [updateOrderRequestData, setUpdateOrderRequestData] = useState({});
  const [updateOrderData, setUpdateOrderData] = useState({});
  const [updateOrderRetries, setUpdateOrderRetries] = useState(0);
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
  const [updateOrderError, setUpdateOrderError] = useState(false);

  const [navigateTo, setNavigateTo] = useState("");

  const updateOrderUrl = `/orders/${checkout?.orderID}`;
  const {
    data: UpdateOrderData,
    loading: UpdateOrderLoading,
    error: UpdateOrderError,
    status: UpdateOrderStatus,
    errorMessage: UpdateOrderErrorMessage,
  } = usePut(updateOrderUrl, updateOrderRequestData);

  const {
    paymentResponse: NetworkPayment,
    loading: NetworkLoading,
    error: NetworkError,
    getPayment,
  } = useNetworkPayment();

  const { createShipment } = useDHLShipping();
  const { createSMSAShipment } = useSMSAShipping();

  const createOrderShipment = async (type, Invoice = "") => {
    if (type == "DHL") {
      let base64EncodedPDF = "";
      let base64EncodedInvoice = "";
      let trackingNumber = "";
      let fileID = 0;
      let fileUploadError = false;
      let invoiceID = 0;
      let invoiceUploadError = false;

      [trackingNumber, base64EncodedPDF, base64EncodedInvoice] =
        await createShipment(
          checkout?.shippingData?.countryName,
          checkout?.shippingData?.cityName,
          checkout?.shippingData?.postalCode,
          checkout?.shippingData?.addressLine1,
          checkout?.shippingData?.addressLine2,
          checkout?.shippingData?.customerName,
          checkout?.shippingData?.customerEmail,
          checkout?.shippingData?.customerPhone,
          checkout?.shippingData?.shippingProductCode,
          checkout?.shippingData?.deliveryNotes,
          checkout?.shippingData?.total,
          checkout?.shippingData?.totalCurrency,
          checkout?.shippingData?.description,
          checkout?.shippingData?.lineItems,
          checkout?.shippingData?.invoiceNum,
          checkout?.shippingData?.pkgs,
          checkout?.shippingData?.orderID
        );

      if (base64EncodedPDF) {
        [fileID, fileUploadError] = await uploadBase64PdfToStrapi(
          base64EncodedPDF,
          `DHL shipment label for order #${checkout?.shippingData?.orderID}`
        );
      }
      if (base64EncodedInvoice) {
        [invoiceID, invoiceUploadError] = await uploadBase64PdfToStrapi(
          base64EncodedInvoice,
          `DHL invoice label for order #${checkout?.shippingData?.orderID}`
        );
      }
      return {
        base64EncodedPDF,
        base64EncodedInvoice,
        trackingNumber,
        fileID,
        fileUploadError,
        invoiceID,
        invoiceUploadError,
      };
    } else if (type?.includes("SMSA")) {
      let base64EncodedPDF = "";
      let trackingNumber = "";
      let fileID = 0;
      let fileUploadError = false;

      [trackingNumber, base64EncodedPDF] = await createSMSAShipment(
        checkout?.shippingData?.countryName,
        checkout?.shippingData?.cityName,
        checkout?.shippingData?.postalCode,
        checkout?.shippingData?.addressLine1,
        checkout?.shippingData?.addressLine2,
        checkout?.shippingData?.customerName,
        checkout?.shippingData?.customerPhone,
        checkout?.shippingData?.selectedShippingCode,
        checkout?.shippingData?.total,
        checkout?.shippingData?.totalCurrency,
        checkout?.shippingData?.description,
        checkout?.shippingData?.pkgs,
        checkout?.shippingData?.orderID
      );

      if (base64EncodedPDF) {
        [fileID, fileUploadError] = await uploadBase64PdfToStrapi(
          base64EncodedPDF,
          `SMSA shipment label for order #${checkout?.shippingData?.orderID}`
        );
      }
      return {
        base64EncodedPDF,
        trackingNumber,
        fileID,
        fileUploadError,
      };
    }

    return null;
  };

  const updatePaymentResponse = async (paymentResponse) => {
    let paymentStatus = "";
    let Invoice = generateInvoiceNumber(checkout?.orderID);

    let trackingNumber = "";

    let base64EncodedPDF = "";
    let base64EncodedInvoice = "";
    let fileID = 0;
    let fileUploadError = false;
    let invoiceID = 0;
    let invoiceUploadError = false;

    if (
      paymentResponse?.status == "CAPTURED" ||
      paymentResponse?.status == "PURCHASED"
    )
      paymentStatus = "Paid";
    else if (paymentResponse?.status == "AUTHORIZED")
      paymentStatus = "Processing";
    else paymentStatus = "Payment Failed";

    if (paymentStatus == "Payment Failed") {
      setNavigateTo(`/checkout?error=${t("checkout.payment-failed")}`);
    } else {
      setNavigateTo("/order-submitted");
    }

    if (
      paymentStatus != "Payment Failed" &&
      checkout?.shippingData?.shippingMethod == "DHL"
    ) {
      ({
        base64EncodedPDF,
        base64EncodedInvoice,
        trackingNumber,
        fileID,
        fileUploadError,
        invoiceID,
        invoiceUploadError,
      } = await createOrderShipment("DHL", Invoice));
    } else if (
      paymentStatus != "Payment Failed" &&
      checkout?.shippingData?.shippingMethod?.includes("SMSA")
    ) {
      ({ base64EncodedPDF, trackingNumber, fileID, fileUploadError } =
        await createOrderShipment("SMSA"));
    }

    const newRequestData = {
      PaymentResponse: JSON.stringify(paymentResponse),
      Status: paymentStatus,
      InvoiceNumber: Invoice,
    };

    // add DHL props
    if (
      paymentStatus != "Payment Failed" &&
      checkout?.shippingData?.shippingMethod == "DHL"
    ) {
      if (trackingNumber) newRequestData.DHLTrackingNumber = trackingNumber;
      if (fileID && !fileUploadError) newRequestData.DHLLabel = fileID;
      if (invoiceID && !invoiceUploadError)
        newRequestData.DHLInvoice = invoiceID;
    }

    // add SMSA props
    if (
      paymentStatus != "Payment Failed" &&
      checkout?.shippingData?.shippingMethod?.includes("SMSA")
    ) {
      if (trackingNumber) newRequestData.DHLTrackingNumber = trackingNumber;
      if (fileID && !fileUploadError) newRequestData.DHLLabel = fileID;
    }

    setUpdateOrderRequestData({ data: newRequestData });
    setUpdateOrderRetries((prev) => prev + 1);
  };

  const retryUpdateOrder = () => setUpdateOrderRetries((prev) => prev + 1);

  useEffect(() => {
    if (UpdateOrderLoading) {
      setUpdateOrderLoading(true);
      return;
    }
    setUpdateOrderLoading(false);

    if (UpdateOrderStatus !== 200 && updateOrderRetries > 0) {
      setUpdateOrderError(true);
      retryUpdateOrder();
      return;
    }

    if (UpdateOrderData) {
      setUpdateOrderData(UpdateOrderData);
      setUpdateOrderError(false);
      setUpdateOrderLoading(false);
      navigate(navigateTo);
    }
  }, [UpdateOrderLoading]);

  useEffect(() => {
    if (NetworkPayment && !NetworkLoading && !NetworkError) {
      updatePaymentResponse(NetworkPayment);
    }
  }, [NetworkLoading]);

  useEffect(() => {
    if (checkout?.orderID) {
      const searchParams = new URLSearchParams(location.search);
      const refParam = searchParams.get("ref");
      getPayment(refParam);
    }
  }, [checkout?.orderID]);

  return (
    <div className="network-payment-redirect">
      <h1 className="network-payment-redirect-loading">
        {t("general.redirect")}
      </h1>
    </div>
  );
};

export default NetworkPaymentRedirect;
