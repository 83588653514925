import { useState, useEffect } from "react";

import useFetch from "./useFetch";

import { useTranslation } from "react-i18next";

const useGetCity = (countryID) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [data, setData] = useState({
    cities: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const retry = () => setTries((prev) => prev + 1);

  const Params = new URLSearchParams({
    populate: "*",
    locale: 'en',
    try: tries,
    "pagination[limit]": -1,
    "filters[Country][id][$eq]": countryID,
  });

  // get cities
  const citiesBaseUrl = "/cities";
  const citiesUrl = `${citiesBaseUrl}?${Params.toString()}`;
  const {
    data: citiesData,
    loading: citiesLoading,
    error: citiesError,
  } = useFetch(countryID ? citiesUrl : "");

  useEffect(() => {
    if (citiesError) {
      setError(true);
      setLoading(false);
      return;
    }

    if (!citiesLoading && citiesData?.length > 0) {
      const cities = citiesData;

      const newData = {
        cities: cities,
      };

      setData(newData);
      setLoading(false);
    }
  }, [citiesLoading]);

  return {
    data,
    loading,
    error,
    setError,
    retry,
  };
};

export { useGetCity };
