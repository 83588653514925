import React, { useState, useEffect } from "react";

import useFetch from "../../hooks/useFetch";

import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";
import Slider from "../../components/Slider/Slider";

import "./Home.scss";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  const [retryKeyNew, setRetryKeyNew] = useState(0);
  const [retryKeyBest, setRetryKeyBest] = useState(0);
  const [sliderRetries, setSliderRetries] = useState(0);

  const [bestSellersIDs, setBestSellersIDs] = useState([]);
  const [bestSellersIDsLoading, setBestSellersIDsLoading] = useState(true);
  const [retryKeyBestSellersIDs, setRetryKeyBestSellersIDs] = useState(0);

  // newest products
  const newProductsDataBaseUrl = "/products";
  const newProductsDataParams = new URLSearchParams({
    populate: "deep",
    sort: "CreationDate:desc",
    "pagination[pageSize]": 9,
    "filters[Status][$eq]": true,
    locale: "en",
    try: retryKeyNew,
  });
  const newProductsDataUrl = `${newProductsDataBaseUrl}?${newProductsDataParams.toString()}`;
  const {
    data: newProductsData,
    loading: newProductsLoading,
    error: newProductsError,
  } = useFetch(newProductsDataUrl);

  // best seller IDs
  const bestSellersIDsBaseUrl = "/most-ordered-products";
  const bestSellersIDsParams = new URLSearchParams({
    try: retryKeyBestSellersIDs,
  });
  const bestSellersIDsUrl = `${bestSellersIDsBaseUrl}?${bestSellersIDsParams.toString()}`;
  const {
    data: BestSellersIDs,
    loading: BestSellersIDsLoading,
    error: BestSellersIDsError,
  } = useFetch(bestSellersIDsUrl);

  // best seller products based on IDs
  const bestProductsDataBaseUrl = "/products";
  const bestProductsDataParams = new URLSearchParams({
    populate: "deep",
    "pagination[pageSize]": 9,
    locale: "en",
    "filters[Status][$eq]": true,
    try: retryKeyBest,
  });

  bestSellersIDs.forEach((id, index) => {
    bestProductsDataParams.append(`filters[id][$in][${index}]`, id);
  });

  const bestProductsDataUrl = bestSellersIDs.length
    ? `${bestProductsDataBaseUrl}?${bestProductsDataParams.toString()}`
    : null;

  const {
    data: bestProductsData,
    loading: bestProductsLoading,
    error: bestProductsError,
  } = useFetch(bestProductsDataUrl);

  // slider data
  const sliderBaseUrl = "/home";
  const sliderParams = new URLSearchParams({
    populate: "deep",
    locale: "en",
    try: sliderRetries,
  });
  const sliderUrl = `${sliderBaseUrl}?${sliderParams.toString()}`;
  const {
    data: sliderData,
    loading: sliderLoading,
    error: sliderError,
  } = useFetch(sliderUrl);

  const retrySlider = () => setSliderRetries((prev) => prev + 1);
  const retryNew = () => setRetryKeyNew((prev) => prev + 1);
  const retryBestSellers = () => setRetryKeyBest((prev) => prev + 1);
  const retryBestSellersIDs = () =>
    setRetryKeyBestSellersIDs((prev) => prev + 1);

  useEffect(() => {
    if (!BestSellersIDsLoading && BestSellersIDs) {
      setBestSellersIDs(BestSellersIDs);
      setBestSellersIDsLoading(false);
      retryBestSellers();
    } else if (BestSellersIDsLoading && !BestSellersIDsError) {
      setBestSellersIDsLoading(true);
    } else if (
      !BestSellersIDsLoading &&
      BestSellersIDsError &&
      !BestSellersIDs
    ) {
      retryBestSellersIDs();
    }
  }, [BestSellersIDsLoading]);

  useEffect(() => {
    if (!sliderLoading && sliderData) {
      setMetaTitle(sliderData?.attributes?.SEO?.metaTitle);
      setMetaDescription(sliderData?.attributes?.SEO?.metaDescription);
      setMetaKeywords(sliderData?.attributes?.SEO?.keywords);
    } else if (!sliderLoading && sliderError && !sliderData) {
      retrySlider();
    }
  }, [BestSellersIDsLoading]);

  useEffect(() => {
    document.title = `${t("general.classy-ym")} - ${t("home.home")}`;
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("home.default-title")}</title>
        <meta
          name="description"
          content={metaDescription || t("home.default-description")}
        />
        <meta
          name="keywords"
          content={metaKeywords || t("home.default-keywords")}
        />
      </Helmet>
      <section className="home">
        <Slider
          data={sliderData}
          loading={sliderLoading}
          error={sliderError}
          retryFunc={retrySlider}
        />

        <FeaturedProducts
          data={newProductsData}
          loading={newProductsLoading}
          error={newProductsError}
          retryFunc={retryNew}
          title={t("home.new-in")}
          link="/category/new"
        />
        {bestSellersIDs?.length > 0 && (
          <FeaturedProducts
            data={bestProductsData}
            loading={bestProductsLoading}
            error={bestProductsError}
            retryFunc={retryBestSellers}
            title={t("home.best-sellers")}
            link="/category/best-sellers"
          />
        )}
      </section>
    </>
  );
};

export default Home;
