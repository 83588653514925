import { useEffect, useState } from "react";
import { makeRequest } from "../makeRequest";

const usePut = (url, reqData) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(200)
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if(Object.keys(reqData).length === 0) return

    const putData = async () => {
      try {
        setLoading(true);
        const res = await makeRequest.put(url, reqData);
        setData(res.data);
        setStatus(res.status)
      } catch (err) {
        console.log(err)
        setErrorMessage(err.response.data.error.message)
        setStatus(err.response.data.error.status)
        setError(true);
      }
      setLoading(false);
    };
    putData();
  }, [url, reqData]);

  return { data, loading, error, status, errorMessage };
};

export default usePut;
