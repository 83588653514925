import { useState } from "react";
import { makeRequest } from "../makeRequest";

const useExchangeRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tries, setTries] = useState(0);

  const retry = () => setTries((prev) => prev + 1);

  const submitExchangeRequest = async (data) => {
    setLoading(true);
    setError(false);
    const payload = {
      data: {
        ...data,
        tries: tries
      },
    };

    try {
      const res = await makeRequest.post(`/ecxhange-requests`, payload);
    } catch (err) {
      setLoading(false);
      setError(true);
      return false;
    }

    setLoading(false);
    setError(false);
    return true;
  };

  return {
    submitExchangeRequest,
    loading,
    error,
    setError,
    retry,
  };
};

export { useExchangeRequest };